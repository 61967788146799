import React, { useRef, useState, useEffect, useMemo, useCallback, useContext } from "react";
import "leaflet/dist/leaflet.css";
import L, { Map as LeafletMap } from "leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    Polygon,
    Circle,
    LayersControl,
    LayerGroup,
    FeatureGroup,
    Tooltip,
    ScaleControl,
    useMapEvents
} from "react-leaflet";
import { EditControl } from "../../../lib/react-leaflet-draw";
import { toast } from "react-toastify";
import { Modal } from "../../misc/modal";
import { DealerTab } from "../dealer-tab";

import "./_leaflet.scss";
import { Loading } from "../../misc/loading/";
import { Page } from "../../misc/layout";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom-v5-compat";
import { RetrieveCompanies } from "@frontend/services/companies";
import { UpdateCompany } from "../../../services/companies";
import { exists, formatPhoneNumber, isZipCode, max, GetDate, formatMoney, formatWholeMoney } from "@common.tools";
import { RetrieveQuoteRequests, RetrieveQuoteRequestsCount } from "@frontend/services/quote-requests";
import Select from "react-select";
import { Between } from "@common.api";
import { Scope, InstallationType } from "@common.abstractions";
import TopBar from "@frontend/components/misc/top-bar/TopBar";
import Button from "../../misc/button/Button";
import { GetGeoPointFromAddressString } from "@frontend/services/utils";
import { RetrieveInstallCount, RetrieveInstalls } from "@frontend/services/installs";
import { CompanyType } from '@common.abstractions'
import ProgressBar2 from "@frontend/components/misc/progress-bar/ProgressBar2";
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import AuthContext from "@frontend/contexts/AuthContext";

// work around broken icons when using webpack, see https://github.com/PaulLeCam/react-leaflet/issues/255
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png",
    iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png",
    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png",
});

function MyComponent({ handleZoomChange }) {
    const [zoomLevel, setZoomLevel] = useState(9);// initial zoom level provided for MapContainer

    const mapEvents = useMapEvents({
        zoomend: () => {
            setZoomLevel(mapEvents.getZoom());
        },
    });

    useEffect(() => {
        handleZoomChange(zoomLevel);
    }, [zoomLevel])

    return null
}

function Handle({
    domain: [min, max],
    handle: { id, value, percent },
    getHandleProps
}) {
    const formatValues = (value) => {
        const formatMoney = formatWholeMoney(value);
        if (!formatMoney.includes(',')) {
            return formatMoney
        }
        const splitOnComma = formatMoney.split(',');
        const index0Arr = splitOnComma[0];
        const index1Arr = splitOnComma[1].substring(0, 1);

        const joined = `${[index0Arr, index1Arr].join('.')}k`;
        return joined;
    }

    return (
        <div
            style={{
                left: `${percent}%`,
                position: 'absolute',
                marginLeft: -15,
                marginTop: 25,
                zIndex: 2,
                width: 24,
                height: 24,
                border: 0,
                textAlign: 'center',
                cursor: 'pointer',
                borderRadius: '50%',
                backgroundColor: '#34568f',
            }}
            {...getHandleProps(id)}
        >
            <div
                role="slider"
                aria-valuemin={min}
                aria-valuemax={max}
                aria-valuenow={value}
                style={{
                    fontFamily: 'Roboto',
                    fontSize: 12,
                    marginTop: -20
                }}
            >
                {formatValues(value)}
            </div>
        </div>
    )
}

function Track({ source, target, getTrackProps }) {
    return (
        <div
            style={{
                position: 'absolute',
                height: 10,
                zIndex: 1,
                marginTop: 35,
                backgroundColor: '#7aa0c4',
                borderRadius: 5,
                cursor: 'pointer',
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`,
            }}
            {...getTrackProps()}
        />
    )
}

function Tick({ tick, count, format }) {
    return (
        <div>
            <div
                style={{
                    position: 'absolute',
                    marginTop: 52,
                    marginLeft: -0.5,
                    width: 1,
                    height: 8,
                    backgroundColor: 'silver',
                    left: `${tick.percent}%`,
                }}
            />
            <div
                style={{
                    position: 'absolute',
                    marginTop: 60,
                    fontSize: 10,
                    textAlign: 'center',
                    marginLeft: `${-(100 / count) / 2}%`,
                    width: `${100 / count}%`,
                    left: `${tick.percent}%`,
                }}
            >
                {format(tick.value)}
            </div>
        </div>
    )
}

export default function Leaflet() {
    const authContext = useContext(AuthContext);
    const zipCodeInput = useRef(null);
    const zipCodeButton = useRef(null);
    const [editableFG, setEditableFG] = useState(null);
    const [unsavedSelection, setUnsavedSelection] = useState();
    const [selectedDealer, setSelectedDealer] = useState();
    const [selectedServiceArea, setSelectedServiceArea] = useState("exclusiveServiceArea");
    const [newArea, setNewArea] = useState();
    const [searchCriteria, setSearchCriteria] = useState("");
    const quoteRequests = useRef([]);
    const filteredQuoteRequests = useRef([]);
    const [displayedQuotes, setDisplayedQuotes] = useState([]);
    const [quoteRequestsFilter, setQuoteRequestsFilter] = useState({
        value: { filterOption: "All Quote Requests" },
        label: "All Quote Requests",
    });
    const [zoomLevel, setZoomLevel] = useState(10);
    const [addressGeoPoint, setAddressGeoPoint] = useState();
    const [timeFrame, setTimeFrame] = useState({ start: GetDate.nDaysAgo(30), end: GetDate.tomorrow(), name: "last30Days" });

    //const [dealersNoEdit, setDealersNoEdit] = useState();
    //const [editingZone, setEditingZone] = useState(false);
    const [editInfo, setEditInfo] = useState();

    const [map, setMap] = useState(null);
    const mapRef = useRef();
    const [dealers, setDealers] = useState();
    const [filteredDealers, setFilteredDealers] = useState();
    const [filteredDealersSideBar, setFilteredDealersSidebar] = useState();

    const [modalVisibility, setModalVisibility] = useState(false);

    const [radius, setRadius] = useState(3000);
    const installs = useRef([]);
    const cursor = useRef();
    const [displayInstalls, setDisplayInstalls] = useState([]);
    const [currentCount, setCurrentCount] = useState(0);
    const [maxNumber, setMax] = useState(0);
    const [currentCountQuotes, setCurrentQuotesCount] = useState(0);
    const [maxQuotes, setMaxQuotes] = useState(0);
    const [showHeatmap, setShowHeatmap] = useState(false);
    const totalSalesMax = useRef();
    const totalSalesMin = useRef();
    const [values, setValues] = useState([20, 60]);
    const [domain, setDomain] = useState([0, 100]);

    const greenIcon = L.icon({
        iconUrl: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|2ecc71&chf=a,s,ee00FFFF',
        shadowUrl: 'http://www.google.com/mapfiles/arrowshadow.png'
    })

    const blueIcon = L.icon({
        iconUrl: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|abcdef&chf=a,s,ee00FFFF',
        shadowUrl: 'http://www.google.com/mapfiles/arrowshadow.png'
    })

    const onFeatureGroupReady = (reactFGref) => {
        // store the featureGroup ref for future access to content
        setEditableFG(reactFGref);
    };

    const _onEdited = async (e) => {
        console.log("start editng");

        let tempDealers = [...dealers];
        let serviceAreasUpdate = { exclusiveServiceAreas: undefined, captureServiceAreas: undefined };
        let numEdited = 0;
        let tempDealer;
        e.layers.eachLayer((layer) => {
            numEdited += 1;
            let tempLatLng = [];
            tempDealer = [...dealers].find((el) => el.id === layer.options.dealerId);

            if (!tempDealer) return;

            if (layer.options.areaType === "exclusiveServiceArea") {
                console.log("layer", layer);
                if (layer.options.areaShape === "polygon") {
                    layer.editing.latlngs[0][0].map((el) => {
                        tempLatLng.push([el.lat, el.lng]);
                    });
                    tempDealer.exclusiveServiceAreas[layer.options.index].area.vertices = tempLatLng;
                } else if (layer.options.areaShape === "circle") {
                    tempDealer.exclusiveServiceAreas[layer.options.index].area.center = [layer._latlng.lat, layer._latlng.lng];
                    tempDealer.exclusiveServiceAreas[layer.options.index].area.radiusMeters = layer._mRadius;
                }
            } else if (layer.options.areaType === "captureServiceArea") {
                if (layer.options.areaShape === "polygon") {
                    layer.editing.latlngs[0][0].map((el) => {
                        tempLatLng.push([el.lat, el.lng]);
                    });
                    tempDealer.captureServiceAreas[layer.options.index].area.vertices = tempLatLng;
                } else if (layer.options.areaShape === "circle") {
                    tempDealer.captureServiceAreas[layer.options.index].area.center = [layer._latlng.lat, layer._latlng.lng];
                    tempDealer.captureServiceAreas[layer.options.index].area.radiusMeters = layer._mRadius;
                }
            }

            tempDealers = [...dealers].map((el) => {
                if (el.id === tempDealer.id) {
                    return tempDealer;
                }
                return el;
            });
        });

        if (!tempDealer) {
            setDealers(tempDealers);
            setFilteredDealers(tempDealers);
            setSelectedDealer(tempDealers[0].id);
            setFilteredDealersSidebar(tempDealers);
            return;
        }

        serviceAreasUpdate = {
            exclusiveServiceAreas: tempDealer.exclusiveServiceAreas,
            captureServiceAreas: tempDealer.captureServiceAreas,
        };

        const response = await UpdateCompany(tempDealer.id, serviceAreasUpdate);
        if (!response) return;

        setDealers(tempDealers);
        setFilteredDealers(tempDealers);
        setSelectedDealer(tempDealers[0].id);
        setFilteredDealersSidebar(tempDealers);

        if (numEdited > 0) toast("Saved");
        console.log("done editng");
    };

    const _onCreated = (e) => {
        let type = e.layerType;
        let tempLatLng = [];
        let newZone = {};

        setUnsavedSelection(e.layer);

        if (type === "polygon") {
            e.layer.editing.latlngs[0][0].map((el) => {
                tempLatLng.push([el.lat, el.lng]);
            });

            newZone.type = "polygon";
            newZone.area = {
                vertices: tempLatLng,
            };
        } else if (type === "circle") {
            newZone.type = "circle";
            newZone.area = {
                center: [e.layer._latlng.lat, e.layer._latlng.lng],
                radiusMeters: e.layer._mRadius,
            };
        }

        setNewArea(newZone);
        setModalVisibility(true);
    };

    const _onDeleted = async (e) => {
        let tempDealers;
        let tempSelectedDealerId;
        let tempDealer;

        e.layers.eachLayer((layer) => {
            tempSelectedDealerId = layer.options.dealerId;
        });

        if (!tempSelectedDealerId) {
            console.log("Error deleting...selected dealer id doesn't exist because there was no layer or no layer with a options.dealerId");
            setEditInfo(undefined);
            toast("Nothing to save");
            return;
        }

        tempDealer = [...dealers].find((el) => el.id === tempSelectedDealerId);

        if (!tempDealer) {
            console.log("Error deleting...No Dealer Matched the layer dealerId");
            setEditInfo(undefined);
            toast.error("Error deleting zone");
            return;
        }

        e.layers.eachLayer((layer) => {
            console.log("Layer", layer);
            if (layer.options.areaType === "captureServiceArea") {
                console.log("Deleting captureServiceArea", tempDealer, layer.options.index, 1);
                tempDealer.captureServiceAreas.splice(layer.options.index, 1);
            } else if (layer.options.areaType === "exclusiveServiceArea") {
                tempDealer.exclusiveServiceAreas.splice(layer.options.index, 1);
                console.log("Deleting exclusiveServiceArea", tempDealer);
            }
        });

        tempDealers = [...dealers].map((el) => {
            if (el.id === tempDealer.id) {
                return tempDealer;
            }
            return el;
        });

        //UPDATE DB FOR DELETION
        console.log("UPDATING", tempDealers, tempDealer, tempSelectedDealerId);
        const { exclusiveServiceAreas, captureServiceAreas } = tempDealer;

        if (exclusiveServiceAreas && captureServiceAreas) {
            const response = await UpdateCompany(tempSelectedDealerId, { exclusiveServiceAreas, captureServiceAreas });
            if (!response) {
                setEditInfo(undefined);
                toast.error("Error deleting zone");
                return;
            }
        } else {
            setEditInfo(undefined);
            toast.error("Error deleting zone");
            return;
        }

        setDealers(tempDealers);
        setFilteredDealers(tempDealers);
        setFilteredDealersSidebar(tempDealers);
        setEditInfo(undefined);
        setSelectedDealer(tempDealers[0].id);
        setModalVisibility(false);
        toast("Deleted");
    };

    const _onEditStart = (e) => { };

    const _onEditStop = (e) => { };

    const _onDeleteStart = (e) => { };

    const _onDeleteStop = (e) => { };

    const handleZoneCreation = async () => {
        console.log("new area", newArea);

        //Creates new state
        const tempDealers = [...dealers].map((el, i) => {
            if (el.id === selectedDealer) {
                if (selectedServiceArea === "exclusiveServiceArea") {
                    el.exclusiveServiceAreas.push(newArea);
                    return el;
                } else if (selectedServiceArea === "captureServiceArea") {
                    el.captureServiceAreas.push(newArea);
                    return el;
                }
            }
            return el;
        });

        //UPDATE DB
        const { exclusiveServiceAreas, captureServiceAreas } = tempDealers.find((el) => el.id === selectedDealer);

        if (exclusiveServiceAreas && captureServiceAreas) {
            const response = await UpdateCompany(selectedDealer, { exclusiveServiceAreas, captureServiceAreas });
            if (!response) {
                deleteMapLayer();
                setEditInfo(undefined);
                setSelectedServiceArea("exclusiveServiceArea");
                setSelectedDealer(dealers[0].id);
                toast.error("Error creating zone");
                return;
            }
        } else {
            deleteMapLayer();
            setEditInfo(undefined);
            setSelectedServiceArea("exclusiveServiceArea");
            setSelectedDealer(dealers[0].id);
            toast.error("Error creating zone");
            return;
        }

        //RESETS THE STATE
        deleteMapLayer();
        setDealers(tempDealers);
        setFilteredDealers(tempDealers);
        setFilteredDealersSidebar(tempDealers);
        setEditInfo(undefined);
        setSelectedServiceArea("exclusiveServiceArea");
        setSelectedDealer(tempDealers[0].id);
        setModalVisibility(false);
        toast("Saved");
    };

    const handleZoneCreationCancel = () => {
        if (unsavedSelection) {
            const drawnItems = editableFG._layers;
            const id = unsavedSelection._leaflet_id;
            const layer = drawnItems[id];
            editableFG.removeLayer(layer);
        }
        setModalVisibility(false);
    };

    const deleteMapLayer = () => {
        const drawnItems = editableFG._layers;
        const id = unsavedSelection._leaflet_id;
        const layer = drawnItems[id];
        editableFG.removeLayer(layer);
    };

    const createZone = (dealer, zoneType) => {
        console.log("create zone", dealer, zoneType);
    };

    const editZone = (dealer, area) => {
        if (!editInfo) setEditInfo({ dealer, area });
        else if (editInfo.dealer.id !== dealer.id || editInfo.area !== area) setEditInfo({ dealer, area });

        let tempDealers = [...dealers];
        const tempDealersNoEdit = tempDealers.filter((el) => el.id !== dealer.id);
        setFilteredDealers(tempDealersNoEdit);

        document.querySelectorAll(".leaflet-draw-edit-edit")[0].click();
        setTimeout(() => {
            document.querySelectorAll(".leaflet-draw-edit-edit")[0].click();
        }, 50);
    };

    const loadDealers = async () => {
        let tempDealers = await RetrieveCompanies();

        let notSuspended = tempDealers.filter((el) => !el.suspended);

        if (notSuspended) {
            notSuspended = notSuspended.sort((a, b) => a.name.localeCompare(b.name));
            setDealers(notSuspended);
            setSelectedDealer(notSuspended[0].id);
            setFilteredDealers(notSuspended);
            setFilteredDealersSidebar(notSuspended);
        }
    };

    const loadPaginatedQuotes = async () => {
        let countAll = await RetrieveQuoteRequestsCount({
            createdAt: Between(timeFrame.start, timeFrame.end)
        });

        quoteRequests.current = [];
        filteredQuoteRequests.current = [];

        setMaxQuotes(countAll);
        loadMoreQuotes(200);
    }

    const loadMoreQuotes = async (num) => {
        const tempQuoteRequests = await RetrieveQuoteRequests(
            {
                filters: {
                    createdAt: Between(timeFrame.start, timeFrame.end),
                },
                relations: ['contact', 'leads', 'leads.company', 'autoAssignment', 'autoAssignment.captureCandidates', 'autoAssignment.exclusiveCandidates']

            },
            {
                cursor: cursor.current,
                direction: 'forward',
                count: num

            });

        if (!tempQuoteRequests) {
            console.warn('no response from load quotes call');
            return;
        }
        if (!tempQuoteRequests.pagination) {
            console.error('Response missing pagination information');
            return;
        }

        const retrievedQuotes = tempQuoteRequests.values;
        quoteRequests.current = [...quoteRequests.current, ...retrievedQuotes];

        const filteredQuotes = handleQuoteRequestFilter2(retrievedQuotes);
        filteredQuoteRequests.current = [...filteredQuoteRequests.current, ...filteredQuotes];

        setDisplayedQuotes(filteredQuoteRequests.current);
        setCurrentQuotesCount(quoteRequests.current.length);

        cursor.current = tempQuoteRequests.pagination.end_cursor;
        if (cursor.current) {
            setTimeout(() => loadMoreQuotes(num), 200);
        }
    };

    useEffect(() => {
        filteredQuoteRequests.current = handleQuoteRequestFilter2(quoteRequests.current);
        setDisplayedQuotes(filteredQuoteRequests.current);
    }, [quoteRequestsFilter]);

    const searchAddress = async () => {
        const res = await GetGeoPointFromAddressString(searchCriteria);
        if (!res) {
            toast.error("Something went wrong");
            return;
        }

        //fly to the point
        const lat = res.latlng[0];
        const lng = res.latlng[1];

        setAddressGeoPoint([lat, lng]);

        if (map) {
            map.setView([lat, lng], zoomLevel);
        }
        return;
    };

    const handleQuoteRequestFilter2 = (quotesArr) => {
        switch (quoteRequestsFilter.value.filterOption) {
            case 'All Quote Requests':
                console.log('Setting All quotes')
                return quotesArr
            case 'Customer':
                console.log('Setting Customers')
                return quotesArr.filter((el) => el.vConverted);
            case 'DIY':
                console.log('Setting DIY quotes')
                return quotesArr.filter((el) => el.installationType === InstallationType.DIY);
            case 'Unserviceable':
                console.log('Setting Unserviceable quotes');
                return quotesArr.filter((el) => {
                    const lastAutoAssignment = max(el.autoAssignment, (a, b) => a.createdAt > b.createdAt);
                    if (!lastAutoAssignment) {
                        return false;
                    }
                    return !lastAutoAssignment.captureCandidates?.length;
                })
            default:
                console.log('setting all quotes')
                return quotesArr
        }
    }

    const handleZoomChange = (zoomLevel) => {
        if (zoomLevel < 4) {
            setRadius(4000);
            return;
        }
        setRadius(1360550 * Math.pow(zoomLevel, -3.54195) - 28);
    }

    const loadMoreInstalls = async (num) => {
        let response = await RetrieveInstalls({
            filters: {
                createdAt: Between(timeFrame.start, timeFrame.end)
            },
            relations: ['branch']
        }, {
            direction: 'forward',
            count: num,
            cursor: cursor.current
        });

        if (!response) {
            console.warn('No response from load installs call');
            return;
        }

        if (!response.values.length) {
            toast.error('No installs within date range');
        }

        if (!response.pagination) {
            console.error('Response missing pagination information');
            return;
        }

        const retrievedInstalls = response.values;

        installs.current = [...installs.current, ...retrievedInstalls];

        setDisplayInstalls(installs.current);
        setCurrentCount(installs.current.length);

        cursor.current = response.pagination.end_cursor;
        if (cursor.current) {
            setTimeout(() => loadMoreInstalls(num), 200);
        }

        if (!cursor.current && installs.current.length) {
            console.log('No more installs');

            findTotalMinMax(installs.current)
        }
    }

    const loadPaginatedInstalls = async () => {
        let countAll = await RetrieveInstallCount({
            createdAt: Between(timeFrame.start, timeFrame.end)
        })
        installs.current = [];

        setMax(countAll);
        loadMoreInstalls(100);
    }

    const findTotalMinMax = (installArr) => {
        installArr.sort((a, b) => {
            return a.total - b.total
        })
        let minTotal = installArr[0];
        let maxTotal = installArr[installArr.length - 1];
        totalSalesMin.current = minTotal.total;
        totalSalesMax.current = maxTotal.total;

        setDomain([totalSalesMin.current, totalSalesMax.current]);
        setValues([totalSalesMin.current, totalSalesMax.current]);
    }

    const getHeatmapColor = (install) => {
        if (install.total > values[1]) return `rgba(255,0,0,1)`

        if (install.total < values[0]) return `rgba(0,0,255,1)`

        const lerpVal = lerp(install);
        const redVal = Math.round(255 * lerpVal);
        const blueVal = Math.round(255 * (1 - lerpVal));
        return `rgba(${redVal},0,${blueVal},1)`
    }

    const lerp = (install) => {
        const total = install.total;
        return (total - values[0]) / (values[1] - values[0]);
    }

    const setShowHeatMapStatus = () => {
        if (showHeatmap === false) {
            setShowHeatmap(true);
            return;
        }
        setShowHeatmap(false);
    }

    const setHeatMapBtnText = () => {
        if (showHeatmap === false) return 'Toggle Heat Map On'
        return 'Toggle Heat Map Off'
    }

    const changeValues = (newValues) => {
        setValues(newValues);
    }

    const formatTicks = (tick) => {
        const formatMoney = formatWholeMoney(tick);
        if (!formatMoney.includes(',')) return formatMoney

        const splitOnComma = formatMoney.split(',');
        const index0Arr = splitOnComma[0];
        const index1Arr = splitOnComma[1].substring(0, 1);

        const joined = `${[index0Arr, index1Arr].join('.')}k`;
        return joined;
    };

    const sliderStyle = {
        position: 'relative',
        width: '40%',
        height: 80,
        marginLeft: 15
    }

    const railStyle = {
        position: 'absolute',
        width: '100%',
        height: 10,
        marginTop: 35,
        borderRadius: 5,
        backgroundColor: '#8B9CB6',
    }

    useEffect(() => {
        loadDealers();
    }, []);

    useEffect(() => {
        function enterKeyPressed(event) {
            if (event.keyCode === 13) {
                if (zipCodeButton.current) {
                    zipCodeButton.current.click();
                }
            }
        }

        zipCodeInput.current.addEventListener("keyup", enterKeyPressed);

        return () => {
            if (zipCodeInput.current) {
                zipCodeInput.current.removeEventListener("keyup", enterKeyPressed);
            }
        };
    }, [zipCodeInput]);

    return (
        <Page>
            <Modal closeModal={() => handleZoneCreationCancel()} visible={modalVisibility}>
                <div className="map_confirmation_modal_wrapper">
                    <h3 className="title form_title">Create new dealer area for:</h3>
                    <form>
                        <div className="form_group">
                            <div>
                                <label>Dealer:</label>
                                <select onChange={(e) => setSelectedDealer(Number(e.target.value))}>
                                    {dealers &&
                                        dealers.length &&
                                        dealers.map((el) => {
                                            return (
                                                <option key={el.id} value={el.id}>
                                                    {el.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                        <div className="form_group">
                            <div>
                                <label>Service Area:</label>
                                <select onChange={(e) => setSelectedServiceArea(e.target.value)}>
                                    <option value="exclusiveServiceArea">Exclusive Service Area</option>
                                    <option value="captureServiceArea">Capture Service Area</option>
                                </select>
                            </div>
                        </div>
                        <div className="button form_button primary" onClick={handleZoneCreation}>
                            Submit
                        </div>
                    </form>
                </div>
            </Modal>

            <TopBar title="Dealers & Branches Map">
                <Button className="mr-4 text-sm" type="button" onClick={() => loadPaginatedQuotes()} color="blue" style="outline" rounded>
                    Load Quote Requests
                </Button>

                {authContext?.hasAnyGrant(Scope.Installs.READ, Scope.Installs.READ_ANY) &&
                    <Button className="mr-4 text-sm" type="button" onClick={() => loadPaginatedInstalls()} color="blue" style="outline" rounded >
                        Load Installs
                    </Button>
                }
                {((currentCount > 0 && maxNumber > 0) && (currentCount === maxNumber) && (installs.current.length > 1) &&
                    <Button className="mr-4 text-sm" type="button" onClick={() => setShowHeatMapStatus()} color="red" style="outline" rounded >
                        {setHeatMapBtnText()}
                    </Button>
                )}

                <div className="relative flex">
                    <input
                        ref={zipCodeInput}
                        className="input w-96"
                        placeholder="enter address"
                        type="text"
                        onChange={(e) => setSearchCriteria(e.target.value)}
                    />
                    <div ref={zipCodeButton} onClick={searchAddress} className="z-50 btn bg-theme_blue text-white p-2 capitalize">
                        Search
                    </div>
                    <div className="flex items-center justify-center max-h-full bg-white">
                        <button className="w-8 h-full text-lg" onClick={() => setZoomLevel(zoomLevel === 0 ? 0 : zoomLevel - 1)}>
                            -
                        </button>
                        <div className="bg-white text-gray-500">{zoomLevel}</div>
                        <button className="w-8 h-full" onClick={() => setZoomLevel(zoomLevel + 1)}>
                            +
                        </button>
                    </div>
                </div>

                <div>
                    <Select
                        className="mx-4 quotes_date_dropdown"
                        defaultValue={{
                            value: {
                                start: GetDate.nDaysAgo(30),
                                end: GetDate.tomorrow(),
                                name: "last30Days",
                            },
                            label: "Last 30 Days",
                        }}
                        options={[
                            {
                                value: {
                                    start: GetDate.january(),
                                    end: GetDate.tomorrow(),
                                    name: "yearToDate",
                                },
                                label: "Year to Date",
                            },
                            {
                                value: {
                                    start: GetDate.nDaysAgo(30),
                                    end: GetDate.tomorrow(),
                                    name: "last30Days",
                                },
                                label: "Last 30 Days",
                            },
                            {
                                value: {
                                    start: new Date(),
                                    end: new Date(),
                                    name: 'custom'
                                },
                                label: 'Custom Range'
                            },
                            {
                                value: {
                                    start: GetDate.currentMonth(),
                                    end: GetDate.tomorrow(),
                                    name: "monthToDate",
                                },
                                label: "Month to Date",
                            },
                            {
                                value: {
                                    start: GetDate.everlightsBirth(),
                                    end: GetDate.tomorrow(),
                                    name: "allTime",
                                },
                                label: "All Time",
                            },
                            ...GetDate.years().map(el => (
                                {
                                    value: {
                                        start: el.start,
                                        end: el.end,
                                        name: `${el.start.getFullYear()}`,
                                    },
                                    label:  `${el.start.getFullYear()}`
                                }
                            )),
                            {
                                value: {
                                    start: GetDate.january(),
                                    end: GetDate.february(),
                                    name: "january",
                                },
                                label: "January",
                            },
                            {
                                value: {
                                    start: GetDate.february(),
                                    end: GetDate.march(),
                                    name: "february",
                                },
                                label: "February",
                            },
                            {
                                value: {
                                    start: GetDate.march(),
                                    end: GetDate.april(),
                                    name: "march",
                                },
                                label: "March",
                            },
                            {
                                value: {
                                    start: GetDate.april(),
                                    end: GetDate.may(),
                                    name: "april",
                                },
                                label: "April",
                            },
                            {
                                value: {
                                    start: GetDate.may(),
                                    end: GetDate.june(),
                                    name: "may",
                                },
                                label: "May",
                            },
                            {
                                value: {
                                    start: GetDate.june(),
                                    end: GetDate.july(),
                                    name: "june",
                                },
                                label: "June",
                            },
                            {
                                value: {
                                    start: GetDate.july(),
                                    end: GetDate.august(),
                                    name: "july",
                                },
                                label: "July",
                            },
                            {
                                value: {
                                    start: GetDate.august(),
                                    end: GetDate.september(),
                                    name: "august",
                                },
                                label: "August",
                            },
                            {
                                value: {
                                    start: GetDate.september(),
                                    end: GetDate.october(),
                                    name: "september",
                                },
                                label: "September",
                            },
                            {
                                value: {
                                    start: GetDate.october(),
                                    end: GetDate.november(),
                                    name: "october",
                                },
                                label: "October",
                            },
                            {
                                value: {
                                    start: GetDate.november(),
                                    end: GetDate.december(),
                                    name: "november",
                                },
                                label: "November",
                            },
                            {
                                value: {
                                    start: GetDate.december(),
                                    end: GetDate.nextJanuary(),
                                    name: "december",
                                },
                                label: "December",
                            }
                        ]}
                        onChange={(val) => {
                            if (val.value.name === 'custom') {
                                setTimeFrame({ start: timeFrame.start, end: timeFrame.end, name: 'custom' });
                                return;
                            }
                            setTimeFrame(val.value)
                        }}
                    />
                </div>

                <div>
                    <Select
                        className="quotes_date_dropdown"
                        defaultValue={{
                            value: { filterOption: "All Quote Requests" },
                            label: "All Quote Requests",
                        }}
                        options={[
                            {
                                value: { filterOption: "All Quote Requests" },
                                label: "All Quote Requests",
                            },
                            {
                                value: { filterOption: "Customer" },
                                label: "Customer",
                            },
                            {
                                value: { filterOption: 'DIY' },
                                label: 'DIY'
                            },
                            {
                                value: {
                                    filterOption: 'Unserviceable',
                                },
                                label: 'Unserviceable'
                            }
                        ]}
                        onChange={(val) => setQuoteRequestsFilter(val)}
                    />
                </div>
            </TopBar>

            {timeFrame.name === 'custom' &&
                <div className="flex justify-end space-x-4">
                    <input
                        type="date"
                        className="flex border hover:border-input_hover text-center"
                        defaultValue={timeFrame.start.toISOString().split("T")[0]}
                        onBlur={(e) => {
                            const date = new Date(e.target.value).getTime();
                            if (isNaN(date)) {
                                return;
                            }
                            setTimeFrame({ start: new Date(date), end: timeFrame.end, name: 'custom' })
                        }}
                    />
                    <input
                        type="date"
                        className="flex border hover:border-input_hover text-center"
                        defaultValue={timeFrame.end.toISOString().split("T")[0]}
                        onBlur={(e) => {
                            const date = new Date(e.target.value).getTime();

                            if (isNaN(date)) {
                                return;
                            }
                            setTimeFrame({ start: timeFrame.start, end: new Date(date), name: 'custom' })
                        }}
                    />
                </div>
            }

            {(currentCount > 0 && maxNumber > 0) && (currentCount !== maxNumber) &&
                <ProgressBar2 count={currentCount} max={maxNumber} />}

            {(currentCountQuotes > 0 && maxQuotes > 0) && (currentCountQuotes !== maxQuotes) &&
                <ProgressBar2 count={currentCountQuotes} max={maxQuotes} />}

            {showHeatmap &&
                <Slider
                    rootStyle={sliderStyle}
                    domain={domain}
                    step={1}
                    mode={2}
                    onChange={(newValues) => changeValues(newValues)}
                    values={values}
                >
                    <Rail>
                        {({ getRailProps }) => (
                            <div style={railStyle} {...getRailProps()} />
                        )}
                    </Rail>

                    <Handles>
                        {({ handles, getHandleProps }) => (
                            <div className="slider-handles">
                                {handles.map(handle => (
                                    <Handle
                                        key={handle.id}
                                        handle={handle}
                                        domain={domain}
                                        getHandleProps={getHandleProps}
                                    />
                                ))}
                            </div>
                        )}
                    </Handles>

                    <Tracks left={false} right={false}>
                        {({ tracks, getTrackProps }) => (
                            <div className="slider-tracks">
                                {tracks.map(({ id, source, target }) => (
                                    <Track
                                        key={id}
                                        source={source}
                                        target={target}
                                        getTrackProps={getTrackProps}
                                    />
                                ))}
                            </div>
                        )}
                    </Tracks>

                    <Ticks count={15}>
                        {({ ticks }) => (
                            <div className="slider-ticks">
                                {ticks.map(tick => (
                                    <Tick key={tick.id} format={formatTicks} tick={tick} />
                                ))}
                            </div>
                        )}
                    </Ticks>
                </Slider>
            }

            <div className="relative flex w-full h-full overflow-hidden p-2 m-4 bg-white shadow-md rounded-sm">
                <div className="flex-1">
                    {filteredDealers ? (
                        <MapContainer
                            center={[40.31572, -111.7138524]}
                            zoom={9}
                            scrollWheelZoom={true}
                            ref={mapRef}
                            whenReady={() => setTimeout(() => setMap(mapRef.current), 100)} // Hacky, but it works
                            style={{ height: "100%", maxHeight: "100%", width: "100%", zIndex: 0 }}
                        >
                            <MyComponent handleZoomChange={(zoomLevel) => handleZoomChange(zoomLevel)} />
                            <LayersControl position="topright">

                                <LayersControl.BaseLayer checked name="Map">
                                    <TileLayer url={`http://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}`} />
                                </LayersControl.BaseLayer>

                                <LayersControl.BaseLayer name="Satellite">
                                    <TileLayer url={`http://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}`} />
                                </LayersControl.BaseLayer>

                                {authContext.hasAnyGrant(Scope.Company.UPDATE_ANY) &&
                                    <LayersControl.Overlay checked name="Edit Layer">
                                        <LayerGroup>
                                            <FeatureGroup
                                                ref={(featureGroupRef) => {
                                                    onFeatureGroupReady(featureGroupRef);
                                                }}
                                            >
                                                {editInfo &&
                                                    editInfo.area === "exclusiveServiceArea" &&
                                                    editInfo.dealer.exclusiveServiceAreas.map((el, i) => {
                                                        return el.type === "circle" ? (
                                                            <Circle
                                                                key={`${editInfo.dealer.id} ${i}`}
                                                                dealerId={editInfo.dealer.id}
                                                                index={i}
                                                                areaShape={"circle"}
                                                                areaType={"exclusiveServiceArea"}
                                                                center={el.area.center}
                                                                radius={el.area.radiusMeters}
                                                                pathOptions={{ color: "rgba(250, 0, 0, 1)" }}
                                                            >
                                                                <Tooltip>Name: {editInfo.dealer.name}</Tooltip>
                                                            </Circle>
                                                        ) : (
                                                            <Polygon
                                                                key={`${editInfo.dealer.id} ${i}`}
                                                                dealerId={editInfo.dealer.id}
                                                                index={i}
                                                                areaShape={"polygon"}
                                                                areaType={"exclusiveServiceArea"}
                                                                positions={el.area.vertices}
                                                                pathOptions={{ color: "rgba(250,0,0,1)" }}
                                                            >
                                                                <Tooltip>Name: {editInfo.dealer.name}</Tooltip>
                                                            </Polygon>
                                                        );
                                                    })}

                                                {editInfo &&
                                                    editInfo.area === "captureServiceArea" &&
                                                    editInfo.dealer.captureServiceAreas.map((el, i) => {
                                                        return el.type === "circle" ? (
                                                            <Circle
                                                                key={`${editInfo.dealer.id} ${i}`}
                                                                dealerId={editInfo.dealer.id}
                                                                index={i}
                                                                areaShape={"circle"}
                                                                areaType={"captureServiceArea"}
                                                                center={el.area.center}
                                                                radius={el.area.radiusMeters}
                                                                pathOptions={{ color: "rgba(0,0,250,1)" }}
                                                            >
                                                                <Tooltip>Name: {editInfo.dealer.name}</Tooltip>
                                                            </Circle>
                                                        ) : (
                                                            <Polygon
                                                                key={`${editInfo.dealer.id} ${i}`}
                                                                dealerId={editInfo.dealer.id}
                                                                index={i}
                                                                areaShape={"polygon"}
                                                                areaType={"captureServiceArea"}
                                                                positions={el.area.vertices}
                                                                pathOptions={{ color: "rgba(0,0,250,1)" }}
                                                            >
                                                                <Tooltip>Name: {editInfo.dealer.name}</Tooltip>
                                                            </Polygon>
                                                        );
                                                    })}

                                                <EditControl
                                                    key={0}
                                                    position="topleft"
                                                    onEdited={_onEdited}
                                                    onCreated={_onCreated}
                                                    onDeleted={_onDeleted}
                                                    onEditStart={_onEditStart}
                                                    onEditStop={_onEditStop}
                                                    onDeleteStart={_onDeleteStart}
                                                    onDeleteStop={_onDeleteStop}
                                                    draw={{
                                                        rectangle: false,
                                                        circle: {
                                                            shapeOptions: { color: "gray" },
                                                        },
                                                        polygon: {
                                                            allowIntersection: false,
                                                            shapeOptions: { color: "gray" },
                                                        },
                                                        polyline: false,
                                                        circlemarker: false,
                                                        marker: false,
                                                    }}
                                                />
                                            </FeatureGroup>
                                        </LayerGroup>
                                    </LayersControl.Overlay>
                                }

                                <LayersControl.Overlay checked name="Dealers">
                                    <LayerGroup>
                                        {filteredDealers.map((dealer) => {
                                            return (
                                                <React.Fragment key={dealer.id}>
                                                    {dealer.exclusiveServiceAreas.map((el, i) => {
                                                        return el.type === "circle" ? (
                                                            <Circle
                                                                key={`${dealer.id} ${i}`}
                                                                dealerId={dealer.id}
                                                                index={i}
                                                                areaShape={"circle"}
                                                                areaType={"exclusiveServiceArea"}
                                                                center={el.area.center}
                                                                radius={el.area.radiusMeters}
                                                                pathOptions={editInfo ? { color: "rgba(250, 0, 0, .3)" } : { color: "rgba(250,0,0,.3)" }}
                                                            >
                                                                <Tooltip>
                                                                    <div className="text-gray-500 text-xl flex items-end">Name: {dealer.name}</div>
                                                                    <div className="text-gray-500 flex items-end">
                                                                        Type: <div className="text-gray-500 text-lg">{dealer.companyType}</div>
                                                                    </div>
                                                                    <div className="text-gray-500 flex items-end">
                                                                        Phone: <div className="text-blue-400 text-lg">{dealer.phone}</div>
                                                                    </div>
                                                                </Tooltip>
                                                            </Circle>
                                                        ) : (
                                                            <Polygon
                                                                key={`${dealer.id} ${i}`}
                                                                dealerId={dealer.id}
                                                                index={i}
                                                                areaShape={"polygon"}
                                                                areaType={"exclusiveServiceArea"}
                                                                positions={el.area.vertices}
                                                                pathOptions={editInfo ? { color: "rgba(250, 0, 0, .3)" } : { color: "rgba(250,0, 0, .3)" }}
                                                            >
                                                                <Tooltip>
                                                                    <div className="text-gray-500 text-xl flex items-end">Name: {dealer.name}</div>
                                                                    <div className="text-gray-500 flex items-end">
                                                                        Type: <div className="text-gray-500 text-lg">{dealer.companyType}</div>
                                                                    </div>
                                                                    <div className="text-gray-500 flex items-end">
                                                                        Phone: <div className="text-blue-400 text-lg">{dealer.phone}</div>
                                                                    </div>
                                                                </Tooltip>
                                                            </Polygon>
                                                        );
                                                    })}
                                                    {dealer.captureServiceAreas.map((el, i) => {
                                                        return el.type === "circle" ? (
                                                            <Circle
                                                                key={`${dealer.id} ${i}`}
                                                                dealerId={dealer.id}
                                                                index={i}
                                                                areaShape={"circle"}
                                                                areaType={"captureServiceArea"}
                                                                center={el.area.center}
                                                                radius={el.area.radiusMeters}
                                                                pathOptions={editInfo ? { color: "rgba(0,0,250, .3)" } : { color: "rgba(0,0,250, .3)" }}
                                                            >
                                                                <Tooltip>
                                                                    <div className="text-gray-500 text-xl flex items-end">Name: {dealer.name}</div>
                                                                    <div className="text-gray-500 flex items-end">
                                                                        Type: <div className="text-gray-500 text-lg">{dealer.companyType}</div>
                                                                    </div>
                                                                    <div className="text-gray-500 flex items-end">
                                                                        Phone: <div className="text-blue-400 text-lg">{dealer.phone}</div>
                                                                    </div>
                                                                    <div className="text-gray-500 flex items-end">
                                                                        Email: <div className="text-gray-500 text-lg">{dealer.email}</div>
                                                                    </div>

                                                                </Tooltip>
                                                            </Circle>
                                                        ) : (
                                                            <Polygon
                                                                key={`${dealer.id} ${i}`}
                                                                dealerId={dealer.id}
                                                                index={i}
                                                                areaShape={"polygon"}
                                                                areaType={"captureServiceArea"}
                                                                positions={el.area.vertices}
                                                                pathOptions={editInfo ? { color: "rgba(0,0,250, .3)" } : { color: "rgba(0,0,250, .3)" }}
                                                            >
                                                                <Tooltip>
                                                                    <div className="text-gray-500 text-xl flex items-end">Name: {dealer.name}</div>
                                                                    <div className="text-gray-500 flex items-end">
                                                                        Type: <div className="text-gray-500 text-lg">{dealer.companyType}</div>
                                                                    </div>
                                                                    <div className="text-gray-500 flex items-end">
                                                                        Phone: <div className="text-blue-400 text-lg">{dealer.phone}</div>
                                                                    </div>
                                                                    <div className="text-gray-500 flex items-end">
                                                                        Email: <div className="text-gray-500 text-lg">{dealer.email}</div>
                                                                    </div>
                                                                </Tooltip>
                                                            </Polygon>
                                                        );
                                                    })}
                                                    {dealer.address && dealer.address.geoPoint ? (
                                                        <Marker key={`${dealer.id} headquarters`} position={dealer.address.geoPoint.latlng} icon={dealer.companyType === CompanyType.DEALER ? blueIcon : greenIcon}>
                                                            <Popup>
                                                                <div className="flex flex-col w-full">
                                                                    <div className="text-gray-500 text-2xl flex items-end w-full text-center">
                                                                        {dealer.name}
                                                                    </div>
                                                                    <div className="text-gray-500 flex items-end">
                                                                        <div className="text-gray-500 text-lg capitalize">{dealer.companyType}</div>
                                                                    </div>
                                                                    <div className="text-gray-500 flex items-end">
                                                                        <div className="text-gray-500 text-lg">
                                                                            {dealer.address.streetAddress}, {dealer.address.city}, {dealer.address.state}{" "}
                                                                            {dealer.address.zip}
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-gray-500 flex items-end">
                                                                        <div className="text-blue-400 text-lg">{dealer.email}</div>
                                                                    </div>
                                                                    <div className="text-gray-500 flex items-end">
                                                                        <div className="text-blue-400 text-lg">{formatPhoneNumber(dealer.phone)}</div>
                                                                    </div>
                                                                </div>
                                                            </Popup>
                                                        </Marker>
                                                    ) : null}
                                                </React.Fragment>
                                            );
                                        })}

                                        {editInfo &&
                                            editInfo.area === "exclusiveServiceArea" && //If editInfo.area === "exclusiveServiceArea" then render captureServiceArea (since that's not being edited)
                                            editInfo.dealer.captureServiceAreas.map((el, i) => {
                                                return el.type === "circle" ? (
                                                    <Circle
                                                        key={`${editInfo.dealer.id} ${i}`}
                                                        dealerId={editInfo.dealer.id}
                                                        index={i}
                                                        areaShape={"circle"}
                                                        areaType={"captureServiceArea"}
                                                        center={el.area.center}
                                                        radius={el.area.radiusMeters}
                                                        pathOptions={editInfo ? { color: "rgba(0,0,250, .3)" } : { color: "rgba(0,0,250, .3)" }}
                                                    >
                                                        <Tooltip>Name: {editInfo.dealer.name}</Tooltip>
                                                    </Circle>
                                                ) : (
                                                    <Polygon
                                                        key={`${editInfo.dealer.id} ${i}`}
                                                        dealerId={editInfo.dealer.id}
                                                        index={i}
                                                        areaShape={"polygon"}
                                                        areaType={"captureServiceArea"}
                                                        positions={el.area.vertices}
                                                        pathOptions={editInfo ? { color: "rgba(0,0,250, .3)" } : { color: "rgba(0,0,250, .3)" }}
                                                    >
                                                        <Tooltip>Name: {editInfo.dealer.name}</Tooltip>
                                                    </Polygon>
                                                );
                                            })}

                                        {editInfo &&
                                            editInfo.area === "captureServiceArea" && //If editInfo.area === "captureServiceArea" then render exclusiveServiceArea (since that's not being edited)
                                            editInfo.dealer.exclusiveServiceAreas.map((el, i) => {
                                                return el.type === "circle" ? (
                                                    <Circle
                                                        imperial={true}
                                                        key={`${editInfo.dealer.id} ${i}`}
                                                        dealerId={editInfo.dealer.id}
                                                        index={i}
                                                        areaShape={"circle"}
                                                        areaType={"exclusiveServiceArea"}
                                                        center={el.area.center}
                                                        radius={el.area.radiusMeters}
                                                        pathOptions={editInfo ? { color: "rgba(250, 0, 0, .3)" } : { color: "rgba(250, 0, 0, .3)" }}
                                                    >
                                                        <Tooltip>Name: {editInfo.dealer.name}</Tooltip>
                                                    </Circle>
                                                ) : (
                                                    <Polygon
                                                        key={`${editInfo.dealer.id} ${i}`}
                                                        dealerId={editInfo.dealer.id}
                                                        index={i}
                                                        areaShape={"polygon"}
                                                        areaType={"exclusiveServiceArea"}
                                                        positions={el.area.vertices}
                                                        pathOptions={editInfo ? { color: "rgba(250, 0, 0, .3)" } : { color: "rgba(250, 0, 0, .3)" }}
                                                    >
                                                        <Tooltip>Name: {editInfo.dealer.name}</Tooltip>
                                                    </Polygon>
                                                );
                                            })}
                                    </LayerGroup>
                                </LayersControl.Overlay>

                                <LayersControl.Overlay name="Quote Requests">
                                    <LayerGroup>
                                        {displayedQuotes &&
                                            displayedQuotes.map(
                                                (quoteRequest) =>
                                                    quoteRequest.address &&
                                                    quoteRequest.address.geoPoint &&
                                                    (quoteRequest.installationType === "professional" ? (
                                                        <Circle
                                                            key={`${quoteRequest.id} quote request id`}
                                                            center={[quoteRequest.address.geoPoint.latlng[0], quoteRequest.address.geoPoint.latlng[1]]}
                                                            radius={radius}
                                                            pathOptions={editInfo ? { color: "rgba(250, 0, 0, .3)" } : { color: "rgba(250,0,0,.3)" }}
                                                        >
                                                            <Popup>
                                                                <div className="text-lg text-gray-600">
                                                                    {quoteRequest.contact.firstname} {quoteRequest.contact.lastname}
                                                                </div>
                                                                <div>{quoteRequest.contact.email}</div>
                                                                <div>{formatPhoneNumber(quoteRequest.contact.phone)}</div>
                                                                <div>
                                                                    {quoteRequest.address.streetAddress}, {quoteRequest.address.state}
                                                                </div>
                                                                <div>
                                                                    {quoteRequest.address.city}, {quoteRequest.address.state}
                                                                </div>
                                                                <div className="uppercase mt-4">{quoteRequest.buildingType}</div>
                                                                <div className="uppercase mb-4">{quoteRequest.installationType}</div>
                                                                <div className="my-3">{new Date(quoteRequest.createdAt).toLocaleDateString()}</div>
                                                                <Link to={`/quoteRequests/${quoteRequest.id}`}>Go to Quote Request</Link>
                                                            </Popup>
                                                        </Circle>
                                                    ) : (
                                                        <Circle
                                                            key={`${quoteRequest.id} quote request id`}
                                                            center={[quoteRequest.address.geoPoint.latlng[0], quoteRequest.address.geoPoint.latlng[1]]}
                                                            radius={radius}
                                                            pathOptions={editInfo ? { color: "rgba(155,0,255, .5)" } : { color: "rgba(155,0,255,.5)" }}
                                                        >
                                                            <Popup>
                                                                <div className="text-lg text-gray-600">
                                                                    {quoteRequest.contact.firstname} {quoteRequest.contact.lastname}
                                                                </div>
                                                                <div>{quoteRequest.contact.email}</div>
                                                                <div>{formatPhoneNumber(quoteRequest.contact.phone)}</div>
                                                                <div>
                                                                    {quoteRequest.address.streetAddress}, {quoteRequest.address.state}
                                                                </div>
                                                                <div>
                                                                    {quoteRequest.address.city}, {quoteRequest.address.state}
                                                                </div>
                                                                <div className="uppercase mt-4">{quoteRequest.buildingType}</div>
                                                                <div className="uppercase mb-4">{quoteRequest.installationType}</div>
                                                                <div className="my-3">{new Date(quoteRequest.createdAt).toLocaleDateString()}</div>
                                                                <Link to={`/quoteRequests/${quoteRequest.id}`}>Go to Quote Request</Link>
                                                            </Popup>
                                                        </Circle>
                                                    ))
                                            )}
                                    </LayerGroup>
                                </LayersControl.Overlay>

                                {authContext.hasAnyGrant(Scope.Installs.READ_ANY, Scope.Installs.READ) &&
                                    <LayersControl.Overlay name="Installs">
                                        <LayerGroup>
                                            {displayInstalls &&
                                                displayInstalls.map((install) => {
                                                    if (!install.customer.geoPoint) return;
                                                    return (
                                                        <Circle key={install.id} center={[install.customer.geoPoint.latlng[0], install.customer.geoPoint.latlng[1]]} radius={radius} pathOptions={showHeatmap ? { color: getHeatmapColor(install) } : { color: "rgba(250,0,0,1)" }}>
                                                            <Popup>
                                                                <div className="text-lg text-gray-600">{install.customer.firstName} {install.customer.lastName}</div>
                                                                <div className="text-lg">Total: {formatMoney(install.total)}</div>
                                                                <div>{install.customer.email}</div>
                                                                <div>{install.customer.address}</div>
                                                                <div className="my-3">{new Date(install.createdAt).toLocaleDateString()}</div>
                                                            </Popup>
                                                        </Circle>
                                                    )
                                                })}
                                        </LayerGroup>
                                    </LayersControl.Overlay>
                                }

                                <LayersControl.Overlay checked name="Address Marker">
                                    <LayerGroup>{addressGeoPoint && <Circle key={`${addressGeoPoint}`} center={addressGeoPoint} radius={200} pathOptions={{ color: "rgba(255,0,0, 1)" }}></Circle>}</LayerGroup>
                                </LayersControl.Overlay>

                            </LayersControl>
                            <ScaleControl imperial={true} metric={true} />
                        </MapContainer>
                    ) : (
                        <Loading center />
                    )}
                </div>

                <div className="flex flex-col  overflow-y-auto mr-2" style={{ minWidth: "440px" }}>
                    {
                        map && filteredDealersSideBar ? (
                            filteredDealersSideBar.length ? (
                                filteredDealersSideBar.map((el) => {
                                    return <DealerTab key={el.id} map={map} dealer={el} edit={(dealer, area) => editZone(dealer, area)} create={createZone} />;
                                })
                            ) : (
                                <div className="text-gray-500 text-center mt-5">No Dealers</div>
                            )
                        ) : null // or loading
                    }
                </div>
            </div>
        </Page>
    );
}
