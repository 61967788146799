import React, { useEffect, useState } from "react";
import { RetrieveLeadsCount, RetrieveLeads } from "../../services/leads";

import AuthContext from "../../contexts/AuthContext";
import { Address, IContact, ILead, Scope } from "@common.abstractions";
import { formatBuildingType, formatPhoneNumber, formatProductLine, GetDate, objectsToCSV } from "@common.tools";
import { Between, Equals, Exists, Not } from "@common.api";
import { Loading } from "@frontend/components/misc/loading";
import TopBar from "@frontend/components/misc/top-bar/TopBar";
import SearchBar from "@frontend/components/misc/search-bar/SearchBar";
import Button from "@frontend/components/misc/button/Button";
import { TableHeader, TableRow, TableRowWrapper, TableTabBar, TableTextColor } from "@frontend/components/misc/table";
import Select from "react-select";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom-v5-compat";

enum LeadTab {
    ALL = "All",
    UNOPENED = "Unopened",
    OPENED = "Opened",
    CLOSED = "Sold",
    LOST = "Lost",
}

export default function Leads() {
    const authContext = React.useContext(AuthContext);

    const [searchCriteria, setSearchCriteria] = useState<string>("");
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState<LeadTab>(LeadTab.ALL);
    const [exporting, setExporting] = useState<boolean>(false);
    const [timeFrame, setTimeFrame] = useState<{
        start: Date;
        end: Date;
        name: string;
    }>({ start: GetDate.nDaysAgo(30), end: GetDate.tomorrow(), name: "last30Days" });

    const [displayLeads, setDisplayLeads] = React.useState<ILead[]>();
    const allLeads = React.useRef<ILead[]>();
    const unopenedLeads = React.useRef<ILead[]>();
    const openedLeads = React.useRef<ILead[]>();
    const closedLeads = React.useRef<ILead[]>();
    const lostLeads = React.useRef<ILead[]>();

    const [unopendCount, setUnopendCount] = React.useState<number>();
    const [viewedCount, setViewedCount] = React.useState<number>();
    const [lostCount, setLostCount] = React.useState<number>();
    const [closedCount, setClosedCount] = React.useState<number>();
    const [allCount, setAllCount] = React.useState<number>();

    const cursor = React.useRef<string>();
    const [loadingLeads, setLoadingLeads] = React.useState<boolean>(false);

    const clearLoadedLeads = () => {
        allLeads.current = [];
        unopenedLeads.current = [];
        openedLeads.current = [];
        closedLeads.current = [];
        lostLeads.current = [];
    }

    const clearCounts = () => {
        setUnopendCount(undefined);
        setViewedCount(undefined);
        setLostCount(undefined);
        setClosedCount(undefined);
        setAllCount(undefined);
    }

    const loadCounts = async () => {
        const unopend = await RetrieveLeadsCount({
            viewedDate: Not(Exists()),
            createdAt: Between(timeFrame.start, timeFrame.end),
        });
        const viewed = await RetrieveLeadsCount({
            viewedDate: Exists(),
            vConverted: Not(Equals(true)),
            manualMarkLostDate: Not(Exists()),
            createdAt: Between(timeFrame.start, timeFrame.end),
        });
        const lost = await RetrieveLeadsCount({
            manualMarkLostDate: Exists(),
            createdAt: Between(timeFrame.start, timeFrame.end),
        });
        const closed = await RetrieveLeadsCount({
            vConverted: Equals(true),
            createdAt: Between(timeFrame.start, timeFrame.end),
        });

        const all = await RetrieveLeadsCount({ createdAt: Between(timeFrame.start, timeFrame.end) });

        setAllCount(all);
        setClosedCount(closed);
        setLostCount(lost);
        setViewedCount(viewed);
        setUnopendCount(unopend);
    };

    const loadAll = async () => {
        await loadLeads(100);
        if (cursor.current) {
            setTimeout(() => loadAll(), 200)
        }
    }

    const loadLeads = async (num: number) => {
        setLoadingLeads(true);

        console.log(`Loading ${num} more leads`)
        if (allLeads.current?.length && !cursor.current) {
            console.log("no more leads")
            return;
        } // We've loaded all of them.

        const response = await RetrieveLeads({
            filters: {
                createdAt: Between(timeFrame.start, timeFrame.end),
            },
            relations: ["quoteRequest", "quoteRequest.contact", "company", "customerContract", "orders"],
        }, {
            direction: "forward",
            count: num,
            cursor: cursor.current,
        });

        if (!response) {
            console.warn("No response from load leads call");
            setLoadingLeads(false);
            return;
        }

        if (!response.pagination) {
            console.error("Response missing pagination information");
            return;
        }

        cursor.current = response.pagination.end_cursor;

        const retrievedLeads = response.values;

        retrievedLeads.sort((a, b) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });

        const uniqueLeadsSet = new Set(allLeads.current?.map((lead) => lead.id));
        const uniqueLeads = retrievedLeads.filter(lead => !uniqueLeadsSet.has(lead.id));
        uniqueLeads.forEach(lead => uniqueLeadsSet.add(lead.id));

        // allLeads.current = [...(allLeads.current ?? []), ...retrievedLeads]; // may duplicate rows in rare instances. TODO: Fix this eventually
        allLeads.current = [...(allLeads.current ?? []), ...uniqueLeads]; // fix duplicate rows

        sortTabs();
        setDisplayedLeadsFromTab(selectedTab);
        setLoadingLeads(false);
    };


    const searchFilter = (entity: ILead) => {
        const s = searchCriteria.toLowerCase();
        const contact = entity.quoteRequest?.contact as IContact;
        const address = entity.quoteRequest?.address as Address;
        if (
            searchCriteria === "" ||
            (contact.firstname + " " + contact.lastname).toLowerCase().includes(s) ||
            contact.phone.toLowerCase().includes(s) ||
            address.city.toLowerCase().includes(s) ||
            address.zip.toLowerCase().includes(s) ||
            address.streetAddress.toLowerCase().includes(s) ||
            address.state.toLowerCase().includes(s)
        ) {
            return true;
        }
        return false;
    };

    const getLeadStatus = (lead: ILead) => {
        if (lead.vConverted) return "Converted";
        if (lead.deletedAt) return "Expired";
        if (!lead.viewedDate) return "Unopened";
        if (lead.manualMarkLostDate) return "Lost";
        if (!((lead.deletedAt || !lead.vConverted) && lead.orders?.length)) return "In Progress";
        if (lead.viewedDate) return "Opened";
        return "Unknown";
    };

    const handleExport = async () => {
        setExporting(true);

        let allLeads: ILead[] = [];
        let cursor: string | undefined;

        do {
            let leads = await RetrieveLeads({
                filters: {
                    createdAt: Between(timeFrame.start, timeFrame.end),

                },
                relations: ["quoteRequest", 'quoteRequest.contact', 'company']
            },
                {
                    direction: 'forward',
                    count: 40,
                    cursor: cursor
                });

            await new Promise(r => setTimeout(r, 200)); // sleep 200ms

            if (!leads) continue;

            if (!leads.pagination) {
                console.error('Response missing pagination information');
                setExporting(false);
                return;
            }

            cursor = leads.pagination.end_cursor
            allLeads = allLeads.concat(leads.values);
        } while (cursor)

        const csv = objectsToCSV(allLeads, {
            "Dealer": i => i.company?.name ? i.company.name : '',
            "Created At": i => i.quoteRequest?.createdAt ? new Date(i.quoteRequest.createdAt).toLocaleDateString() : '',
            "First Name": i => i.quoteRequest?.contact?.firstname ? i.quoteRequest?.contact?.firstname : '',
            "Last Name": i => i.quoteRequest?.contact?.lastname ? i.quoteRequest?.contact?.lastname : '',
            "Email": i => i.quoteRequest?.contact?.email ? i.quoteRequest?.contact?.email : '',
            "Phone": i => i.quoteRequest?.contact?.phone ? i.quoteRequest?.contact?.phone : '',
            "Origin": i => i.quoteRequest?.origin ? i.quoteRequest.origin : '',
            "Product Lines": i => i.quoteRequest?.productLines ? i.quoteRequest.productLines.toString() : '',
            "Installation Type": i => i.quoteRequest?.installationType ? i.quoteRequest.installationType : '',
            "Building Type": i => i.quoteRequest?.buildingType ? i.quoteRequest.buildingType : '',
            "Country": i => i.quoteRequest?.address.country ? i.quoteRequest?.address.country : '',
            "State": i => i.quoteRequest?.address.state ? i.quoteRequest.address.state : '',
            "City": i => i.quoteRequest?.address.city ? i.quoteRequest.address.city : '',
            "Street Address": i => i.quoteRequest?.address.streetAddress ? i.quoteRequest?.address.streetAddress : '',
            "Zip": i => i.quoteRequest?.address.zip ? i.quoteRequest.address.zip : '',
            "Additional Info": i => i.quoteRequest?.additionalInfo ? i.quoteRequest.additionalInfo : ''
        })

        let encodedCSV = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);

        let downloadLink = document.createElement("a");
        downloadLink.download = `Leads_${new Date().toLocaleDateString()}.csv`;
        downloadLink.href = encodedCSV;
        downloadLink.click();

        setExporting(false);
    };

    const setDisplayedLeadsFromTab = (tab: LeadTab) => {
        switch (tab) {
            case LeadTab.ALL:
                setDisplayLeads(allLeads.current);
                break;
            case LeadTab.UNOPENED:
                setDisplayLeads(unopenedLeads.current);
                break;
            case LeadTab.OPENED:
                setDisplayLeads(openedLeads.current);
                break;
            case LeadTab.CLOSED:
                setDisplayLeads(closedLeads.current);
                break;
            case LeadTab.LOST:
                setDisplayLeads(lostLeads.current);
                break;
            default:
                setDisplayLeads(allLeads.current);
                break;
        }
    }

    const sortTabs = () => {
        const unopened: ILead[] = [];
        const opened: ILead[] = [];
        const closed: ILead[] = [];;
        const lost: ILead[] = [];

        allLeads.current?.forEach((lead) => {
            if (!lead.viewedDate) {
                unopened.push(lead);
            }
            if (lead.vConverted) {
                closed.push(lead);
            }
            if (lead.manualMarkLostDate) {
                lost.push(lead);
            }
            if (lead.viewedDate && !lead.manualMarkLostDate && !lead.vConverted) {
                opened.push(lead);
            }
        })

        unopenedLeads.current = unopened;
        openedLeads.current = opened;
        closedLeads.current = closed;
        lostLeads.current = lost;
    }

    const getTableHeaders = () => {
        const headers = [];
        headers.push({ title: "Assigned Date" });

        if (authContext?.hasAnyGrant(Scope.Lead.READ_ANY)) {
            headers.push({ title: 'Assigned to' });
        }

        headers.push(
            { title: "Submission Date" },
            { title: "Source" },
            { title: "Status" },
            { title: "Contact" },
            { title: "Address" },
            { title: "Building Type" },
            { title: "Product Line" }
        )

        return headers;
    }

    const getTableRows = (lead: ILead) => {
        const rows = [];

        rows.push({ text: { title: new Date(lead.createdAt).toLocaleDateString() } })

        if (authContext?.hasAnyGrant(Scope.Lead.READ_ANY)) {
            rows.push({ text: { title: lead.company?.name ?? 'unknown' } })
        }

        rows.push(
            {
                text: {
                    title: lead.quoteRequest
                        ? new Date(
                            lead.quoteRequest?.createdAt
                        ).toLocaleDateString()
                        : "",
                },
            },
            {
                text: {
                    title: lead.quoteRequest
                        ? lead.quoteRequest.origin
                        : "Unknown",
                },
            },
            {
                text: {
                    title: getLeadStatus(lead)
                },
                contractSigned: lead.customerContract?.signedDate ? true : false
            },
            {
                text: {
                    title: lead.quoteRequest?.contact
                        ? lead.quoteRequest.contact.firstname +
                        " " +
                        lead.quoteRequest.contact.lastname
                        : "",
                    subtitle: [
                        lead.quoteRequest?.contact
                            ? lead.quoteRequest.contact.email
                            : "",
                        lead.quoteRequest?.contact
                            ? formatPhoneNumber(lead.quoteRequest.contact.phone)
                            : "",
                    ],
                },
            },
            {
                text: {
                    title: lead.quoteRequest?.contact
                        ? `${lead.quoteRequest.address.streetAddress}, ${lead.quoteRequest.address.city}, ${lead.quoteRequest.address.state} ${lead.quoteRequest.address.zip}`
                        : "",
                },
            },
            {
                text: {
                    title: lead.quoteRequest?.buildingType
                        ? formatBuildingType(lead.quoteRequest.buildingType)
                        : "",
                },
            },
            {
                text: {
                    title: lead.quoteRequest?.productLines
                        ? lead.quoteRequest.productLines
                            .map((pl) => formatProductLine(pl))
                            .join(", ")
                        : "",
                },
            },
            {
                text: { title: "Quote Request", to: (`leads/${lead.id}`), color: "blue" as TableTextColor },
            },
        )

        return rows
    }

    useEffect(() => {
        cursor.current = undefined;
        clearLoadedLeads();
        loadLeads(100);
        loadCounts();
    }, [timeFrame]);

    useEffect(() => {
        setDisplayedLeadsFromTab(selectedTab);
    }, [selectedTab]);

    return (
        <div className="w-full h-full max-w-full">
            <div className="flex flex-col h-full w-full max-w-full overflow-hidden">
                <TopBar title="Leads">
                    <div className="flex sm:items-center mb-2">
                        <Button
                            className="w-full sm:w-auto mb-2 sm:mb-0 mr-2"
                            type="button"
                            color="blue"
                            style="outline"
                            rounded
                            onClick={() => navigate("/leads/create")}
                        >
                            Create Lead
                        </Button>
                        <Button
                            className="w-full sm:w-auto mb-2 sm:mb-0 mr-2"
                            type="button"
                            color="green"
                            style="outline"
                            rounded
                            onClick={handleExport}
                        >
                            Export CSV
                        </Button>
                    </div>
                    <SearchBar
                        placeholder="Search Quote Requests"
                        search={setSearchCriteria}
                        className="w-full sm:w-96 mb-2 sm:mb-0 sm:mr-4"
                    />
                    <Select
                        className="w-full sm:w-auto quotes_date_dropdown mb-2 sm:mb-0"
                        defaultValue={{
                            value: {
                                start: GetDate.nDaysAgo(30),
                                end: GetDate.tomorrow(),
                                name: "last30Days",
                            },
                            label: "Last 30 Days",
                        }}
                        options={[
                            {
                                value: {
                                    start: GetDate.january(),
                                    end: GetDate.tomorrow(),
                                    name: "yearToDate",
                                },
                                label: "Year to Date",
                            },
                            {
                                value: {
                                    start: GetDate.nDaysAgo(30),
                                    end: GetDate.tomorrow(),
                                    name: "last30Days",
                                },
                                label: "Last 30 Days",
                            },
                            {
                                value: {
                                    start: new Date(),
                                    end: new Date(),
                                    name: 'custom'
                                },
                                label: 'Custom Range'
                            },
                            {
                                value: {
                                    start: GetDate.currentMonth(),
                                    end: GetDate.tomorrow(),
                                    name: "monthToDate",
                                },
                                label: "Month to Date",
                            },
                            {
                                value: {
                                    start: GetDate.everlightsBirth(),
                                    end: GetDate.tomorrow(),
                                    name: "allTime",
                                },
                                label: "All Time",
                            },
                            ...GetDate.years().map(el => (
                                {
                                    value: {
                                        start: el.start,
                                        end: el.end,
                                        name: `${el.start.getFullYear()}`,
                                    },
                                    label:  `${el.start.getFullYear()}`
                                }
                            )),
                            {
                                value: {
                                    start: GetDate.january(),
                                    end: GetDate.february(),
                                    name: "january",
                                },
                                label: "January",
                            },
                            {
                                value: {
                                    start: GetDate.february(),
                                    end: GetDate.march(),
                                    name: "february",
                                },
                                label: "February",
                            },
                            {
                                value: {
                                    start: GetDate.march(),
                                    end: GetDate.april(),
                                    name: "march",
                                },
                                label: "March",
                            },
                            {
                                value: {
                                    start: GetDate.april(),
                                    end: GetDate.may(),
                                    name: "april",
                                },
                                label: "April",
                            },
                            {
                                value: {
                                    start: GetDate.may(),
                                    end: GetDate.june(),
                                    name: "may",
                                },
                                label: "May",
                            },
                            {
                                value: {
                                    start: GetDate.june(),
                                    end: GetDate.july(),
                                    name: "june",
                                },
                                label: "June",
                            },
                            {
                                value: {
                                    start: GetDate.july(),
                                    end: GetDate.august(),
                                    name: "july",
                                },
                                label: "July",
                            },
                            {
                                value: {
                                    start: GetDate.august(),
                                    end: GetDate.september(),
                                    name: "august",
                                },
                                label: "August",
                            },
                            {
                                value: {
                                    start: GetDate.september(),
                                    end: GetDate.october(),
                                    name: "september",
                                },
                                label: "September",
                            },
                            {
                                value: {
                                    start: GetDate.october(),
                                    end: GetDate.november(),
                                    name: "october",
                                },
                                label: "October",
                            },
                            {
                                value: {
                                    start: GetDate.november(),
                                    end: GetDate.december(),
                                    name: "november",
                                },
                                label: "November",
                            },
                            {
                                value: {
                                    start: GetDate.december(),
                                    end: GetDate.nextJanuary(),
                                    name: "december",
                                },
                                label: "December",
                            }
                        ]}
                        onChange={(val) => {
                            if (val?.value.name === 'custom') {
                                setTimeFrame({ start: timeFrame.start, end: timeFrame.end, name: 'custom' });
                                clearCounts();
                                return;
                            }
                            setTimeFrame(val!.value);
                            clearCounts();
                        }}
                    />
                </TopBar>

                {timeFrame.name === 'custom' && (
                    <div className="flex justify-end space-x-2 -mt-4 mb-4">
                        <input
                            type="date"
                            className="border hover:border-input_hover text-center p-1 text-xs w-full sm:w-auto"
                            defaultValue={timeFrame.start.toISOString().split("T")[0]}
                            onBlur={(e) => {
                                const date = new Date(e.target.value).getTime();

                                if (isNaN(date)) {
                                    return;
                                }
                                setTimeFrame({ start: new Date(date), end: timeFrame.end, name: 'custom' });
                            }}
                        />
                        <input
                            type="date"
                            className="border hover:border-input_hover text-center p-1 text-xs w-full sm:w-auto"
                            defaultValue={timeFrame.end.toISOString().split("T")[0]}
                            onBlur={(e) => {
                                const date = new Date(e.target.value).getTime();

                                if (isNaN(date)) {
                                    return;
                                }
                                setTimeFrame({ start: timeFrame.start, end: new Date(date), name: 'custom' });
                            }}
                        />
                    </div>
                )}
                <TableTabBar
                    tabOptions={{
                        tabs: [
                            { name: LeadTab.ALL, quantity: allCount === undefined ? <ReactLoading height={15} width={15} type='spin' /> : allCount },
                            { name: LeadTab.UNOPENED, quantity: unopendCount === undefined ? <ReactLoading height={15} width={15} type='spin' /> : unopendCount },
                            { name: LeadTab.OPENED, quantity: viewedCount === undefined ? <ReactLoading height={15} width={15} type='spin' /> : viewedCount },
                            { name: LeadTab.CLOSED, quantity: closedCount === undefined ? <ReactLoading height={15} width={15} type='spin' /> : closedCount },
                            { name: LeadTab.LOST, quantity: lostCount === undefined ? <ReactLoading height={15} width={15} type='spin' /> : lostCount },
                        ],
                        active: selectedTab,
                        onClick: setSelectedTab,
                    }}
                />

                <div
                    id="table-scroll-div"
                    className={`h-full w-full max-w-full overflow-auto relative`}
                >
                    <InfiniteScroll
                        dataLength={displayLeads?.length ?? 0} //This is important field to render the next data
                        next={() => loadLeads(200)}
                        hasMore={!!cursor.current}
                        loader={
                            <div>
                                <p style={{ textAlign: "center" }}>
                                    {loadingLeads
                                        ? <b>Loading more leads...</b>
                                        : <Button
                                            className="mx-auto my-8"
                                            type="button"
                                            color="blue"
                                            style="outline"
                                            rounded
                                            onClick={() => loadLeads(200)}
                                        >
                                            Load More
                                        </Button>
                                    }

                                </p>
                                <p style={{ textAlign: "center" }}>
                                    <Button
                                        className="mx-auto my-8"
                                        type="button"
                                        color="blue"
                                        style="outline"
                                        rounded
                                        onClick={() => loadAll()}
                                    >
                                        Load All
                                    </Button>
                                </p>
                            </div>
                        }
                        endMessage={
                            <p style={{ textAlign: "center" }}>
                                <b>No more leads</b>
                            </p>
                        }
                        scrollThreshold={"80px"}
                        scrollableTarget="table-scroll-div"
                    >
                        <table className="text-left w-full relative border-collapse flex-grow">

                            <TableHeader
                                data={getTableHeaders()}
                            />

                            <TableRowWrapper>
                                {displayLeads?.length ? (
                                    displayLeads.map(
                                        (lead, i) =>
                                            searchFilter(lead) && (
                                                <TableRow
                                                    key={i}
                                                    data={getTableRows(lead)}
                                                />
                                            )
                                    )
                                ) : (
                                    <></>
                                )}
                            </TableRowWrapper>

                        </table>
                    </InfiniteScroll>

                </div>

                {displayLeads ? (
                    displayLeads.length ? null : (
                        <div className="text-gray-500 inset-center top-72">No leads</div>
                    )
                ) : (
                    <Loading center />
                )}
            </div>

            {exporting && <Loading fullscreen />}
        </div>
    );
}
