import { ICompany, int } from '@common.abstractions'
import { Page } from '@frontend/components/misc/layout'
import AuthContext from '@frontend/contexts/AuthContext'
import { RetrieveCompany } from '@frontend/services/companies'
import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import { calcPercentage, formatMoney, GetDate } from '@common.tools'
import { RetrieveCompanyLeadCloseRates, RetrieveCompanyMOQ, RetrieveCompanyTotalSpent } from '@frontend/services/analytics'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'

export default function DealerDashboard() {
    const authContext = useContext(AuthContext)
    const companyId = int.parse(authContext?.user?.company?.id);
    const [company, setCompany] = useState<ICompany>();
    const [timeFrame, setTimeFrame] = useState<{
        start: Date;
        end: Date;
        name: string
    }>({ start: GetDate.currentMonth(), end: GetDate.tomorrow(), name: 'monthToDate' });
    const [companyTotalSales, setCompanyTotalSales] = useState<{ current: number, previous: number }>();
    const [totalSpentTotals, setTotalSpentTotals] = useState<{ totalSpent: number, totalPercentTrend: number, trendGrowth: boolean }>();
    const [moq, setMoq] = useState<{ moq: number, moqPurchased: number }>();
    const [moqTotals, setMOQTotals] = useState<{ moq: number, moqPurchased: number, goalPercentage: number }>();
    const [leadCloseRates, setLeadCloseRates] = useState<{ current: { assignedCount: number, closedCount: number }, previous: { assignedCount: number, closedCount: number } }>()
    const [leadCloseRatesTotals, setLeadsCloseRatesTotals] = useState<{
        totalCloseRate: number,
        totalPercentTrend: number,
        trendGrowth: boolean,
        totalLeadsSent: number,
        totalLeadsClosed: number
    }>()

    const retrieveCompany = async () => {
        const response = await RetrieveCompany(companyId);
        if (!response) {
            console.log('Could not load company');
            return;
        }
        setCompany(response);
    }

    const handleTimeChange = (value: any) => {
        setTimeFrame({ start: value.start, end: value.end, name: value.name })
    }

    const retrieveTotalSales = async () => {
        const totalSales = await RetrieveCompanyTotalSpent(companyId, timeFrame);
        if (!totalSales) {
            console.log('Could not load company sales');
            return;
        }
        const totalSalesCurrent = Number(totalSales.current.toFixed(2));
        const totalSalesPrevious = Number(totalSales.previous.toFixed(2));
        const totalPercentTrend = Math.abs(calcPercentage(Number((totalSalesCurrent - totalSalesPrevious).toFixed(2)), totalSalesCurrent));
        const trendGrowth = totalSalesCurrent - totalSalesPrevious > 0 ? true : false;

        const tempTotalSales = {
            totalSpent: totalSalesCurrent,
            totalPercentTrend: totalPercentTrend,
            trendGrowth: trendGrowth
        }

        setCompanyTotalSales(totalSales);
        setTotalSpentTotals(tempTotalSales);
    }

    const retrieveMOQ = async () => {
        const moqData = await RetrieveCompanyMOQ(companyId);
        if (!moqData) {
            console.log('Could not load moq data')
            return;
        }

        const goalPercentage = calcPercentage(moqData.moqPurchased, moqData.moq);

        const tempTotalMOQs = {
            moq: moqData.moq,
            moqPurchased: moqData.moqPurchased,
            goalPercentage: goalPercentage
        }

        setMoq(moqData);
        setMOQTotals(tempTotalMOQs);
    }

    const retrieveLeadCloseRate = async () => {
        const leadsData = await RetrieveCompanyLeadCloseRates(companyId, timeFrame);
        if (!leadsData) {
            console.log('Could not load leads data');
            return;
        }

        const tempTotalCloseRateCurrent = calcPercentage(leadsData.current.closedCount, leadsData.current.assignedCount);
        const tempTotalCloseRatePrevious = calcPercentage(leadsData.previous.closedCount, leadsData.previous.assignedCount);
        const tempTotalLeadsSent = leadsData.current.assignedCount;
        const tempTotalLeadsClosed = leadsData.current.closedCount;

        const tempTotalPercentTrend = Math.abs(Number((tempTotalCloseRateCurrent - tempTotalCloseRatePrevious).toFixed(2)));
        const tempTrendGrowth = tempTotalCloseRateCurrent - tempTotalCloseRatePrevious > 0 ? true : false;

        const tempLeadCloseRatesTotals = {
            totalCloseRate: tempTotalCloseRateCurrent,
            totalPercentTrend: tempTotalPercentTrend,
            trendGrowth: tempTrendGrowth,
            totalLeadsSent: tempTotalLeadsSent,
            totalLeadsClosed: tempTotalLeadsClosed
        }

        setLeadsCloseRatesTotals(tempLeadCloseRatesTotals);
        setLeadCloseRates(leadsData);
    }

    useEffect(() => {
        retrieveCompany();
        retrieveTotalSales();
        retrieveLeadCloseRate();
        retrieveMOQ();
    }, [timeFrame]);

    return (
        <Page>
            <div className='flex justify-between mb-5 p-1 flex-wrap min-w-full'>
                <h1 className="text-2xl text-gray-500 uppercase">{company?.name ? `${company.name} Dashboard` : 'Dashboard'}</h1>
                <Select
                    className='quotes_date_dropdown'
                    defaultValue={{
                        value: {
                            start: GetDate.currentMonth(),
                            end: GetDate.tomorrow(),
                            name: 'monthToDate'
                        },
                        label: 'Month to Date'
                    }}
                    options={[
                        {
                            value: {
                                start: GetDate.currentMonth(),
                                end: GetDate.tomorrow(),
                                name: 'monthToDate'
                            },
                            label: 'Month to Date'
                        },
                        {
                            value: {
                                start: new Date(),
                                end: new Date(),
                                name: 'custom'
                            },
                            label: 'Custom Range'
                        },
                        {
                            value: {
                                start: GetDate.nDaysAgo(30),
                                end: GetDate.tomorrow(),
                                name: 'last30Days'
                            },
                            label: 'Last 30 Days'
                        },
                        {
                            value: {
                                start: GetDate.january(),
                                end: GetDate.tomorrow(),
                                name: 'yearToDate'
                            },
                            label: 'Year to Date'
                        },
                        {
                            value: {
                                start: GetDate.everlightsBirth(),
                                end: GetDate.tomorrow(),
                                name: "allTime",
                            },
                            label: "All Time",
                        },
                        ...GetDate.years().map(el => (
                                {
                                    value: {
                                        start: el.start,
                                        end: el.end,
                                        name: `${el.start.getFullYear()}`,
                                    },
                                    label:  `${el.start.getFullYear()}`
                                }
                            ))
                        ,
                    ]}
                    onChange={(val) => {
                        if (val?.value.name === 'custom') {
                            setTimeFrame({ start: timeFrame.start, end: timeFrame.end, name: 'custom' })
                            return;
                        }
                        handleTimeChange(val?.value);
                    }}
                />
            </div>

            {timeFrame.name === 'custom' &&
                <div className="flex justify-end space-x-4">
                    <input
                        type="date"
                        className="flex border hover:border-input_hover text-center"
                        defaultValue={timeFrame.start.toISOString().split("T")[0]}
                        onBlur={(e) => {
                            const date = new Date(e.target.value).getTime();

                            if (isNaN(date)) return;

                            setTimeFrame({ start: new Date(date), end: timeFrame.end, name: 'custom' })
                        }}
                    />
                    <input
                        type="date"
                        className="flex border hover:border-input_hover text-center"
                        defaultValue={timeFrame.end.toISOString().split("T")[0]}
                        onBlur={(e) => {
                            const date = new Date(e.target.value).getTime();

                            if (isNaN(date)) return;

                            setTimeFrame({ start: timeFrame.start, end: new Date(date), name: 'custom' })
                        }}
                    />
                </div>
            }

            <div className="grid mb-5 gap-6 p-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-h-full overflow-y-auto">
                {/* Total Sales */}
                <div className='p-5 shadow-md hover:shadow-lg duration-theme h-60 opacity-70 hover:opacity-100'>
                    <div className="text-gray-500 mb-3 text-lg">Total Sales</div>
                    <div className="flex items-end mb-4">
                        <div className="text-3xl text-gray-700 font-bold">{totalSpentTotals?.totalSpent && formatMoney(totalSpentTotals?.totalSpent)}</div>
                        <div className="flex items-center ml-2 ">
                            {totalSpentTotals?.trendGrowth ? (
                                <>
                                    <FontAwesomeIcon className="text-xs text-theme_green" icon={faArrowUp} />
                                    <span className="ml-1 text-theme_green">{totalSpentTotals?.totalPercentTrend}%</span>
                                </>
                            ) : (
                                <>
                                    <FontAwesomeIcon className="text-xs text-theme_red" icon={faArrowDown} />
                                    <span className="ml-1 text-theme_red">{totalSpentTotals?.totalPercentTrend}%</span>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {/* Lead Close Rate */}
                <div className='p-5 shadow-md hover:shadow-lg duration-theme h-60 opacity-70 hover:opacity-100'>
                    <div className="text-gray-500 mb-3 text-lg">Lead Close Rate</div>
                    <div className="flex items-end mb-4">
                        <div className="text-3xl text-gray-700 font-bold flex">{leadCloseRatesTotals?.totalCloseRate}%</div>
                        {leadCloseRatesTotals?.trendGrowth ? (
                            <div className="flex items-center ml-2 mr-5  text-theme_green">
                                <FontAwesomeIcon className="text-xs" icon={faArrowUp} />
                                <span className="ml-1">{leadCloseRatesTotals?.totalPercentTrend}%</span>
                            </div>
                        ) : (
                            <div className="flex items-center ml-2 mr-5  text-theme_red">
                                <FontAwesomeIcon className="text-xs" icon={faArrowDown} />
                                <span className="ml-1">{leadCloseRatesTotals?.totalPercentTrend}%</span>
                            </div>
                        )}
                        <div className="text-xl text-gray-700 font-bold flex">
                            <div className="relative" style={{ minWidth: "25px" }}>
                                {leadCloseRatesTotals?.totalLeadsClosed}
                                <div className="absolute bottom-full text-xs whitespace-nowrap">closed</div>
                            </div>
                            <div className="mx-2">/</div>
                            <div className="relative">
                                {leadCloseRatesTotals?.totalLeadsSent}
                                <div className="absolute bottom-full text-xs whitespace-nowrap">sent</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MOQ */}
                <div className='p-5 shadow-md hover:shadow-lg duration-theme h-60 opacity-70 hover:opacity-100'>
                    <div className="text-gray-500 mb-3 text-xl">MOQ*
                        <span className='text-xs'>Does not update with specified timeframe</span>
                    </div>
                    <div className="flex items-end mb-4">
                        <div className="text-3xl text-gray-700 font-bold">{moqTotals?.goalPercentage}%</div>
                        <div className="text-xl text-gray-700 font-bold flex ml-5">
                            <div className="relative" style={{ minWidth: "30px" }}>
                                {moqTotals?.moqPurchased}
                                <div className="absolute bottom-full text-xs whitespace-nowrap">purchased</div>
                            </div>
                            <div className="mx-3">/</div>
                            <div className="relative">
                                {moqTotals?.moq}
                                <div className="absolute bottom-full text-xs whitespace-nowrap">goal</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    )
}
