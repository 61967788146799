import { useState, useEffect, useContext } from "react";
import { Between, Exists, Not } from "@common.api";
import { DealerOrdersCount, DeleteOrder, RetrieveDealerOrders, RetrieveOrders, RetrieveOrdersCount } from "@frontend/services/orders";
import Select from "react-select";
import ReactSwitch from "react-switch";
import "./_orders.scss";
import { Loading } from "../../../components/misc/loading";
import { float, Scope, int, IOrder } from "@common.abstractions";
import { calculateDiscount, formatMoney, GetDate, objectsToCSV } from "@common.tools";
import AuthContext from "@frontend/contexts/AuthContext";
import TopBar from "@frontend/components/misc/top-bar/TopBar";
import SearchBar from "@frontend/components/misc/search-bar/SearchBar";
import { Table, TableHeader, TableRowWrapper, TableRow } from "@frontend/components/misc/table";
import Button from "@frontend/components/misc/button/Button";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom-v5-compat";
import { Modal } from "@frontend/components/misc/modal";

enum OrderTab {
    ALL = "All",
    PROCESSED = "Processed",
    PAID = "Paid",
    OVERDUE = "Payment Overdue",
    FAILED = "Payment Failed",
}

export default function ReviewAllOrders() {
    const authContext = useContext(AuthContext);
    const [searchCriteria, setSearchCriteria] = useState<string>("");

    const [displayedOrders, setDisplayedOrders] = useState<IOrder[]>();
    const [orders, setOrders] = useState<IOrder[]>();
    const [processedOrders, setProcessedOrders] = useState<IOrder[]>();
    const [paidOrders, setPaidOrders] = useState<IOrder[]>();
    const [overdueOrders, setOverdueOrders] = useState<IOrder[]>();
    const [failedOrders, setFailedOrders] = useState<IOrder[]>();
    const [filterUnsubmitted, setFilterUnsubmitted] = useState<boolean>(window.localStorage.getItem("filterUnsubmitted") === "false" ? false : true);
    const [dealerOrderView, setDealerOrderView] = useState<boolean>();
    const [selectedTab, setSelectedTab] = useState(OrderTab.ALL);
    const [orderModal, setOrderModal] = useState<boolean>(false);
    const [orderHasChanged, setOrderHasChanged] = useState<boolean>(false);
    const [selectedOrder, setSelectedOrder] = useState<IOrder>();

    const [allCount, setAllCount] = useState<number>();
    const [processedCount, setProcessedCount] = useState<number>();
    const [paidCount, setPaidCount] = useState<number>();
    const [overdueCount, setOverdueCount] = useState<number>();
    const [failedCount, setFailedCount] = useState<number>();
    const navigate = useNavigate();

    const [timeFrame, setTimeFrame] = useState<{
        start: Date;
        end: Date;
        name: string;
    }>({ start: GetDate.nDaysAgo(30), end: GetDate.tomorrow(), name: "last30Days" });

    const loadOrders = async () => {
        const response = await RetrieveOrders({
            filters: {
                submittedDate: filterUnsubmitted ? Exists() : undefined,
                updatedAt: Between(timeFrame.start, timeFrame.end)
            },
            relations: ['discountPackage', 'lead', 'lead.company', 'lead.quoteRequest', 'lead.quoteRequest.contact', 'lead.company.owner', 'items', 'items.productVariant', 'items.productVariant.product']
        })

        if (!response) {
            console.log('Could not retrieve orders')
            setOrders([]);
            return;
        }

        response.sort((a, b) => {
            let varA = 0;
            let varB = 0;
            if (a.submittedDate) {
                varA = new Date(a.submittedDate).getTime();
            } else {
                varA = new Date(a.createdAt).getTime();
            }
            if (b.submittedDate) {
                varB = new Date(b.submittedDate).getTime();
            } else {
                varB = new Date(b.createdAt).getTime();
            }
            return varB - varA;
        });

        setOrders(response);

        const processed: IOrder[] = [];
        const paid: IOrder[] = [];
        const overdue: IOrder[] = [];
        const failed: IOrder[] = [];

        response.forEach((order) => {
            if (order.paymentOverdueDate && !order.paymentSuccessfulDate) {
                overdue.push(order);
            }
            if (order.paymentFailedDate && !order.paymentSuccessfulDate) {
                failed.push(order);
            }
            if (order.paymentSuccessfulDate && !order.paymentFailedDate) {
                paid.push(order);
            }
            if (order.invoiceProcessedDate) {
                processed.push(order);
            }
        })

        setProcessedOrders(processed);
        setPaidOrders(paid);
        setOverdueOrders(overdue);
        setFailedOrders(failed);
    };

    const ordersMOQ = (order: IOrder) => {
        let totalMoq = 0
        order.items?.forEach((item) => {
            if (!item.productVariant?.product?.moqValue) return;

            totalMoq += int(item.quantity * item.productVariant.product.moqValue);
        })
        return totalMoq;
    }

    const loadDealerOrders = async () => {
        const dealOrders = await RetrieveDealerOrders({
            relations: ['discountPackage', 'lead', 'lead.company', 'lead.quoteRequest', 'lead.quoteRequest.contact', 'lead.company.owner'],
            filters: {
                submittedDate: filterUnsubmitted ? Exists() : undefined,
                updatedAt: Between(timeFrame.start, timeFrame.end)
            }
        })

        if (!dealOrders) {
            console.log('Could not retrieve dealer orders')
            setOrders([]);
            return;
        }

        dealOrders.sort((a, b) => {
            let varA = 0;
            let varB = 0;
            if (a.submittedDate) {
                varA = new Date(a.submittedDate).getTime();
            } else {
                varA = new Date(a.createdAt).getTime();
            }
            if (b.submittedDate) {
                varB = new Date(b.submittedDate).getTime();
            } else {
                varB = new Date(b.createdAt).getTime();
            }
            return varB - varA;
        });

        setOrders(dealOrders);

        const processed: IOrder[] = [];
        const paid: IOrder[] = [];
        const overdue: IOrder[] = [];
        const failed: IOrder[] = [];

        dealOrders.forEach((order) => {
            if (order.paymentOverdueDate && !order.paymentSuccessfulDate) {
                overdue.push(order);
            }
            if (order.paymentFailedDate) {
                failed.push(order);
            }
            if (order.paymentSuccessfulDate) {
                paid.push(order);
            }
            if (order.invoiceProcessedDate) {
                processed.push(order);
            }
        })

        setProcessedOrders(processed);
        setPaidOrders(paid);
        setOverdueOrders(overdue);
        setFailedOrders(failed);
    }

    const getDealerOrdersCount = async () => {
        const all = await DealerOrdersCount({
            updatedAt: Between(timeFrame.start, timeFrame.end)
        })
        const processed = await DealerOrdersCount({
            updatedAt: Between(timeFrame.start, timeFrame.end),
            invoiceProcessedDate: Exists()
        })
        const paid = await DealerOrdersCount({
            updatedAt: Between(timeFrame.start, timeFrame.end),
            paymentSuccessfulDate: Exists()
        })
        const overdue = await DealerOrdersCount({
            updatedAt: Between(timeFrame.start, timeFrame.end),
            paymentSuccessfulDate: Not(Exists()),
            paymentOverdueDate: Exists(),
        })
        const failed = await DealerOrdersCount({
            updatedAt: Between(timeFrame.start, timeFrame.end),
            paymentFailedDate: Exists()
        })

        setAllCount(all);
        setProcessedCount(processed);
        setPaidCount(paid);
        setOverdueCount(overdue);
        setFailedCount(failed);
    }

    const getCounts = async () => {
        const all = await RetrieveOrdersCount({
            updatedAt: Between(timeFrame.start, timeFrame.end)
        })
        const processed = await RetrieveOrdersCount({
            updatedAt: Between(timeFrame.start, timeFrame.end),
            invoiceProcessedDate: Exists()
        })
        const paid = await RetrieveOrdersCount({
            updatedAt: Between(timeFrame.start, timeFrame.end),
            paymentSuccessfulDate: Exists()
        })
        const overdue = await RetrieveOrdersCount({
            updatedAt: Between(timeFrame.start, timeFrame.end),
            paymentSuccessfulDate: Not(Exists()),
            paymentOverdueDate: Exists(),
        })
        const failed = await RetrieveOrdersCount({
            updatedAt: Between(timeFrame.start, timeFrame.end),
            paymentFailedDate: Exists(),
            paymentSuccessfulDate: Not(Exists())
        })

        setAllCount(all);
        setProcessedCount(processed);
        setPaidCount(paid);
        setOverdueCount(overdue);
        setFailedCount(failed);
    }

    const clearCounts = () => {
        setAllCount(undefined);
        setProcessedCount(undefined);
        setPaidCount(undefined);
        setOverdueCount(undefined);
        setFailedCount(undefined);
    }

    const setTabs = async () => {
        switch (selectedTab) {
            case OrderTab.ALL:
                setDisplayedOrders(orders);
                break;
            case OrderTab.PROCESSED:
                setDisplayedOrders(processedOrders);;
                break
            case OrderTab.PAID:
                setDisplayedOrders(paidOrders);;
                break
            case OrderTab.OVERDUE:
                setDisplayedOrders(overdueOrders);;
                break
            case OrderTab.FAILED:
                setDisplayedOrders(failedOrders);;
                break
            default:
                setDisplayedOrders(orders);
                break;
        }
    }

    const searchFilter = (entity: IOrder) => {
        const s = searchCriteria.toLowerCase();
        if (
            searchCriteria === "" ||
            entity.lead.company?.users?.find((el) => (el.firstname.toLowerCase() + " " + el.lastname.toLowerCase()).includes(s)) ||
            entity.lead.company?.name.toLowerCase().includes(s) ||
            entity.lead.quoteRequest?.contact?.firstname.toLowerCase().includes(s) ||
            entity.lead.quoteRequest?.contact?.lastname.toLowerCase().includes(s) ||
            (entity.lead.quoteRequest?.contact?.firstname.toLowerCase() + " " + entity.lead.quoteRequest?.contact?.lastname.toLowerCase()).includes(s)
        ) {
            return true;
        }
    };

    const setOrderStatus = (order: IOrder) => {
        if (order.paymentSuccessfulDate) {
            return "Paid";
        } else if (order.paymentFailedDate) {
            return "Payment Failed";
        } else if (order.invoiceProcessedDate && order.paymentMethod?.paymentMethodCode === "card") {
            return "Unpaid";
        } else if (order.invoiceProcessedDate && order.paymentMethod?.paymentMethodCode === "ach_debit") {
            return "Payment Pending";
        } else if (order.submittedDate) {
            return "Contract Pending";
        }
        else if (order.submittedDate && order.lead.customerContract?.signedDate) {
            return "Payment Processing"
        }
        else {
            return "Not Submitted";
        }
    };

    const handleUnsubmittedFilterToggle = (checked: boolean) => {
        setFilterUnsubmitted(checked);

        if (checked) {
            window.localStorage.setItem("filterUnsubmitted", "true");
        } else {
            window.localStorage.setItem("filterUnsubmitted", "false");
        }
    };

    const openOrderModal = (orderId: int) => {
        if (!authContext?.hasAnyGrant(Scope.Order.DELETE)) return;

        const selectedOrder = orders?.find((order) => order.id === orderId);
        if (selectedOrder) {
            setSelectedOrder(selectedOrder);
        }
        setOrderModal(true);
    }

    const closeOrderModal = () => {
        setOrderModal(false)
        setSelectedOrder(undefined)
    }

    const deleteOrder = async () => {
        if (!selectedOrder) return;
        const confirm = window.confirm("Are you sure you want to delete this order?");
        if (confirm) {
            const response = await DeleteOrder(selectedOrder.id);
            if (!response) {
                toast.error('Could not delete order');
                return;
            }
            orderHasChanged ? setOrderHasChanged(false) : setOrderHasChanged(true);
            closeOrderModal();
            toast.success('Order deleted successfully');
        }
    }

    const handleDealerExport = async () => {
        const orders = await RetrieveDealerOrders({
            filters: {
                updatedAt: Between(timeFrame.start, timeFrame.end),
                submittedDate: Exists()
            },
            relations: ['discountPackage', 'lead', 'lead.company', 'lead.quoteRequest', 'lead.quoteRequest.contact', 'lead.company.owner', 'items', 'items.productVariant', 'items.productVariant.product']
        });

        if (!orders) {
            toast.error('Cannot export CSV');
            return;
        }

        orders.sort((a, b) => {
            let varA = 0;
            let varB = 0;
            if (a.submittedDate) {
                varA = new Date(a.submittedDate).getTime();
            } else {
                varA = new Date(a.createdAt).getTime();
            }
            if (b.submittedDate) {
                varB = new Date(b.submittedDate).getTime();
            } else {
                varB = new Date(b.createdAt).getTime();
            }
            return varB - varA;
        });

        console.log('Dealer Export');

        const csv = objectsToCSV(orders, {
            'Order Id': i => (i.id).toString(),
            'Submitted Date': i => i.submittedDate ? new Date(i.submittedDate).toLocaleDateString() : 'Not submitted',
            'Assigned Dealer': i => i.lead.company?.name ? i.lead.company.name : '',
            'Dealer Manager': (i) => {
                if (!i.lead.company?.owner) return ''
                return `${i.lead.company.owner.firstname} ${i.lead.company.owner.lastname}`
            },
            'First Name': i => i.lead.quoteRequest?.contact?.firstname ? i.lead.quoteRequest.contact.firstname : '',
            'Last Name': i => i.lead.quoteRequest?.contact?.lastname ? i.lead.quoteRequest?.contact?.lastname : '',
            'Status': i => setOrderStatus(i),
            'Processed': i => i.invoiceProcessedDate ? new Date(i.invoiceProcessedDate).toLocaleDateString() : 'Not processed',
            'Payment Date': i => i.paymentSuccessfulDate ? new Date(i.paymentSuccessfulDate).toLocaleDateString() : 'Not paid',
            'Value': i => i.vTotal ? formatMoney(i.vTotal) : formatMoney(0),
            'Subtotal': i => i.vSubtotal ? formatMoney(calculateDiscount(i.vSubtotal, i.discountPackage?.discountPercent ? i.discountPackage.discountPercent : float(0))) : formatMoney(0),
            'Shipped': i => i.shippedDate ? new Date(i.shippedDate).toLocaleDateString() : 'Not shipped',
            'Order MOQ': i => (ordersMOQ(i)).toString()
        })

        const encodedCSV = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        const downloadLink = document.createElement('a');
        downloadLink.download = `Orders_${new Date().toLocaleDateString()}.csv`;
        downloadLink.href = encodedCSV;
        downloadLink.click();
    }

    const handleExport = async () => {
        let orders = await RetrieveOrders({
            filters: {
                updatedAt: Between(timeFrame.start, timeFrame.end),
                submittedDate: Exists()
            },
            relations: ['discountPackage', 'lead', 'lead.company', 'lead.quoteRequest', 'lead.quoteRequest.contact', 'lead.company.owner', 'items', 'items.productVariant', 'items.productVariant.product']
        });

        if (!orders) {
            toast.error('Cannot export');
            return;
        }

        orders.sort((a, b) => {
            let varA = 0;
            let varB = 0;
            if (a.submittedDate) {
                varA = new Date(a.submittedDate).getTime();
            } else {
                varA = new Date(a.createdAt).getTime();
            }
            if (b.submittedDate) {
                varB = new Date(b.submittedDate).getTime();
            } else {
                varB = new Date(b.createdAt).getTime();
            }
            return varB - varA;
        });

        const csv = objectsToCSV(orders, {
            'Submitted Date': i => i.submittedDate ? new Date(i.submittedDate).toLocaleDateString() : 'Not submitted',
            'Assigned Dealer': i => i.lead.company?.name ? i.lead.company.name : 'undefined',
            'Dealer Manager': (i) => {
                if (!i.lead.company?.owner) return ''
                return `${i.lead.company.owner.firstname} ${i.lead.company.owner.lastname}`
            },
            'First Name': i => i.lead.quoteRequest?.contact?.firstname ? i.lead.quoteRequest.contact.firstname : 'undefined',
            'Last Name': i => i.lead.quoteRequest?.contact?.lastname ? i.lead.quoteRequest?.contact?.lastname : 'undefined',
            'Status': i => setOrderStatus(i),
            'Processed': i => i.invoiceProcessedDate ? new Date(i.invoiceProcessedDate).toLocaleDateString() : 'Not processed',
            'Payment Date': i => i.paymentSuccessfulDate ? new Date(i.paymentSuccessfulDate).toLocaleDateString() : 'Not paid',
            'Value': i => i.vTotal ? formatMoney(i.vTotal) : formatMoney(0),
            'Subtotal': i => i.vSubtotal ? formatMoney(calculateDiscount(i.vSubtotal, i.discountPackage?.discountPercent ? i.discountPackage.discountPercent : float(0))) : formatMoney(0),
            'Shipped': i => i.shippedDate ? new Date(i.shippedDate).toLocaleDateString() : 'Not shipped',
            'Order MOQ': i => (ordersMOQ(i)).toString()
        })

        const encodedCSV = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        const downloadLink = document.createElement('a');
        downloadLink.download = `Orders_${new Date().toLocaleDateString()}.csv`;
        downloadLink.href = encodedCSV;
        downloadLink.click();
    }

    const csvOnClick = () => {
        if (authContext?.hasAnyGrant(Scope.Order.READ_ANY)) {
            if (dealerOrderView) {
                handleDealerExport();
            }
            else {
                handleExport()
            }
        }
        else if (authContext?.hasAnyGrant(Scope.Order.DEALER_READ)) {
            handleDealerExport()
        }
        else {
            handleExport()
        }
    }

    useEffect(() => {
        setTabs()
    }, [selectedTab, orders, processedOrders, paidOrders, overdueOrders, failedOrders]);

    useEffect(() => {
        if (authContext?.hasAnyGrant(Scope.Order.READ_ANY)) {
            if (dealerOrderView) {
                loadDealerOrders();
                getDealerOrdersCount();
            }
            else {
                loadOrders();
                getCounts();
            }
        }
        else if (authContext?.hasAnyGrant(Scope.Order.DEALER_READ)) {
            loadDealerOrders();
            getDealerOrdersCount();
        }
        else {
            loadOrders();
            getCounts();
        }
    }, [timeFrame, filterUnsubmitted, orderHasChanged])

    return (
        <div className="w-full h-full max-w-full">
            <div className="flex flex-col h-full w-full overflow-hidden">
                <TopBar title="Orders">
                    <div className="flex flex-col items-center sm:items-start mr-6 mb-2">
                        <div className="text-gray-500 text-sm whitespace-nowrap">Filter Unsubmitted</div>
                        <ReactSwitch
                            offColor={"#aaa"}
                            onColor={"#3F87ED"}
                            boxShadow={"2px 2px 2px rgba(30,30,30,.2)"}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            width={40}
                            height={20}
                            onChange={(checked) => handleUnsubmittedFilterToggle(checked)}
                            checked={filterUnsubmitted}
                        />
                    </div>
                    {authContext?.hasAnyGrant(Scope.Order.CREATE_ANY) &&
                        <Button
                            className="w-full sm:w-auto mb-2 sm:mb-0 sm:mr-4"
                            rounded
                            style="outline"
                            color="blue"
                            type="button"
                            onClick={() => navigate('/shippingLabel')}>Create Shipping Label
                        </Button>}
                    <Button
                        className="w-full sm:w-auto mb-2 sm:mb-0 sm:mr-4"
                        rounded
                        style="outline"
                        color="green"
                        type="button"
                        onClick={csvOnClick}>Export CSV
                    </Button>

                    <SearchBar className="w-full sm:w-96 mb-2 sm:mb-0 sm:mr-4" placeholder="Search Orders" search={setSearchCriteria} />

                    <div className="flex flex-row w-full space-x-4">
                        <Select
                            className="w-full sm:w-auto quotes_date_dropdown"
                            defaultValue={{
                                value: {
                                    start: GetDate.nDaysAgo(30),
                                    end: GetDate.tomorrow(),
                                    name: "last30Days",
                                },
                                label: "Last 30 Days",
                            }}
                            options={[
                                {
                                    value: {
                                        start: GetDate.january(),
                                        end: GetDate.tomorrow(),
                                        name: "yearToDate",
                                    },
                                    label: "Year to Date",
                                },
                                {
                                    value: {
                                        start: GetDate.nDaysAgo(30),
                                        end: GetDate.tomorrow(),
                                        name: "last30Days",
                                    },
                                    label: "Last 30 Days",
                                },
                                {
                                    value: {
                                        start: new Date(),
                                        end: new Date(),
                                        name: 'custom'
                                    },
                                    label: 'Custom Range'
                                },
                                {
                                    value: {
                                        start: GetDate.currentMonth(),
                                        end: GetDate.tomorrow(),
                                        name: "monthToDate",
                                    },
                                    label: "Month to Date",
                                },
                                ...GetDate.years().map(el => (
                                    {
                                        value: {
                                            start: el.start,
                                            end: el.end,
                                            name: `${el.start.getFullYear()}`,
                                        },
                                        label:  `${el.start.getFullYear()}`
                                    }
                                )),
                                {
                                    value: {
                                        start: GetDate.everlightsBirth(),
                                        end: GetDate.tomorrow(),
                                        name: "allTime",
                                    },
                                    label: "All Time",
                                },
                                {
                                    value: {
                                        start: GetDate.january(),
                                        end: GetDate.february(),
                                        name: "january",
                                    },
                                    label: "January",
                                },
                                {
                                    value: {
                                        start: GetDate.february(),
                                        end: GetDate.march(),
                                        name: "february",
                                    },
                                    label: "February",
                                },
                                {
                                    value: {
                                        start: GetDate.march(),
                                        end: GetDate.april(),
                                        name: "march",
                                    },
                                    label: "March",
                                },
                                {
                                    value: {
                                        start: GetDate.april(),
                                        end: GetDate.may(),
                                        name: "april",
                                    },
                                    label: "April",
                                },
                                {
                                    value: {
                                        start: GetDate.may(),
                                        end: GetDate.june(),
                                        name: "may",
                                    },
                                    label: "May",
                                },
                                {
                                    value: {
                                        start: GetDate.june(),
                                        end: GetDate.july(),
                                        name: "june",
                                    },
                                    label: "June",
                                },
                                {
                                    value: {
                                        start: GetDate.july(),
                                        end: GetDate.august(),
                                        name: "july",
                                    },
                                    label: "July",
                                },
                                {
                                    value: {
                                        start: GetDate.august(),
                                        end: GetDate.september(),
                                        name: "august",
                                    },
                                    label: "August",
                                },
                                {
                                    value: {
                                        start: GetDate.september(),
                                        end: GetDate.october(),
                                        name: "september",
                                    },
                                    label: "September",
                                },
                                {
                                    value: {
                                        start: GetDate.october(),
                                        end: GetDate.november(),
                                        name: "october",
                                    },
                                    label: "October",
                                },
                                {
                                    value: {
                                        start: GetDate.november(),
                                        end: GetDate.december(),
                                        name: "november",
                                    },
                                    label: "November",
                                },
                                {
                                    value: {
                                        start: GetDate.december(),
                                        end: GetDate.nextJanuary(),
                                        name: "december",
                                    },
                                    label: "December",
                                },
                            ]}
                            onChange={(val) => {
                                if (val?.value.name === 'custom') {
                                    setTimeFrame({ start: timeFrame.start, end: timeFrame.end, name: 'custom' });
                                    clearCounts();
                                    return;
                                }
                                setTimeFrame(val!.value);
                                clearCounts();
                            }}
                        />

                        {authContext?.hasAnyGrant(Scope.Order.READ_ANY) ? (
                            <Select
                                className="w-full sm:w-auto quotes_date_dropdown"
                                options={[
                                    {
                                        label: 'All Orders',
                                        name: 'allOrders'
                                    },
                                    {
                                        label: 'My Orders',
                                        name: 'myOrders'
                                    }
                                ]}
                                defaultValue={{
                                    label: 'All Orders',
                                    name: 'All Orders'
                                }}
                                onChange={(val) => {
                                    if (val?.name === 'myOrders') {
                                        setDealerOrderView(true);
                                        loadDealerOrders();
                                        getDealerOrdersCount();
                                        return;
                                    }
                                    setDealerOrderView(false);
                                    loadOrders();
                                    getCounts();
                                }}
                            />
                        ) : null}
                    </div>
                </TopBar>

                {timeFrame.name === 'custom' && (
                    <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4">
                        <input
                            type="date"
                            className="border hover:border-input_hover text-center p-1 text-xs w-full sm:w-auto"
                            defaultValue={timeFrame.start.toISOString().split("T")[0]}
                            onBlur={(e) => {
                                const date = new Date(e.target.value).getTime();

                                if (isNaN(date)) {
                                    return;
                                }
                                setTimeFrame({ start: new Date(date), end: timeFrame.end, name: 'custom' });
                            }}
                        />
                        <input
                            type="date"
                            className="border hover:border-input_hover text-center p-1 text-xs w-full sm:w-auto"
                            defaultValue={timeFrame.end.toISOString().split("T")[0]}
                            onBlur={(e) => {
                                const date = new Date(e.target.value).getTime();

                                if (isNaN(date)) {
                                    return;
                                }
                                setTimeFrame({ start: timeFrame.start, end: new Date(date), name: 'custom' });
                            }}
                        />
                    </div>
                )}

                <Table
                    tabOptions={{
                        tabs: [
                            { name: OrderTab.ALL, quantity: allCount === undefined ? <ReactLoading height={15} width={15} type='spin' /> : allCount },
                            { name: OrderTab.PROCESSED, quantity: processedCount === undefined ? <ReactLoading height={15} width={15} type='spin' /> : processedCount },
                            { name: OrderTab.PAID, quantity: paidCount === undefined ? <ReactLoading height={15} width={15} type='spin' /> : paidCount },
                            { name: OrderTab.OVERDUE, quantity: overdueCount === undefined ? <ReactLoading height={15} width={15} type='spin' /> : overdueCount },
                            { name: OrderTab.FAILED, quantity: failedCount === undefined ? <ReactLoading height={15} width={15} type='spin' /> : failedCount },
                        ],
                        active: selectedTab,
                        onClick: setSelectedTab,
                    }}
                >
                    <TableHeader
                        data={[
                            { title: "Id" },
                            { title: "Submitted" },
                            { title: "Owner" },
                            { title: "Customer" },
                            { title: "Status" },
                            { title: "Processed" },
                            { title: "Payment Date" },
                            { title: "Value" },
                            { title: "" },
                        ]}
                    />
                    <TableRowWrapper>
                        {displayedOrders?.length
                            ? displayedOrders.map(
                                (order) =>
                                    searchFilter(order) && (
                                        <TableRow
                                            key={order.id}
                                            data={[
                                                {
                                                    text: {
                                                        title: order.id,
                                                        onClick: () => openOrderModal(order.id),
                                                    },
                                                },
                                                {
                                                    text: {
                                                        title: order.submittedDate ? new Date(order.submittedDate).toLocaleDateString() : "Not Submitted Yet",
                                                    },
                                                },
                                                { text: { title: order.lead.company ? order.lead.company.name : "No Company" } },
                                                {
                                                    text: {
                                                        title: order.lead.quoteRequest?.contact
                                                            ? `${order.lead.quoteRequest.contact.firstname} ${order.lead.quoteRequest.contact.lastname}`
                                                            : "No Customer",
                                                    },
                                                },
                                                {
                                                    text: {
                                                        title: setOrderStatus(order),
                                                    },
                                                },
                                                {
                                                    text: {
                                                        title: order.invoiceProcessedDate
                                                            ? new Date(order.invoiceProcessedDate).toLocaleDateString()
                                                            : "Not Processed Yet",
                                                    },
                                                },
                                                {
                                                    text: {
                                                        title: order.paymentSuccessfulDate
                                                            ? new Date(order.paymentSuccessfulDate).toLocaleDateString()
                                                            : "Not Paid Yet",
                                                    },
                                                },
                                                {
                                                    text: {
                                                        title: order.vTotal ? formatMoney(order.vTotal) : formatMoney(0),
                                                        subtitle: [
                                                            `Subtotal: ${order.vSubtotal
                                                                ? order.vDiscountAmount
                                                                    ? formatMoney(order.vSubtotal - order.vDiscountAmount)
                                                                    : formatMoney(order.vSubtotal)
                                                                : formatMoney(0)
                                                            }`,
                                                        ],
                                                    },
                                                }, {
                                                    text: {
                                                        title: "Order Details",
                                                        color: "blue",
                                                        to: order.submittedDate ? `/orders/${order.id}` : `/orders/${order.id}/create`, // change from href to to
                                                    },
                                                },

                                            ]}
                                        />
                                    )
                            )
                            : null}
                    </TableRowWrapper>
                </Table>

                {displayedOrders ? displayedOrders.length ? null : <div className="text-gray-500 inset-center top-72">No Orders</div> : <Loading center />}
            </div>

            <Modal visible={orderModal} closeModal={closeOrderModal}>
                <div className="p-4 bg-white rounded-lg shadow-md max-w-md mx-auto">
                    <div className="flex flex-col space-y-4">
                        <h2 className="text-lg font-semibold text-gray-800">Order ID: {selectedOrder?.id}</h2>
                        <h2 className="text-md font-medium text-gray-700">Owner: {selectedOrder?.lead.company?.name}</h2>
                        <h2 className="text-md font-medium text-gray-700">Customer: {selectedOrder?.lead?.quoteRequest?.contact?.firstname} {selectedOrder?.lead?.quoteRequest?.contact?.lastname}</h2>
                        <h2 className="text-md font-medium text-gray-700">Status: {selectedOrder ? setOrderStatus(selectedOrder) : ''}</h2>
                        <div className="flex justify-end">
                            <Button
                                className="w-full py-2 px-4 text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                style="solid"
                                color="red"
                                onClick={deleteOrder}
                            >
                                Delete Order
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>

        </div>
    );
}
