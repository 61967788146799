import { useState, useEffect, useContext, useRef, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { Between, Equals, Exists, LessThan, Not } from "@common.api";
import Select from "react-select";
import { Loading } from "../../components/misc/loading";
import { CompanyType, Scope, ICompany, IInstall, int, IPandaDocLightingProposalTemplate, IUpdatePostInstall, IPostInstall, IUser, PaymentMethod, InstallType, IContractorJob, FinancingCompany, float, ICreateInstallParams, BookingType, ChannelType, TypeOfLights, IBookableUser, financingCompanyToString } from "@common.abstractions";
import { formatMoney, formatPhoneNumber, GetDate, objectsToCSV } from "@common.tools";
import AuthContext from "@frontend/contexts/AuthContext";
import {
    DeleteInstall,
    ManuallyCreateInstall,
    MarkInstallAsPaid,
    RetrieveInstallCount,
    RetrieveInstalls,
    RetrievePandaDocTemplateRelationships,
    SendOutstandingBalanceInvoie,
    SendWarrantyDoc,
    UpdateInstall,
    ManuallyCreateInstallFromContractorJob,
    sendNewSalesNotifcation,
    RetrieveInstall,
    MarkInstallAsJobCompleted,
    UpdateInstallBookableUsers
} from "@frontend/services/installs";
import { toast } from "react-toastify";
import { TableTabBar } from "../misc/table";
import { Modal } from "../misc/modal";
import TopBar from "../misc/top-bar/TopBar";
import SearchBar from "../misc/search-bar/SearchBar";
import Button from "../misc/button/Button";
import { RetrieveCompanies } from "@frontend/services/companies";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactLoading from "react-loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCalendarCheck, faCalendarMinus, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { DeletePostInstall, UpdatePostInstall, RequestSunLightPayment } from "@frontend/services/post-install";
import { RetrieveUsers } from "@frontend/services/users";
import { RetrieveContractorJobs } from "@frontend/services/contractor-jobs";
import { Row } from "react-table";
import React from "react";
import { RetrieveProductVariants } from "@frontend/services/products";
import { faCaretRight, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { moneyStringToCents } from "@common.tools";
import CustomTable from "../misc/table/CustomTable";
import { RetrieveBookableUser, RetrieveBookableUsers } from "@frontend/services/bookable-users";
import InstallSubRow from "./InstallSubRow";

enum InstallTab {
    All = "All",
    NOT_SCHEDULED = "Not Scheduled",
    SCHEDULED = "Scheduled",
    NOT_PAID = "Not Paid Yet",
    PAID = "Paid",
    WARRANTY_NOT_SIGNED = "Not Signed",
    WARRANTY_SIGNED = "Signed",
    PENDING_APPROVAL = "Pending Approval",
}

export default function Installs() {
    const authContext = useContext(AuthContext);
    const history = useHistory();

    const [exporting, setExporting] = useState<boolean>(false);
    const [searchCriteria, setSearchCriteria] = useState<string>("");
    const [selectedTab, setSelectedTab] = useState<InstallTab>(InstallTab.All);

    const [countAll, setCountAll] = useState<number>();
    const [countPaid, setCountPaid] = useState<number>();
    const [countUnpaid, setCountUnpaid] = useState<number>();
    const [countScheduled, setCountScheduled] = useState<number>();
    const [countNotScheduled, setCountNotScheduled] = useState<number>();
    const [countSigned, setCountSigned] = useState<number>();
    const [countNotSigned, setCountNotSigned] = useState<number>();
    const [countPendingApproval, setCountPendingApproval] = useState<number>();

    const [manualAddInstallModalOpen, setManualAddInstallModalOpen] = useState<boolean>(false);
    const [manualAddInstallPandaDocId, setManualAddInstallPandaDocId] = useState<string>();
    const [manualAddInstallTemplateId, setManualAddInstallTemplateId] = useState<string>();
    const [manualAddInstallTypeId, setManualAddInstallTypeId] = useState<string>();
    const [pandaDocTemplates, setPandaDocTemplates] = useState<IPandaDocLightingProposalTemplate[]>();

    const [paidAmount, setPaidAmount] = useState<number>();
    const [unpaidAmount, setUnpaidAmount] = useState<number>();
    const [notScheduledAmount, setNotScheduledAmount] = useState<number>();
    const [scheduledAmount, setScheduledAmount] = useState<number>();
    const [downpaymentAmount, setDownPaymentAmount] = useState<number>();

    const [selectedContractorJob, setSelectedContractorJob] = useState<IContractorJob>();
    const [contractorJobs, setContractorJobs] = useState<IContractorJob[]>();
    const [lotNumber, setlotNumber] = useState<string>('');
    const [ownerFirstName, setOwnerFirstName] = useState<string>('');
    const [ownerLastName, setOwnerLastName] = useState<string>('');
    const [ownerEmail, setOwnerEmail] = useState<string>('');
    const [ownerAddressStreet, setOwnerAddressStreet] = useState<string>('');
    const [ownerAddressCity, setOwnerAddressCity] = useState<string>('');
    const [ownerAddressState, setOwnerAddressState] = useState<string>('');
    const [ownerAddressZip, setOwnerAddressZip] = useState<string>('');
    const [ownerPhone, setOwnerPhone] = useState<string>('');
    const [lightType, setLightType] = useState<TypeOfLights | null>();
    const [contractorJobFtSold, setContractorJobFtSold] = useState<int>();
    const [contractorTotalSale, setContractorTotalSale] = useState<string>('');
    const [contractorTransactionDate, setContractorTransactionDate] = useState<Date>();

    const [selectedInstall, setSelectedInstall] = useState<IInstall>();
    const [installDate, setInstallDate] = useState<Date>();
    const [installDateModalOpen, setInstallDateModalOpen] = useState<boolean>(false);

    const [jopCompletedModalOpen, setJobCompletedModalOpen] = useState<boolean>(false);
    const [jobCompletedDate, setJobCompletedDate] = useState<Date>();

    const [footageModal, setFootageModal] = useState<boolean>(false);
    const [postInstallModal, setPostInstallModal] = useState<boolean>(false);
    const [selectedPostInstall, setSelectedPostInstall] = useState<IPostInstall>();
    const [lightsInstalledList, setLightInstalledList] = useState<{ ft: int; serialNumber: string | null; }[]>([]);
    const [serialNumber, setSerialNumber] = useState<string | undefined>();
    const [ftInstalled, setFtInstalled] = useState<string>('');
    const [installers, setInstallers] = useState<IUser[]>()
    const [installerId, setInstallerId] = useState<int>();

    const [openBalanceAmount, setOpenBalanceAmount] = useState<number>();
    const [openBalanceModalOpen, setOpenBalanceModalOpen] = useState<boolean>(false);

    const [installOptionsModalOpen, setInstallOptionsModalOpen] = useState<boolean>(false);
    const [newOpenBalance, setNewOpenBalance] = useState<string | null>(null);
    const [newStripeInvoiceId, setNewStripeInvoiceId] = useState<string | null>(null);
    const [newInvoicePaidDate, setNewInvoicePaidDate] = useState<Date | null>(null);
    const [newInvoiceSentDate, setNewInvoiceSentDate] = useState<Date | null>(null);
    const [newWarrantyId, setNewWarrantyId] = useState<string | null>(null);
    const [newWarrantySentDate, setNewWarrantySentDate] = useState<Date | null>(null);
    const [newWarrantySignedDate, setNewWarrantySignedDate] = useState<Date | null>(null);
    const [newPaymentMethod, setNewPaymentMethod] = useState<PaymentMethod | null>();
    const [isFinancedSelected, setIsFinancedSelected] = useState<boolean>(false);
    const [financingCompany, setFinancingCompany] = useState<FinancingCompany | null>(null);
    const [newLoanNumber, setNewLoanNumber] = useState<string | null>(null);
    const [newAmountFinanced, setNewAmountFinanced] = useState<string | null>(null);

    const [companies, setCompanies] = useState<ICompany[]>();

    const [timeFrame, setTimeFrame] = useState<{
        start: Date;
        end: Date;
        name: string;
    }>({ start: GetDate.nDaysAgo(30), end: GetDate.tomorrow(), name: "last30Days" });

    const [filteredCompany, setFilteredCompany] = useState<{
        companyId: number;
    }>({ companyId: 0 });

    const allInstalls = useRef<IInstall[]>();
    const paidInstalls = useRef<IInstall[]>();
    const unpaidInstalls = useRef<IInstall[]>();
    const scheduledInstalls = useRef<IInstall[]>();
    const notScheduledInstalls = useRef<IInstall[]>();
    const warrantySignedInstalls = useRef<IInstall[]>();
    const warrantyNotSignedInstalls = useRef<IInstall[]>();
    const pendingApprovalInstalls = useRef<IInstall[]>();

    const cursor = useRef<string>();
    const [displayInstalls, setDisplayInstalls] = useState<IInstall[]>();
    const [loadingInstalls, setLoadingInstalls] = useState<boolean>(false);

    const columns = useMemo(() =>
        [
            {
                Header: () => null,
                id: 'expander',
                Cell: ({ row }: { row: Row<any> }) => (
                    <span
                        {...row.getToggleRowExpandedProps()}>
                        {row.isExpanded ? <FontAwesomeIcon size="lg" icon={faCaretDown} /> : <FontAwesomeIcon size="lg" icon={faCaretRight} />}
                    </span>
                ),
            },
            {
                Header: "Id",
                accessor: (row: IInstall) => row,
                Cell: ({ cell: { value } }: { cell: { value: IInstall } }) => (
                    <Button
                        rounded
                        color={value.stripeId === null ? "red" : "gray"}
                        style="soft"
                        onClick={async () => {
                            if (
                                authContext?.hasAnyGrant(
                                    Scope.Installs.DELETE_ANY,
                                    Scope.Installs.UPDATE_ANY
                                )
                            ) {
                                openInstallOptionsModal(value);
                            }
                        }}
                    >
                        {value.id}
                    </Button>
                ),
            },
            {
                Header: "Transaction Date",
                accessor: (row: IInstall) => new Date(row.transactionDate).toLocaleDateString(),
            },
            {
                Header: "Branch",
                accessor: (row: IInstall) => row.branch.name,
            },
            {
                Header: "Customer",
                accessor: (row: IInstall) => row,
                Cell: ({ cell: { value } }: { cell: { value: IInstall } }) => (
                    <div className="p-4 leading-none">
                        <div className="flex justify-between">
                            <div>
                                {value.customer.firstName} {value.customer.lastName}
                            </div>
                        </div>
                        <div className="text-gray-400 text-xs">
                            {value.customer.address}
                        </div>
                        <div className="text-gray-400 text-xs">
                            {value.customer.email}
                        </div>
                    </div>
                ),
            },
            {
                Header: "Warranty",
                accessor: (row: IInstall) => row,
                Cell: ({ cell: { value } }: { cell: { value: IInstall } }) => (
                    <Button
                        className={value.warrantySent ? "disabled:opacity-50 cursor-default" : ""}
                        rounded
                        style="soft"
                        color={value.warrantySigned ? "green" : value.warrantySent ? "yellow" : "gray"}
                        onClick={() => {
                            if (!value.warrantySent) {
                                sendWarrantyDoc(value);
                            }
                        }}
                    >
                        {value.warrantySigned ? "Signed" : value.warrantySent ? "Sent" : "Not Sent"}
                    </Button>
                ),
            },
            {
                Header: "Invoice",
                accessor: (row: IInstall) => row,
                Cell: ({ cell: { value } }: { cell: { value: IInstall } }) => (
                    <Button
                        rounded
                        style="soft"
                        color={
                            value.invoiceSent
                                ? value.invoicePaid
                                    ? "green"
                                    : "yellow"
                                : "gray"
                        }
                        onClick={() => {
                            if (value.invoiceSent && !value.invoicePaid) {
                                markAsPaid(value);
                            } else if (!value.invoiceSent) {
                                sendOutstandingBalanceInvoice(value);
                            }
                        }}
                    >
                        {value.invoiceSent
                            ? value.invoicePaid
                                ? "Paid"
                                : "Mark As Paid"
                            : "Not Sent"}
                    </Button>
                ),
            },
            {
                Header: "Install Date",
                accessor: (row: IInstall) => row,
                Cell: ({ cell: { value } }: { cell: { value: IInstall } }) => {
                    const { color, icon } = getBookingStatusProps(value);

                    return (
                        <Button
                            rounded
                            style="soft"
                            onClick={() => {
                                if (value.paymentMethod === PaymentMethod.FINANCE && !value.amountFinanced) {
                                    alert('This is a financed install and the amount financed has not been set. Please verify the amount financed before scheduling the install.');
                                }
                                openInstallDateModal(value);
                            }}
                            color={color}
                        >
                            {value.installDate ? new Date(value.installDate).toLocaleDateString() : "Not Scheduled"}
                            {icon}
                        </Button>
                    );
                }
            },
            {
                Header: "Job Completed",
                accessor: (row: IInstall) => row,
                Cell: ({ cell: { value } }: { cell: { value: IInstall } }) => (
                    <Button
                        rounded
                        style="soft"
                        color={value.jobCompletedDate ? "green" : "gray"}
                        onClick={() => openJobCompletedModal(value)}
                    >
                        {value.jobCompletedDate ? new Date(value.jobCompletedDate).toLocaleDateString() : "Not completed"}
                    </Button>
                ),
            },
            {
                Header: "Open Balance",
                accessor: (row: IInstall) => row,
                Cell: ({ cell: { value } }: { cell: { value: IInstall } }) => (
                    <div className="p-4 leading-none">
                        <div className="flex justify-between">
                            <Button
                                rounded
                                style="soft"
                                color={setOpenBalanceColor(value)}
                                onClick={() => {
                                    if (value.openBalance !== 0) {
                                        openOpenBalanceModal(value);
                                    }
                                }}
                            >
                                {formatMoney(value.openBalance)}
                            </Button>
                        </div>
                        <div className="text-gray-400 text-xs">
                            Method: {value.paymentMethod ? value.paymentMethod : "?"}
                        </div>
                    </div>
                ),
            },
            {
                Header: "Total Sale",
                accessor: (row: IInstall) => formatMoney(row.total),
            },
            {
                Header: "Down Payment",
                accessor: (row: IInstall) => formatMoney(row.downPayment),
            },
            {
                Header: "Sales Tax",
                accessor: (row: IInstall) => row,
                Cell: ({ cell: { value } }: { cell: { value: IInstall } }) => (
                    <div>
                        <div className="flex justify-between">
                            <div>
                                {formatMoney(value.salesTax)}
                            </div>
                        </div>
                        <div className="text-gray-400 text-xs">
                            Elligible: {formatMoney(value.salesTaxElligible)}
                        </div>
                        <div className="text-gray-400 text-xs">
                            Inelligible: {formatMoney(value.salesTaxInelligible)}
                        </div>
                    </div>
                ),
            },
            {
                Header: "Total Discount",
                accessor: (row: IInstall) => formatMoney(row.cashDiscount),
            },
        ],
        []
    );

    const data = useMemo(() => displayInstalls || [], [displayInstalls]);

    const renderRowSubComponent = React.useCallback(({ row }: { row: Row<IInstall> }) => {
        return (
            <InstallSubRow row={row} />
        );
    }, []);

    const loadPaymentAmounts = async () => {
        const response = await RetrieveInstalls({
            filters: {
                branch: {
                    id: filteredCompany.companyId ? Equals(int(filteredCompany.companyId)) : undefined,
                },
                createdAt: Between(timeFrame.start, timeFrame.end),
            },
        });

        if (!response) return;

        let tempPaidAmount = 0;
        let tempUnpaidAmount = 0;
        let tempNotScheduledAmount = 0;
        let tempScheduledAmount = 0;
        let tempDownPaymentAmount = 0;

        response.values.map((el) => {
            if (!el.invoicePaid && el.jobCompletedDate && new Date(el.jobCompletedDate) < new Date()) {
                tempUnpaidAmount += el.openBalance;
            }

            if (!el.installDate) {
                // Subtract pending finance amount from not scheduled tab
                if (el.paymentMethod === PaymentMethod.FINANCE && !el.amountFinanced) {
                    tempNotScheduledAmount -= el.total;
                } else {
                    tempNotScheduledAmount += el.total;
                }
            }

            if (el.invoicePaid) {
                tempPaidAmount += el.total;
            }

            if (!el.invoicePaid && el.installDate && new Date(el.installDate) >= new Date()) {
                tempScheduledAmount += el.total;
            }

            if (el.downPayment && !el.installDate) {
                // Subtract pending finance amount from not scheduled tab
                if (el.paymentMethod === PaymentMethod.FINANCE && !el.amountFinanced) {
                    tempDownPaymentAmount -= el.total;
                } else {
                    tempDownPaymentAmount += el.downPayment;
                }
            };
        });

        setPaidAmount(tempPaidAmount);
        setUnpaidAmount(tempUnpaidAmount);
        setNotScheduledAmount(tempNotScheduledAmount);
        setScheduledAmount(tempScheduledAmount);
        setDownPaymentAmount(tempDownPaymentAmount);
    };

    const loadPandaDocTemplates = async () => {
        const response = await RetrievePandaDocTemplateRelationships();
        if (!response) {
            toast.error("Unable to load pandadoc templates");
            return;
        }

        setPandaDocTemplates(response);
    };

    const clearLoadedInstalls = () => {
        allInstalls.current = [];
        unpaidInstalls.current = [];
        paidInstalls.current = [];
        scheduledInstalls.current = [];
        notScheduledInstalls.current = [];
        warrantySignedInstalls.current = [];
        warrantyNotSignedInstalls.current = []
    }

    const loadAll = async () => {
        await loadInstalls(100);
        if (cursor.current) {
            setTimeout(() => loadAll(), 200);
        }
    }

    const loadInstalls = async (num: number) => {
        setLoadingInstalls(true);

        console.log(`Loading ${num} more leads`);
        if (allInstalls.current?.length && !cursor.current) {
            console.log('No more installs');
            return;
        }
        let response = await RetrieveInstalls({
            filters: {
                createdAt: Between(timeFrame.start, timeFrame.end),
                branch: {
                    id: filteredCompany.companyId ? Equals(int(filteredCompany.companyId)) : undefined
                }
            },
            relations: ['branch', 'postInstalls', 'postInstalls.installer', 'salesRep', 'contractorJob', 'contractorJob.contractor', 'bookings', 'bookableUsers', 'bookableUsers.user']
        }, {
            direction: 'forward',
            count: num,
            cursor: cursor.current
        });

        if (!response) {
            console.warn('No response from load installs call');
            setLoadingInstalls(false);
            return;
        }
        if (!response.pagination) {
            console.error('Response missing pagination information');
            return;
        }
        cursor.current = response.pagination.end_cursor;

        const retrievedInstalls = response.values;

        retrievedInstalls.sort((a, b) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });

        allInstalls.current = [...(allInstalls.current ?? []), ...retrievedInstalls]; // may duplicate rows in rare instances. TODO: Fix this eventually

        sortTabs();
        setDisplayedInstallsFromTab(selectedTab);
        setLoadingInstalls(false);
    }

    const sortTabs = () => {
        const paid: IInstall[] = [];
        const unpaid: IInstall[] = [];
        const scheduled: IInstall[] = [];
        const notScheduled: IInstall[] = [];
        const signed: IInstall[] = [];
        const unsigned: IInstall[] = [];
        const pendingApproval: IInstall[] = [];

        allInstalls.current?.forEach((install) => {
            if (!install.invoicePaid && install.jobCompletedDate && new Date(install.jobCompletedDate) < new Date()) {
                unpaid.push(install);
            }
            if (!install.installDate) {
                notScheduled.push(install);
            }
            if (install.invoicePaid) {
                paid.push(install);
            }
            if (!install.invoicePaid && install.installDate && new Date(install.installDate) > new Date()) {
                scheduled.push(install);
            }
            if (install.warrantySent && !install.warrantySigned) {
                unsigned.push(install);
            }
            if (install.warrantySigned) {
                signed.push(install);
            }
            if (install.paymentMethod === PaymentMethod.FINANCE && !install.amountFinanced) {
                pendingApproval.push(install);
            }
        });


        paidInstalls.current = paid;
        unpaidInstalls.current = unpaid;
        scheduledInstalls.current = scheduled;
        notScheduledInstalls.current = notScheduled;
        warrantyNotSignedInstalls.current = unsigned;
        warrantySignedInstalls.current = signed;
        pendingApprovalInstalls.current = pendingApproval;
    }

    const loadCounts = async () => {
        let tempCountAll = await RetrieveInstallCount({
            branch: {
                id: filteredCompany.companyId ? Equals(int(filteredCompany.companyId)) : undefined,
            },
            createdAt: Between(timeFrame.start, timeFrame.end),
        });
        if (tempCountAll === undefined) {
            return;
        }

        let tempCountPaid = await RetrieveInstallCount({
            branch: {
                id: filteredCompany.companyId ? Equals(int(filteredCompany.companyId)) : undefined,
            },
            invoicePaid: Exists(),
            createdAt: Between(timeFrame.start, timeFrame.end),
        });
        if (tempCountPaid === undefined) {
            return;
        }

        let tempCountUnpaid = await RetrieveInstallCount({
            branch: {
                id: filteredCompany.companyId ? Equals(int(filteredCompany.companyId)) : undefined,
            },
            invoicePaid: Not(Exists()),
            jobCompletedDate: LessThan(new Date()),
            createdAt: Between(timeFrame.start, timeFrame.end),
        });
        if (tempCountUnpaid === undefined) {
            return;
        }

        let tempCountScheduled = await RetrieveInstallCount({
            branch: {
                id: filteredCompany.companyId ? Equals(int(filteredCompany.companyId)) : undefined,
            },
            invoicePaid: Not(Exists()),
            installDate: Not(LessThan(new Date())),
            createdAt: Between(timeFrame.start, timeFrame.end),
        });
        if (tempCountScheduled === undefined) {
            return;
        }

        let tempCountNotScheduled = await RetrieveInstallCount({
            branch: {
                id: filteredCompany.companyId ? Equals(int(filteredCompany.companyId)) : undefined,
            },
            installDate: Not(Exists()),
            createdAt: Between(timeFrame.start, timeFrame.end),
        });
        if (tempCountNotScheduled === undefined) {
            return;
        }

        let tempCountSigned = await RetrieveInstallCount({
            branch: {
                id: filteredCompany.companyId ? Equals(int(filteredCompany.companyId)) : undefined,
            },
            warrantySigned: Exists(),
            createdAt: Between(timeFrame.start, timeFrame.end),
        });
        if (tempCountSigned === undefined) {
            return;
        }

        let tempCountNotSigned = await RetrieveInstallCount({
            branch: {
                id: filteredCompany.companyId ? Equals(int(filteredCompany.companyId)) : undefined,
            },
            warrantySent: Exists(),
            warrantySigned: Not(Exists()),
            createdAt: Between(timeFrame.start, timeFrame.end),
        });
        if (tempCountNotSigned === undefined) {
            return;
        }
        const tempCountPendingApproval = await RetrieveInstallCount({
            branch: {
                id: filteredCompany.companyId ? Equals(int(filteredCompany.companyId)) : undefined,
            },
            paymentMethod: Equals(PaymentMethod.FINANCE),
            amountFinanced: Not(Exists()),
            createdAt: Between(timeFrame.start, timeFrame.end),
        });
        if (tempCountPendingApproval === undefined) {
            return;
        }

        setCountAll(tempCountAll);
        setCountPaid(tempCountPaid);
        setCountUnpaid(tempCountUnpaid);
        setCountScheduled(tempCountScheduled);
        setCountNotScheduled(tempCountNotScheduled);
        setCountSigned(tempCountSigned);
        setCountNotSigned(tempCountNotSigned);
        setCountPendingApproval(tempCountPendingApproval);
    };

    const clearCounts = () => {
        setCountAll(undefined);
        setCountPaid(undefined);
        setCountUnpaid(undefined);
        setCountScheduled(undefined);
        setCountNotScheduled(undefined);
        setCountSigned(undefined);
        setCountNotSigned(undefined);
        setCountPendingApproval(undefined);
    }

    const setDisplayedInstallsFromTab = (tab: InstallTab) => {
        switch (tab) {
            case InstallTab.All:
                setDisplayInstalls(allInstalls.current);
                break;
            case InstallTab.PAID:
                setDisplayInstalls(paidInstalls.current);
                break;
            case InstallTab.NOT_PAID:
                setDisplayInstalls(unpaidInstalls.current);
                break;
            case InstallTab.SCHEDULED:
                setDisplayInstalls(scheduledInstalls.current);
                break;
            case InstallTab.NOT_SCHEDULED:
                setDisplayInstalls(notScheduledInstalls.current);
                break;
            case InstallTab.WARRANTY_SIGNED:
                setDisplayInstalls(warrantySignedInstalls.current);
                break;
            case InstallTab.WARRANTY_NOT_SIGNED:
                setDisplayInstalls(warrantyNotSignedInstalls.current);
                break;
            case InstallTab.PENDING_APPROVAL:
                setDisplayInstalls(pendingApprovalInstalls.current);
                break;
            default:
                setDisplayInstalls(allInstalls.current);
                break;
        }
    }

    const loadCompanies = async () => {
        const response = await RetrieveCompanies({
            filters: {
                companyType: Equals(CompanyType.BRANCH),
            },
        });
        if (!response) return;

        setCompanies(response);
    };

    const loadContractorJobs = async () => {
        const response = await RetrieveContractorJobs({
            relations: ['company', 'contacts']
        });

        setContractorJobs(response);
    };

    const deleteInstall = async () => {
        if (!allInstalls.current) return;
        if (!paidInstalls.current) return;
        if (!unpaidInstalls.current) return;
        if (!scheduledInstalls.current) return;
        if (!notScheduledInstalls.current) return;
        if (!warrantyNotSignedInstalls.current) return;
        if (!warrantySignedInstalls.current) return;
        if (!pendingApprovalInstalls.current) return

        if (!selectedInstall) return;

        if (!window.confirm("Are you sure you want to delete this install?")) return;
        const response = await DeleteInstall(selectedInstall.id);
        if (!response) {
            toast.error("Error deleting install");
            return;
        }

        allInstalls.current = allInstalls.current.filter((el) => el.id !== selectedInstall.id);
        paidInstalls.current = paidInstalls.current.filter((el) => el.id !== selectedInstall.id);
        unpaidInstalls.current = unpaidInstalls.current.filter((el) => el.id !== selectedInstall.id);
        scheduledInstalls.current = scheduledInstalls.current.filter((el) => el.id !== selectedInstall.id);
        notScheduledInstalls.current = notScheduledInstalls.current.filter((el) => el.id !== selectedInstall.id);
        warrantySignedInstalls.current = warrantySignedInstalls.current.filter((el) => el.id !== selectedInstall.id);
        warrantyNotSignedInstalls.current = warrantyNotSignedInstalls.current.filter((el) => el.id !== selectedInstall.id);
        pendingApprovalInstalls.current = pendingApprovalInstalls.current.filter((el) => el.id !== selectedInstall.id);

        closeInstallOptionsModal();
        toast("Install Deleted");
        setDisplayedInstallsFromTab(selectedTab);
        loadCounts();
        loadPaymentAmounts();
    };

    const manuallyAddInstall = async () => {
        if (!allInstalls.current || !paidInstalls.current || !unpaidInstalls.current || !scheduledInstalls.current || !notScheduledInstalls.current || !warrantyNotSignedInstalls.current || !warrantySignedInstalls.current) {
            return;
        }

        if (manualAddInstallTypeId === InstallType.PANDADOC) {
            if (!manualAddInstallPandaDocId) {
                toast.error("You must include a Pandadoc document Id");
                return;
            }

            const response = await ManuallyCreateInstall(manualAddInstallPandaDocId, manualAddInstallTemplateId);
            if (!response) {
                toast.error("Unable to add this install. Try adding the templateId.");
                return;
            }

            if (response.invoicePaid && response.installDate && response.installDate < new Date()) {
                unpaidInstalls.current = [response, ...unpaidInstalls.current];
            }

            if (response.invoicePaid) {
                paidInstalls.current = [response, ...paidInstalls.current];
            }

            if (!response.installDate) {
                notScheduledInstalls.current = [response, ...notScheduledInstalls.current];
            }

            if (!response.invoicePaid && response.installDate && response.installDate > new Date()) {
                scheduledInstalls.current = [response, ...scheduledInstalls.current];
            }

            if (response.warrantySent && !response.warrantySigned) {
                warrantyNotSignedInstalls.current = [response, ...warrantyNotSignedInstalls.current];
            }

            if (response.warrantySigned) {
                warrantySignedInstalls.current = [response, ...warrantySignedInstalls.current];
            }

            allInstalls.current = [response, ...allInstalls.current];
        } else {
            const missingFields = [];
            if (!ownerFirstName) missingFields.push('First Name');
            if (!ownerEmail) missingFields.push('Email');
            if (!ownerAddressStreet) missingFields.push('Address Street');
            if (!ownerAddressCity) missingFields.push('Address City');
            if (!ownerAddressState) missingFields.push('Address State');
            if (!ownerAddressZip) missingFields.push('Address Zip');
            if (!lightType) missingFields.push('Light Type');
            if (!contractorJobFtSold) missingFields.push('Ft Sold');
            if (!contractorTotalSale) missingFields.push('Total Sale');
            if (!selectedContractorJob) missingFields.push('Contractor Job');
            if (!contractorTransactionDate) missingFields.push('Transaction Date');

            if (missingFields.length > 0) {
                toast.error(`Missing fields: ${missingFields.join(', ')}`);
                return;
            }

            const address = `${ownerAddressStreet}, ${ownerAddressCity}, ${ownerAddressState} ${ownerAddressZip}`;
            const formattedMoney = await moneyStringToCents(contractorTotalSale!);

            const params: ICreateInstallParams = {
                createdAt: undefined,
                transactionDate: contractorTransactionDate!,
                pandaDocId: null,
                stripeId: null,
                branch: selectedContractorJob!.company,
                salesRep: null,
                invoiceId: null,
                warrantyId: null,
                customer: {
                    firstName: ownerFirstName,
                    lastName: ownerLastName,
                    address: address,
                    email: ownerEmail,
                    phone: ownerPhone || '',
                },
                validatedAddress: null,
                warrantySent: null,
                warrantySigned: null,
                invoiceSent: null,
                invoicePaid: null,
                total: formattedMoney,
                subtotal: formattedMoney,
                downPayment: int(0),
                openBalance: formattedMoney,
                salesTaxElligible: int(0),
                salesTaxInelligible: int(0),
                salesTax: int(0),
                cashDiscount: int(0),
                installDate: null,
                paymentMethod: null,
                quoteRequest: null,
                typeOfLights: lightType,
                lotNumber: lotNumber ? lotNumber : null,
                ftSold: contractorJobFtSold,
                rawDocument: null,
                contractorJobId: selectedContractorJob!.id,

                amountFinanced: null,
                financingCharge: null,
                pandadocTotal: null,
                auditUserId: authContext!.user!.accountId,
                channel: null,
                loanNumber: null,
                financingCompany: null
            };

            const createdInstall = await ManuallyCreateInstallFromContractorJob(params);
            if (!createdInstall) {
                toast.error("Unable to add this install.");
                return;
            }
            allInstalls.current = [createdInstall, ...allInstalls.current];
        }

        setDisplayedInstallsFromTab(selectedTab); // does not update tab counts.
        loadCounts();
        loadPaymentAmounts();
        toast("Install added");
        closeManualAddInstallModal();
    };

    const closeManualAddInstallModal = () => {
        setManualAddInstallModalOpen(false);
        setManualAddInstallPandaDocId(undefined);
        setManualAddInstallTemplateId(undefined);
        setLightType(undefined);
    };

    const openInstallDateModal = (install: IInstall) => {
        setSelectedInstall(install);
        setInstallDateModalOpen(true);
    };

    const closeInstallDateModal = () => {
        setSelectedInstall(undefined);
        setInstallDate(undefined);
        setInstallDateModalOpen(false);
    };

    const clearInstallDate = async () => {
        if (!selectedInstall) return;

        if (selectedInstall.bookings?.length) {
            const confirm = window.confirm('This install is booked. Changing the date requires updating the calendar booking too. Continue?');
            if (!confirm) return;
        }

        const response = await UpdateInstall(selectedInstall.id, { installDate: null, auditUserId: authContext!.user!.accountId });
        if (!response) {
            toast.error("Something went wrong");
            return;
        }

        const updatedInstall = allInstalls.current!.find((el => el.id === response.id));
        if (!updatedInstall) return;

        Object.assign(updatedInstall, response);

        sortTabs();
        loadCounts();
        loadPaymentAmounts();
        setDisplayedInstallsFromTab(selectedTab);
        toast("Saved");
        closeInstallDateModal();
    };

    const addInstallDate = async () => {
        if (!selectedInstall || !installDate) return;

        if (selectedInstall.bookings?.length) {
            const confirm = window.confirm('This install is booked. Changing the date requires updating the calendar booking too. Continue?');
            if (!confirm) return;
        }

        const response = await UpdateInstall(selectedInstall.id, { installDate: installDate, auditUserId: authContext!.user!.accountId });
        if (!response) {
            toast.error("Something went wrong");
            return;
        }

        const updatedInstall = allInstalls.current!.find(el => el.id === response.id);
        if (!updatedInstall) return;

        Object.assign(updatedInstall, response);

        sortTabs();
        loadCounts();
        loadPaymentAmounts();
        setDisplayedInstallsFromTab(selectedTab);
        toast("Saved");
        closeInstallDateModal();
    };

    const addJobCompletedDate = async () => {
        if (!selectedInstall || !jobCompletedDate) return;

        const response = await MarkInstallAsJobCompleted(selectedInstall.id, jobCompletedDate);
        if (!response) return toast.error('Error marking job as completed');

        const updatedInstall = allInstalls.current!.find(el => el.id === response.id);
        if (!updatedInstall) return;

        Object.assign(updatedInstall, response);

        setDisplayedInstallsFromTab(selectedTab);
        toast.success('Saved');

        closeJobCompletedModal();
    }

    const clearJobCompletedDate = async () => {
        if (!selectedInstall) return;

        const response = await UpdateInstall(selectedInstall.id, { jobCompletedDate: null, auditUserId: authContext!.user!.accountId });
        if (!response) {
            toast.error('Something went wrong');
            return;
        }
        const updatedInstall = allInstalls.current!.find((el => el.id === response.id));
        if (!updatedInstall) return;

        Object.assign(updatedInstall, response);

        setDisplayedInstallsFromTab(selectedTab);
        toast("Saved");
        closeJobCompletedModal();
    }

    const openJobCompletedModal = (install: IInstall) => {
        setSelectedInstall(install);
        setJobCompletedModalOpen(true);
    }

    const closeJobCompletedModal = () => {
        setSelectedInstall(undefined);
        setJobCompletedModalOpen(false);
        setJobCompletedDate(undefined);
    }

    const openFootageModal = () => {
        setFootageModal(true);
        closeInstallOptionsModal();
    }

    const closeFootageModal = () => {
        setFootageModal(false);
    }

    const openPostInstallModal = () => {
        setPostInstallModal(true);
    }

    const closePostInstallModal = () => {
        setPostInstallModal(false);
        setSelectedPostInstall(undefined);
    }

    const openOpenBalanceModal = (install: IInstall) => {
        setSelectedInstall(install);
        setOpenBalanceModalOpen(true);
    };

    const closeOpenBalanceModal = () => {
        setSelectedInstall(undefined);
        setOpenBalanceAmount(undefined);
        setOpenBalanceModalOpen(false);
    };

    const openInstallOptionsModal = (install: IInstall) => {
        setSelectedInstall(install);
        setNewOpenBalance((install.openBalance / 100).toFixed(2).toString()); //int(Math.trunc(install.openBalance / 100)));
        setNewStripeInvoiceId(install.invoiceId);
        setNewInvoicePaidDate(install.invoicePaid ? new Date(install.invoicePaid) : null);
        setNewInvoiceSentDate(install.invoiceSent ? new Date(install.invoiceSent) : null);
        setNewWarrantyId(install.warrantyId);
        setNewWarrantySignedDate(install.warrantySigned ? new Date(install.warrantySigned) : null);
        setNewWarrantySentDate(install.warrantySent ? new Date(install.warrantySent) : null);
        setInstallOptionsModalOpen(true);
        setNewLoanNumber(install.loanNumber);
        setNewAmountFinanced(install.amountFinanced ? (install.amountFinanced / 100).toFixed(2).toString() : null);

        install.paymentMethod === PaymentMethod.FINANCE ? setIsFinancedSelected(true) : setIsFinancedSelected(false);
    };

    const closeInstallOptionsModal = () => {
        setInstallOptionsModalOpen(false);
        setNewOpenBalance(null);
        setNewStripeInvoiceId(null);
        setNewInvoicePaidDate(null);
        setNewInvoiceSentDate(null);
        setNewWarrantyId(null);
        setNewWarrantySignedDate(null);
        setNewWarrantySentDate(null);
        setNewLoanNumber(null);
        setNewAmountFinanced(null);
        setFinancingCompany(null);
    };

    const updateInstall = async () => {
        if (!selectedInstall) return;

        const response = await UpdateInstall(selectedInstall.id, {
            openBalance: newOpenBalance === null ? undefined : moneyStringToCents(newOpenBalance),
            invoiceId: newStripeInvoiceId === "" ? null : newStripeInvoiceId,
            invoiceSent: newInvoiceSentDate,
            invoicePaid: newInvoicePaidDate,
            warrantyId: newWarrantyId === "" ? null : newWarrantyId,
            warrantySent: newWarrantySentDate,
            warrantySigned: newWarrantySignedDate,
            paymentMethod: newPaymentMethod,
            financingCompany: financingCompany,
            amountFinanced: newAmountFinanced === null ? undefined : moneyStringToCents(newAmountFinanced),
            loanNumber: newLoanNumber === "" ? null : newLoanNumber,
            auditUserId: authContext!.user!.accountId
        });

        if (!response) {
            toast.error("Something went wrong");
            return;
        }

        const updatedInstall = allInstalls.current!.find(el => el.id === response.id);
        if (!updatedInstall) return;

        Object.assign(updatedInstall, response);

        if (newInvoicePaidDate && !selectedInstall.jobCompletedDate) {
            window.alert('Job is not marked as completed. You will be prompted to mark it as completed.');
            openJobCompletedModal(selectedInstall);
        }

        sortTabs();
        toast("Install Updated");
        setDisplayedInstallsFromTab(selectedTab);
        loadCounts();
        loadPaymentAmounts();
        closeInstallOptionsModal();
    };

    const updateOpenBalance = async () => {
        if (!selectedInstall || openBalanceAmount === undefined) return;

        let install: IInstall;

        if (openBalanceAmount === 0) {
            const response = await MarkInstallAsPaid(selectedInstall.id);

            if (response === undefined) {
                toast.error("Something went wrong");
                return;
            }

            install = response;
        } else {
            if (!authContext?.hasAnyGrant(Scope.Installs.ADJUST_OPEN_BALANCE)) {
                toast.error("Permission required to adjust open balance");
                return;
            }
            const response = await UpdateInstall(selectedInstall.id, { openBalance: int.parse((openBalanceAmount * 100).toFixed(2)), invoicePaid: null, auditUserId: authContext!.user!.accountId });

            if (response === undefined) {
                toast.error("Something went wrong");
                return;
            }

            install = response;
        }

        const updatedInstall = allInstalls.current!.find(el => el.id === install.id);
        if (!updatedInstall) return;

        Object.assign(updatedInstall, install);

        sortTabs();
        loadCounts();
        loadPaymentAmounts();
        setDisplayedInstallsFromTab(selectedTab);
        toast("Saved");
        closeOpenBalanceModal();
    };

    const markAsPaid = async (install: IInstall) => {
        if (!install.jobCompletedDate) {
            window.alert('Job is not marked as completed. You will be prompted to mark it as completed.');
            openJobCompletedModal(install);
        }

        if (window.confirm(`Are you sure ${install.customer.firstName} ${install.customer.lastName} has paid? This cannot be undone.`)) {
            const response = await MarkInstallAsPaid(install.id);

            if (!response) {
                toast.error("Something went wrong");
                return;
            }

            const updatedInstall = allInstalls.current!.find((el) => el.id === response.id);
            if (!updatedInstall) return;

            Object.assign(updatedInstall, response);

            sortTabs();
            loadCounts();
            loadPaymentAmounts();
            setDisplayedInstallsFromTab(selectedTab);
        }
    };

    const sendOutstandingBalanceInvoice = async (install: IInstall) => {
        let confirm;
        if (install.contractorJob) {
            confirm = window.confirm(`Are you sure you want to send the invoice doc to ${install.contractorJob.contractor.contractorName}?`);
        } else {
            confirm = window.confirm(`Are you sure you want to send the invoice doc to ${install.customer.firstName} ${install.customer.lastName}?`);
        }

        if (confirm) {
            const response = await SendOutstandingBalanceInvoie(install.id);
            if (!response) {
                toast.error("Something went wrong");
                return;
            }

            const updatedInstall = allInstalls.current!.find((el) => el.id === response.id);
            if (!updatedInstall) return;

            Object.assign(updatedInstall, response);

            sortTabs();
            loadCounts();
            loadPaymentAmounts();
            setDisplayedInstallsFromTab(selectedTab);
        }
    };

    const sendWarrantyDoc = async (install: IInstall) => {
        if (window.confirm(`Are you sure you want to send the warranty doc to ${install.customer.firstName} ${install.customer.lastName}?`)) {
            const response = await SendWarrantyDoc(install.id);

            if (!response) {
                toast.error("Something went wrong");
                return;
            }

            const updatedInstall = allInstalls.current!.find((el) => el.id === response.id);
            if (!updatedInstall) return;

            Object.assign(updatedInstall, response);

            sortTabs();
            loadCounts();
            loadPaymentAmounts();
            setDisplayedInstallsFromTab(selectedTab);
        }
    };

    const exportInstalls = async () => {
        setExporting(true);

        let allInstalls: IInstall[] = [];
        let cursor: string | undefined;
        do {
            const response = await RetrieveInstalls({
                filters: {
                    createdAt: Between(timeFrame.start, timeFrame.end),
                },
                relations: ['branch', 'salesRep']
            },
                {
                    direction: 'forward',
                    count: 40,
                    cursor: cursor
                });

            await new Promise(r => setTimeout(r, 200)); // sleep 200ms

            if (!response) continue;

            if (!response.pagination) {
                console.error('Response missing pagination information');
                setExporting(false);
                return;
            }

            cursor = response.pagination.end_cursor;

            allInstalls = allInstalls.concat(response.values);
        } while (cursor);

        const csv = objectsToCSV(
            allInstalls,
            {
                "Transaction Date": (i) => new Date(i.transactionDate).toLocaleDateString(),
                "Branch": (i) => i.branch.name,
                "Sales Rep": (i) => i.salesRep ? `${i.salesRep.firstname} ${i.salesRep.lastname}` : '',
                "Customer Name": (i) => `${i.customer.firstName} ${i.customer.lastName}`,
                "Customer Address": (i) => i.customer.address,
                "Customer Email": (i) => i.customer.email,
                "Pandadoc Id": (i) => i.pandaDocId ? i.pandaDocId : '',
                "Stripe Id": (i) => i.stripeId ?? '',
                "Warranty Status": (i) => (i.warrantySent ? (i.warrantySigned ? "Signed" : "Sent") : "Not Sent Yet"),
                "Invoice Status": (i) => (i.invoiceSent ? (i.invoicePaid ? "Paid" : "Sent") : "Not Sent Yet"),
                "Install Date": (i) => (i.installDate ? new Date(i.installDate!).toLocaleDateString() : "Not Scheduled"),
                "Job Completed Date": (i) => (i.jobCompletedDate ? new Date(i.jobCompletedDate!).toLocaleDateString() : "Not Completed"),
                "Paid Date": (i) => (i.invoicePaid ? new Date(i.invoicePaid!).toLocaleDateString() : "Not Paid"),
                "Open Balance": (i) => formatMoney(i.openBalance),
                "Total Sale": (i) => formatMoney(i.total),
                "Down Payment": (i) => formatMoney(i.downPayment),
                "Total Sales Tax": (i) => formatMoney(i.salesTax),
                "Sales Tax Elligble": (i) => formatMoney(i.salesTaxElligible),
                "Sales Tax Inelligible": (i) => formatMoney(i.salesTaxInelligible),
                "Total Discount": (i) => formatMoney(i.cashDiscount),
                "Ft Sold": (i) => i.ftSold ? i.ftSold.toString() : ''
            });

        let encodedCSV = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);

        let downloadLink = document.createElement("a");
        downloadLink.download = `Installs_${new Date().toLocaleDateString()}.csv`;
        downloadLink.href = encodedCSV;
        downloadLink.click();

        setExporting(false);
    };

    const createLightsInstalled = (serialNumber: string | null, ft: int) => {
        const lightsInstalled = {
            serialNumber: serialNumber ? serialNumber : null,
            ft,
        };

        if (!lightsInstalled) {
            toast.error("Error adding SN/ft");
            return;
        }

        setLightInstalledList([...lightsInstalledList, lightsInstalled]);
        setSerialNumber('');
        setFtInstalled('');
    };

    const deleteLightsInstalled = (lightsInstall: { ft: int; serialNumber: string | null; }) => {
        const newList = lightsInstalledList.filter((el) => el !== lightsInstall);
        setLightInstalledList(newList);
    };

    const submitFootage = async () => {
        if (!selectedPostInstall) {
            toast.error('Something went wrong');
            return;
        }
        const params: IUpdatePostInstall = {
            lightsInstalled: lightsInstalledList,
            installerAccountId: installerId,
        }

        const response = await UpdatePostInstall(selectedPostInstall.id, params);
        if (!response) {
            toast.error('Error - submission failed');
            return;
        }

        toast.success('Post install updated');
        setLightInstalledList([]);
        closePostInstallModal();
        closeFootageModal();
        setSelectedInstall(undefined);
    }

    const getTotalFootageSubmitted = (install: IInstall): number => {
        if (!install.postInstalls) return int(0);

        const total = install.postInstalls.reduce((acc, curr) => {
            if (!curr.lightsInstalled) return acc;
            return acc + curr.lightsInstalled.reduce((acc, curr) => acc + curr.ft, 0);
        }, 0);

        return total;
    }

    const deletePostInstall = async (postInstallId: int) => {
        const confirm = window.confirm('Are you sure you want to delete this post install?');

        if (confirm) {
            const response = await DeletePostInstall(postInstallId);
            if (!response) {
                toast.error('Error - deletion failed');
                return;
            }
            toast.success('Post install deleted');
            closeFootageModal();
            setSelectedInstall(undefined);
        }
    }

    const loadInstallers = async (companyId: int) => {
        const installers = await RetrieveUsers({
            relations: ['company'],
            filters: {
                company: {
                    id: Equals(companyId)
                }
            }
        })
        if (!installers) {
            toast.error('Could not retrieve installers');
            return;
        }
        setInstallers(installers)
    }

    const setOpenBalanceColor = (install: IInstall) => {
        if (install.openBalance === 0) return 'green';
        else if (install.paymentMethod === PaymentMethod.FINANCE && !install.amountFinanced) return 'yellow';
        return 'blue'
    }

    const getBookingStatusProps = (install: IInstall) => {
        const { bookings } = install;

        if (!bookings?.length) {
            return { color: 'gray', icon: null };
        }

        const isAnyBookingConfirmed = bookings.some(booking => booking.confirmed);
        if (isAnyBookingConfirmed) {
            return {
                color: 'green',
                icon: (
                    <div className="ml-2">
                        <FontAwesomeIcon icon={faCalendarCheck} />
                    </div>
                ),
            };
        }

        const isAnyBookingRescheduled = bookings.some(booking => booking.rescheduled);
        if (isAnyBookingRescheduled) {
            return {
                color: 'red',
                icon: (
                    <div className="ml-2">
                        <FontAwesomeIcon icon={faCalendarMinus} />
                    </div>
                ),
            };
        }

        return {
            color: 'blue',
            icon: (
                <div className="ml-2">
                    <FontAwesomeIcon icon={faCalendar} />
                </div>
            ),
        };
    };

    useEffect(() => {
        loadCompanies();
        loadContractorJobs();
        if (authContext?.hasAnyGrant(Scope.TaxRules.READ_ANY)) {
            loadPandaDocTemplates();
        }
    }, []);

    useEffect(() => {
        cursor.current = undefined;
        clearLoadedInstalls();
        loadInstalls(100);
        loadCounts();
        loadPaymentAmounts();
    }, [timeFrame, filteredCompany]);

    useEffect(() => {
        setDisplayedInstallsFromTab(selectedTab);
    }, [selectedTab]);

    return (
        <div className="w-full h-full max-w-full">
            <div className="flex flex-col h-full w-full max-w-full overflow-hidden">
                <TopBar title="Installations">
                    {authContext?.hasAnyGrant(Scope.Installs.CREATE_ANY) && (
                        <Button className="mr-4 text-sm" type="button" onClick={() => setManualAddInstallModalOpen(true)} color="blue" style="outline" rounded>
                            Add Install
                        </Button>
                    )}
                    <Button className="mr-4 text-sm" type="button" onClick={exportInstalls} color="blue" style="outline" rounded>
                        Export CSV
                    </Button>
                    <Link to="/installs/details">
                        <Button className="mr-8 text-sm" type="button" color="blue" style="outline" rounded>
                            Summary View
                        </Button>
                    </Link>
                    <SearchBar className="mr-8 w-96" search={setSearchCriteria} placeholder="Search Installs" />
                    <Select
                        className="quotes_date_dropdown mr-8"
                        defaultValue={{
                            value: {
                                start: GetDate.nDaysAgo(90),
                                end: GetDate.tomorrow(),
                                name: "last90Days",
                            },
                            label: "Last 90 Days",
                        }}
                        options={[
                            {
                                value: {
                                    start: GetDate.january(),
                                    end: GetDate.tomorrow(),
                                    name: "yearToDate",
                                },
                                label: "Year to Date",
                            },
                            {
                                value: {
                                    start: GetDate.nDaysAgo(30),
                                    end: GetDate.tomorrow(),
                                    name: "last30Days",
                                },
                                label: "Last 30 Days",
                            },
                            {
                                value: {
                                    start: GetDate.nDaysAgo(90),
                                    end: GetDate.tomorrow(),
                                    name: "last90Days",
                                },
                                label: "Last 90 Days",
                            },
                            {
                                value: {
                                    start: new Date(),
                                    end: new Date(),
                                    name: "custom",
                                },
                                label: "Custom Range",
                            },

                            {
                                value: {
                                    start: GetDate.currentMonth(),
                                    end: GetDate.tomorrow(),
                                    name: "monthToDate",
                                },
                                label: "Month to Date",
                            },
                            {
                                value: {
                                    start: GetDate.everlightsBirth(),
                                    end: GetDate.tomorrow(),
                                    name: "allTime",
                                },
                                label: "All Time",
                            },
                            ...GetDate.years().map(el => (
                                    {
                                        value: {
                                            start: el.start,
                                            end: el.end,
                                            name: `${el.start.getFullYear()}`,
                                        },
                                        label:  `${el.start.getFullYear()}`
                                    }
                                ))
                            ,
                            {
                                value: {
                                    start: GetDate.january(),
                                    end: GetDate.february(),
                                    name: "january",
                                },
                                label: "January",
                            },
                            {
                                value: {
                                    start: GetDate.february(),
                                    end: GetDate.march(),
                                    name: "february",
                                },
                                label: "February",
                            },
                            {
                                value: {
                                    start: GetDate.march(),
                                    end: GetDate.april(),
                                    name: "march",
                                },
                                label: "March",
                            },
                            {
                                value: {
                                    start: GetDate.april(),
                                    end: GetDate.may(),
                                    name: "april",
                                },
                                label: "April",
                            },
                            {
                                value: {
                                    start: GetDate.may(),
                                    end: GetDate.june(),
                                    name: "may",
                                },
                                label: "May",
                            },
                            {
                                value: {
                                    start: GetDate.june(),
                                    end: GetDate.july(),
                                    name: "june",
                                },
                                label: "June",
                            },
                            {
                                value: {
                                    start: GetDate.july(),
                                    end: GetDate.august(),
                                    name: "july",
                                },
                                label: "July",
                            },
                            {
                                value: {
                                    start: GetDate.august(),
                                    end: GetDate.september(),
                                    name: "august",
                                },
                                label: "August",
                            },
                            {
                                value: {
                                    start: GetDate.september(),
                                    end: GetDate.october(),
                                    name: "september",
                                },
                                label: "September",
                            },
                            {
                                value: {
                                    start: GetDate.october(),
                                    end: GetDate.november(),
                                    name: "october",
                                },
                                label: "October",
                            },
                            {
                                value: {
                                    start: GetDate.november(),
                                    end: GetDate.december(),
                                    name: "november",
                                },
                                label: "November",
                            },
                            {
                                value: {
                                    start: GetDate.december(),
                                    end: GetDate.nextJanuary(),
                                    name: "december",
                                },
                                label: "December",
                            },
                        ]}
                        onChange={(val) => {
                            if (val?.value.name === "custom") {
                                setTimeFrame({
                                    start: timeFrame.start,
                                    end: timeFrame.end,
                                    name: "custom",
                                });
                                clearCounts();
                                return;
                            }
                            setTimeFrame(val!.value);
                            clearCounts();
                        }}
                    />
                    {companies && authContext?.hasAnyGrant(Scope.Installs.READ_ANY) && (
                        <Select
                            className="quotes_date_dropdown"
                            defaultValue={{
                                value: {
                                    companyId: 0,
                                },
                                label: "All Branches",
                            }}
                            options={[
                                {
                                    value: {
                                        companyId: 0,
                                    },
                                    label: "All Branches",
                                },
                                ...companies.map((el) => ({
                                    value: {
                                        companyId: el.id,
                                    },
                                    label: el.name,
                                })),
                            ]}
                            onChange={(val) => setFilteredCompany(val!.value)}
                        />
                    )}
                </TopBar>

                {timeFrame.name === "custom" && (
                    <div className="flex justify-end space-x-4">
                        <input
                            type="date"
                            className="flex border hover:border-input_hover text-center"
                            defaultValue={timeFrame.start.toISOString().split("T")[0]}
                            onBlur={(e) => {
                                const date = new Date(e.target.value).getTime();

                                if (isNaN(date)) {
                                    return;
                                }
                                setTimeFrame({
                                    start: new Date(date),
                                    end: timeFrame.end,
                                    name: "custom",
                                });
                            }}
                        />
                        <input
                            type="date"
                            className="flex border hover:border-input_hover text-center"
                            defaultValue={timeFrame.end.toISOString().split("T")[0]}
                            onBlur={(e) => {
                                const date = new Date(e.target.value).getTime();

                                if (isNaN(date)) {
                                    return;
                                }
                                setTimeFrame({
                                    start: timeFrame.start,
                                    end: new Date(date),
                                    name: "custom",
                                });
                            }}
                        />
                    </div>
                )}

                <Modal
                    visible={installDateModalOpen}
                    closeModal={closeInstallDateModal}
                >
                    <form className="flex flex-col p-8">
                        <div className="text-center text-gray-500 text-lg mb-4">
                            Schedule Appointment Date
                        </div>
                        <div className="flex flex-col">
                            <label className="text-gray-500 text-sm mb-1">
                                Appointment Date
                            </label>
                            <input
                                type="date"
                                className="input"
                                onChange={(e) =>
                                    setInstallDate(new Date(Date.parse(e.target.value)))
                                }
                            />
                        </div>
                        <div className="flex justify-end">
                            <button
                                type="button"
                                className="mr-4 btn bg-gray-400 text-white mt-8"
                                onClick={() => closeInstallDateModal()}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={() => clearInstallDate()}
                                className="mr-4 btn text-gray-500 mt-8"
                            >
                                Clear
                            </button>
                            <button
                                type="button"
                                onClick={() => addInstallDate()}
                                className="btn bg-theme_blue text-white mt-8"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </Modal>

                <Modal
                    visible={openBalanceModalOpen}
                    closeModal={closeOpenBalanceModal}
                >
                    <form className="flex flex-col p-8">
                        <div className="text-center text-gray-500 text-lg mb-4">
                            Open Balance Amount
                        </div>
                        <div className="flex flex-col">
                            <label className="text-gray-500 text-sm mb-1">Amount</label>
                            <input
                                type="number"
                                className="input"
                                min={0}
                                placeholder="dollar amount"
                                onChange={(e) => setOpenBalanceAmount(Number(e.target.value))}
                            />
                        </div>
                        <div className="flex justify-end">
                            <button
                                type="button"
                                className="mr-4 btn bg-gray-400 text-white mt-8"
                                onClick={() => closeOpenBalanceModal()}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={() => updateOpenBalance()}
                                className="btn bg-theme_blue text-white mt-8"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </Modal>

                <Modal
                    visible={installOptionsModalOpen}
                    closeModal={closeInstallOptionsModal}
                >
                    <form className="flex flex-col p-8">
                        <div className="text-center text-gray-500 text-lg mb-4">
                            Update Install
                        </div>

                        <div className="flex flex-col">
                            <div className="flex flex-col my-2">
                                <label className="text-gray-500 text-sm mb-1">
                                    Stripe Invoice Id
                                </label>
                                <input
                                    className="input"
                                    value={newStripeInvoiceId ?? undefined}
                                    min={0}
                                    placeholder="Invoice Id"
                                    onChange={(e) => setNewStripeInvoiceId(e.target.value)}
                                />
                            </div>
                            <div>
                                <label className="text-gray-500 text-sm mb-1">
                                    Payment Method
                                </label>
                                <Select
                                    defaultValue={{
                                        value: selectedInstall?.paymentMethod
                                            ? selectedInstall.paymentMethod
                                            : null,
                                        label: selectedInstall?.paymentMethod
                                            ? selectedInstall.paymentMethod
                                            : "?",
                                    }}
                                    options={[
                                        { value: PaymentMethod.CASH, label: PaymentMethod.CASH },
                                        { value: PaymentMethod.CARD, label: PaymentMethod.CARD },
                                        {
                                            value: PaymentMethod.FINANCE,
                                            label: PaymentMethod.FINANCE,
                                        },
                                    ]}
                                    onChange={(val) => {
                                        setNewPaymentMethod(val?.value ? val.value : null);
                                        val?.value === PaymentMethod.FINANCE
                                            ? setIsFinancedSelected(true)
                                            : setIsFinancedSelected(false);
                                    }}
                                />
                            </div>
                            {isFinancedSelected ? (
                                <div>
                                    <div>
                                        <label className="text-gray-500 text-sm mb-1">
                                            Financing Company
                                        </label>
                                        <Select
                                            defaultValue={{
                                                label: selectedInstall?.financingCompany
                                                    ? financingCompanyToString(selectedInstall.financingCompany)
                                                    : "Select...",
                                                value: selectedInstall?.financingCompany
                                                    ? selectedInstall.financingCompany
                                                    : null,
                                            }}
                                            options={[
                                                {
                                                    label: financingCompanyToString(FinancingCompany.MOUNTAIN_AMERICA_CU),
                                                    value: FinancingCompany.MOUNTAIN_AMERICA_CU,
                                                },
                                                {
                                                    label: financingCompanyToString(FinancingCompany.SUNLIGHT_FINANCIAL_SERVICES),
                                                    value: FinancingCompany.SUNLIGHT_FINANCIAL_SERVICES,
                                                },
                                                {
                                                    label: financingCompanyToString(FinancingCompany.GREENSKY),
                                                    value: FinancingCompany.GREENSKY,
                                                },
                                                {
                                                    label: financingCompanyToString(FinancingCompany.GREENSKY_CA),
                                                    value: FinancingCompany.GREENSKY_CA
                                                }
                                            ]}
                                            onChange={(val) => {
                                                setFinancingCompany(val?.value ? val.value : null);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <label className="text-gray-500 text-sm mb-1">Loan Number</label>
                                        <input
                                            className="input"
                                            placeholder="Loan #"
                                            value={newLoanNumber ?? undefined}
                                            type="text"
                                            onChange={(e) => setNewLoanNumber(e.target.value)}
                                        >
                                        </input>
                                        <label className="text-gray-500 text-sm mb-1">Loan Amount</label>
                                        <input
                                            className="input"
                                            placeholder="Loan amount"
                                            type="number"
                                            value={newAmountFinanced ?? undefined}
                                            onChange={(e) => setNewAmountFinanced(e.target.value)}
                                        >
                                        </input>
                                    </div>
                                </div>
                            ) : null}
                            <div className="flex flex-col my-2">
                                <label className="text-gray-500 text-sm mb-1">
                                    Open Balance
                                </label>
                                <input
                                    className="input"
                                    value={newOpenBalance === null ? undefined : newOpenBalance}
                                    placeholder="Open Balance"
                                    type="number"
                                    onChange={(e) => setNewOpenBalance(e.target.value)}
                                />
                            </div>
                            <div className="flex flex-col my-2">
                                <label className="text-gray-500 text-sm mb-1">
                                    Invoice Sent Date
                                </label>
                                <input
                                    type="date"
                                    className="input"
                                    value={
                                        newInvoiceSentDate
                                            ? new Date(
                                                newInvoiceSentDate.getTime() -
                                                newInvoiceSentDate.getTimezoneOffset() * 60000
                                            )
                                                .toISOString()
                                                .split("T")[0]
                                            : ""
                                    }
                                    onChange={(e) =>
                                        e.target.value === ""
                                            ? setNewInvoiceSentDate(null)
                                            : setNewInvoiceSentDate(
                                                new Date(Date.parse(e.target.value))
                                            )
                                    }
                                />
                            </div>
                            <div className="flex flex-col my-2">
                                <label className="text-gray-500 text-sm mb-1">
                                    Invoice Paid Date
                                </label>
                                <input
                                    type="date"
                                    className="input"
                                    value={
                                        newInvoicePaidDate
                                            ? new Date(
                                                newInvoicePaidDate.getTime() -
                                                newInvoicePaidDate.getTimezoneOffset() * 60000
                                            )
                                                .toISOString()
                                                .split("T")[0]
                                            : ""
                                    }
                                    onChange={(e) =>
                                        e.target.value === ""
                                            ? setNewInvoicePaidDate(null)
                                            : setNewInvoicePaidDate(
                                                new Date(Date.parse(e.target.value))
                                            )
                                    }
                                />
                            </div>
                            <div className="flex flex-col my-2">
                                <label className="text-gray-500 text-sm mb-1">
                                    Warranty Doc Id
                                </label>
                                <input
                                    className="input"
                                    value={newWarrantyId ?? undefined}
                                    placeholder="Warranty Id"
                                    onChange={(e) => setNewWarrantyId(e.target.value)}
                                />
                            </div>
                            <div className="flex flex-col my-2">
                                <label className="text-gray-500 text-sm mb-1">
                                    Warranty Sent Date
                                </label>
                                <input
                                    type="date"
                                    className="input"
                                    value={
                                        newWarrantySentDate
                                            ? new Date(
                                                newWarrantySentDate.getTime() -
                                                newWarrantySentDate.getTimezoneOffset() * 60000
                                            )
                                                .toISOString()
                                                .split("T")[0]
                                            : ""
                                    }
                                    onChange={(e) =>
                                        e.target.value === ""
                                            ? setNewWarrantySentDate(null)
                                            : setNewWarrantySentDate(
                                                new Date(Date.parse(e.target.value))
                                            )
                                    }
                                />
                            </div>
                            <div className="flex flex-col my-2">
                                <label className="text-gray-500 text-sm mb-1">
                                    Warranty Signed Date
                                </label>
                                <input
                                    type="date"
                                    className="input"
                                    value={
                                        newWarrantySignedDate
                                            ? new Date(
                                                newWarrantySignedDate.getTime() -
                                                newWarrantySignedDate.getTimezoneOffset() * 60000
                                            )
                                                .toISOString()
                                                .split("T")[0]
                                            : ""
                                    }
                                    onChange={(e) =>
                                        e.target.value === ""
                                            ? setNewWarrantySignedDate(null)
                                            : setNewWarrantySignedDate(
                                                new Date(Date.parse(e.target.value))
                                            )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex justify-evenly">
                            {authContext?.hasAnyGrant(Scope.PostInstall.READ_ANY) && (
                                <Button
                                    style="outline"
                                    color="yellow"
                                    className="mr-0 mt-8"
                                    onClick={() => openFootageModal()}
                                >
                                    View footage
                                </Button>
                            )}
                            {authContext?.hasAnyGrant(Scope.Installs.DELETE_ANY) && (
                                <button
                                    type="button"
                                    className="mr-0 btn mt-8 shadow-none text-gray-500"
                                    onClick={() => deleteInstall()}
                                >
                                    Delete
                                </button>
                            )}
                            <button
                                type="button"
                                className="mr-4 btn bg-gray-400 text-white mt-8"
                                onClick={() => closeInstallOptionsModal()}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    if (!authContext?.hasAnyGrant(Scope.Installs.ADJUST_OPEN_BALANCE)) {
                                        // Check if newOpenBalance is a string or a float
                                        const parsedNewOpenBalance = typeof newOpenBalance === 'string' ? parseFloat(newOpenBalance) : newOpenBalance;

                                        // Convert newOpenBalance to an integer representing pennies
                                        const newOpenBalanceInPennies = Math.round(parsedNewOpenBalance! * 100);

                                        if (selectedInstall?.openBalance !== newOpenBalanceInPennies) {
                                            toast.error("Permission required to adjust open balance");
                                            return;
                                        }
                                    }
                                    updateInstall()
                                }}
                                className="btn bg-theme_blue text-white mt-8"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </Modal>

                <Modal
                    visible={manualAddInstallModalOpen}
                    closeModal={closeManualAddInstallModal}
                >
                    <form className="flex flex-col p-8">
                        <div className="text-center text-gray-500 text-lg mb-4">Add Install</div>
                        <div className="flex flex-col">
                            <div className="flex flex-col my-2">
                                <label className="text-gray-500 text-sm mb-1">Create Install From:</label>
                                <select
                                    className="input"
                                    value={manualAddInstallTypeId ?? undefined}
                                    onChange={(e) => {
                                        const value: any = e.target.value;
                                        setManualAddInstallTypeId(value === "" ? undefined : value);
                                        if (value !== InstallType.PANDADOC) {
                                            const contractorJob = contractorJobs?.find((e) => e.id === int.parse(value));
                                            setSelectedContractorJob(contractorJob);
                                        }
                                    }}
                                >
                                    <option value="">Select...</option>
                                    <option key={InstallType.PANDADOC} value="PandaDoc">
                                        PandaDoc
                                    </option>
                                    {contractorJobs?.map((e, index) => {
                                        return (
                                            <option key={index} value={e.id}>
                                                {e.contractJobName}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        {manualAddInstallTypeId === InstallType.PANDADOC && (
                            <div>
                                <div className="flex flex-col my-2">
                                    <label className="text-gray-500 text-sm mb-1">
                                        Pandadoc Document Id
                                        <span className="text-gray-500 text-xs ml-2">
                                            *only required if pandadoc was copied
                                        </span>
                                    </label>
                                    <input
                                        className="input"
                                        value={manualAddInstallPandaDocId ?? undefined}
                                        min={0}
                                        placeholder="required"
                                        onChange={(e) => setManualAddInstallPandaDocId(e.target.value)}
                                    />
                                </div>
                                <div className="flex flex-col my-2">
                                    <label className="text-gray-500 text-sm mb-1">Pandadoc Template Id</label>
                                    <select
                                        className="input"
                                        value={manualAddInstallTemplateId ?? undefined}
                                        onChange={(e) => setManualAddInstallTemplateId(e.target.value === "" ? undefined : e.target.value)}
                                    >
                                        <option value="">Optional</option>
                                        {pandaDocTemplates?.map((template) => {
                                            return (
                                                <option key={template.id} value={template.templateId}>
                                                    {template.templateName}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                        )}

                        {!isNaN(Number(manualAddInstallTypeId)) && (
                            <div>
                                <div className="flex flex-col my-2">
                                    <div className="flex flex-row gap-x-4">
                                        <div className="flex-initial basis-1/6 shrink w-4">
                                            <label className="text-gray-500 text-sm mb-1">Lot #</label>
                                            <input
                                                className="input"
                                                value={lotNumber ?? undefined}
                                                min={0}
                                                placeholder="optional"
                                                onChange={(e) => setlotNumber(e.target.value)}
                                            />
                                        </div>
                                        <div className="basis-5/6 shrink">
                                            <label className="text-gray-500 text-sm mb-1">Contact Email</label>
                                            <select
                                                className="input"
                                                onChange={(e) => setOwnerEmail(e.target.value)}
                                            >
                                                <option value="">Select...</option>
                                                {selectedContractorJob?.contacts &&
                                                    selectedContractorJob.contacts.map((contact) => (
                                                        <option key={contact.id} value={contact.email}>
                                                            {contact.email}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="flex flex-col my-2 gap-y-1"></div>
                                    <div>
                                        <label className="text-gray-500 text-sm mb-1">Owner First Name</label>
                                        <input
                                            type="text"
                                            className="input"
                                            value={ownerFirstName}
                                            placeholder="required"
                                            onChange={(e) => setOwnerFirstName(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <label className="text-gray-500 text-sm mb-1">Owner Last Name</label>
                                        <input
                                            type="text"
                                            className="input"
                                            value={ownerLastName}
                                            placeholder="optional"
                                            onChange={(e) => setOwnerLastName(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <label className="text-gray-500 text-sm mb-1">Address</label>
                                        <input
                                            className="input"
                                            value={ownerAddressStreet ?? undefined}
                                            min={0}
                                            placeholder="required"
                                            onChange={(e) => setOwnerAddressStreet(e.target.value)}
                                        />
                                    </div>
                                    <div className="flex flex-row gap-x-4">
                                        <div className="flex-initial basis-4/6 shrink w-4">
                                            <label className="text-gray-500 text-sm mb-1">City</label>
                                            <input
                                                className="input"
                                                value={ownerAddressCity ?? undefined}
                                                min={0}
                                                placeholder="required"
                                                onChange={(e) => setOwnerAddressCity(e.target.value)}
                                            />
                                        </div>
                                        <div className="basis-1/6 shrink">
                                            <label className="text-gray-500 text-sm mb-1">State</label>
                                            <input
                                                className="input"
                                                value={ownerAddressState ?? undefined}
                                                min={0}
                                                placeholder="required"
                                                onChange={(e) => setOwnerAddressState(e.target.value)}
                                            />
                                        </div>
                                        <div className="basis-1/6">
                                            <label className="text-gray-500 text-sm mb-1">Postal Code</label>
                                            <input
                                                className="input"
                                                value={ownerAddressZip ?? undefined}
                                                min={0}
                                                placeholder="required"
                                                onChange={(e) => setOwnerAddressZip(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col my-2 gap-y-1"></div>
                                    <div className="flex flex-row gap-x-4">
                                        <div className="flex-initial basis-4/6 shrink w-4">
                                            <label className="text-gray-500 text-sm mb-1">Lights</label>
                                            <select
                                                className="input"
                                                value={lightType as TypeOfLights}
                                                onChange={(e) => setLightType(e.target.value as TypeOfLights)}
                                            >
                                                <option value="">Select...</option>
                                                <option value="classic">Classic</option>
                                                <option value="color changing">Color Changing</option>
                                            </select>
                                        </div>
                                        <div className="basis-1/6">
                                            <label className="text-gray-500 text-sm mb-1">Quantity</label>
                                            <input
                                                className="input"
                                                value={contractorJobFtSold!}
                                                min={0}
                                                placeholder="required"
                                                onChange={(e) => setContractorJobFtSold(int.parse(e.target.value))}
                                            />
                                        </div>
                                    </div>
                                    <div className="basis-1/6">
                                        <label className="text-gray-500 text-sm mb-1">Total</label>
                                        <input
                                            type="number"
                                            className="input"
                                            value={contractorTotalSale}
                                            min={0}
                                            placeholder="required"
                                            onChange={(e) => setContractorTotalSale(e.target.value)}
                                        />
                                    </div>
                                    <div className="basis-1/6">
                                        <label className="text-gray-500 text-sm mb-1">Transaction Date</label>
                                        <input
                                            type="date"
                                            className="input"
                                            onChange={(e) => setContractorTransactionDate(new Date(Date.parse(e.target.value)))}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {manualAddInstallTypeId && (
                            <div className="flex flex-col">
                                <div className="flex justify-end">
                                    <button
                                        type="button"
                                        className="mr-4 btn bg-gray-400 text-white mt-8"
                                        onClick={closeManualAddInstallModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        onClick={manuallyAddInstall}
                                        className="btn bg-theme_blue text-white mt-8"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        )}
                    </form>
                </Modal>

                <Modal
                    visible={jopCompletedModalOpen}
                    closeModal={closeJobCompletedModal}
                >
                    <form className="flex flex-col p-8">
                        <div className="text-center text-gray-500 text-lg mb-4">
                            Job Completed Date
                        </div>
                        <div className="flex flex-col">
                            <label className="text-gray-500 text-sm mb-1">
                                Completed Date
                            </label>
                            <input
                                type="date"
                                className="input"
                                onChange={(e) =>
                                    setJobCompletedDate(new Date(Date.parse(e.target.value)))
                                }
                            />
                        </div>
                        <div className="flex justify-end">
                            <button
                                type="button"
                                className="mr-4 btn bg-gray-400 text-white mt-8"
                                onClick={() => closeJobCompletedModal()}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={() => clearJobCompletedDate()}
                                className="mr-4 btn text-gray-500 mt-8"
                            >
                                Clear
                            </button>
                            <button
                                type="button"
                                onClick={() => addJobCompletedDate()}
                                className="btn bg-theme_blue text-white mt-8"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </Modal>

                <Modal visible={footageModal} closeModal={closeFootageModal}>
                    <div className="p-4 flex flex-col h-full">
                        <h2 className="text-lg font-bold mb-2">
                            {selectedInstall?.customer.firstName}{" "}
                            {selectedInstall?.customer.lastName}
                        </h2>
                        <h3 className="text-sm font-medium mb-2">
                            Sold: {selectedInstall?.ftSold}
                        </h3>
                        <h3 className="text-sm font-medium mb-2">
                            Submitted:{" "}
                            {selectedInstall && getTotalFootageSubmitted(selectedInstall)}
                        </h3>
                        {selectedInstall && selectedInstall.postInstalls && selectedInstall.postInstalls.length > 0 && (
                            <div className="flex-1 overflow-auto space-y-4">
                                {selectedInstall.postInstalls.map((postInstall) => (
                                    <div key={postInstall.id} className="bg-gray-100 p-4 rounded-md">
                                        <h3 className="text-md font-medium mb-2">
                                            Post Install {postInstall.id}
                                        </h3>
                                        <div className="space-y-2">
                                            {postInstall.lightsInstalled.map((light, lightIndex) => (
                                                <div key={lightIndex} className="flex items-center">
                                                    <div className="flex items-center mb-2">
                                                        <label className="mr-2 font-medium">Serial Number:</label>
                                                        <p className="border rounded-md py-1 px-2 flex-1 text-sm font-medium">
                                                            {light.serialNumber ? light.serialNumber : "N/A"}
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center mb-2">
                                                        <label className="block w-16 font-medium">Ft:</label>
                                                        <p className="border rounded-md px-2 py-1 flex-1 text-sm font-medium">
                                                            {light.ft}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="mt-4 flex items-center">
                                            <label className="block w-16 font-medium">Installer:</label>
                                            <p className="border rounded-md px-2 py-1 flex-1 mb-2 text-sm font-medium">
                                                {postInstall.installer.firstname} {postInstall.installer.lastname}
                                            </p>
                                        </div>
                                        <div className="mt-4 flex justify-evenly">
                                            <Button
                                                style="solid"
                                                color="yellow"
                                                onClick={() => {
                                                    loadInstallers(selectedInstall.branch.id);
                                                    setSelectedPostInstall(postInstall);
                                                    openPostInstallModal();
                                                }}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                style="solid"
                                                color="red"
                                                onClick={() => deletePostInstall(postInstall.id)}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="flex justify-center mt-4">
                            <button
                                className="border rounded-md px-4 py-2 text-sm font-medium"
                                onClick={closeFootageModal}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal>

                <Modal visible={postInstallModal} closeModal={closePostInstallModal}>
                    <div className="flex flex-col p-4">
                        <h2 className="text-lg font-bold mb-4">
                            Post Install {selectedPostInstall?.id}
                        </h2>
                        <div className="mb-4">
                            <label className="text-gray-500 font-semibold">Installer</label>
                            <Select
                                defaultValue={{
                                    label: `${selectedPostInstall?.installer.firstname} ${selectedPostInstall?.installer.lastname}`,
                                    value: selectedPostInstall?.installer.accountId,
                                }}
                                options={installers?.map((installer) => {
                                    return {
                                        label: `${installer.firstname} ${installer.lastname}`,
                                        value: installer.accountId,
                                    };
                                })}
                                onChange={(val) => {
                                    setInstallerId(val?.value);
                                }}
                            />
                        </div>

                        <h3 className="text-lg font-semibold mb-4">Enter Footage</h3>
                        <div className="flex items-center mb-4">
                            <label className="text-gray-500 font-semibold mr-2">SN</label>
                            <input
                                className="text-center bg-gray-200 w-24 md:w-32 py-1 px-2 rounded-md text-sm"
                                value={serialNumber || ""}
                                onChange={(e) => setSerialNumber(e.target.value)}
                            />

                            <label className="text-gray-500 font-semibold mx-2">ft</label>
                            <input
                                type="number"
                                value={ftInstalled}
                                className="text-center bg-gray-200 w-24 md:w-32 py-1 px-2 rounded-md"
                                onChange={(e) => setFtInstalled(e.target.value)}
                            />

                            <button className="ml-5">
                                <FontAwesomeIcon
                                    icon={faPlus}
                                    size="xs"
                                    className="text-gray-500"
                                    onClick={() => {
                                        createLightsInstalled(
                                            serialNumber ? serialNumber : null,
                                            int.parse(ftInstalled)
                                        );
                                    }}
                                />
                            </button>
                        </div>

                        {lightsInstalledList.map((el, index) => (
                            <div
                                key={index}
                                className="flex items-center justify-between mb-3"
                            >
                                <div className="flex flex-col md:flex-row">
                                    <label className="text-gray-500 font-semibold mr-2">
                                        SN
                                    </label>
                                    <input
                                        className="bg-gray-200 text-center w-24 md:w-32 py-1 px-2 rounded-md text-sm mr-8"
                                        value={el.serialNumber ? el.serialNumber : ""}
                                        readOnly
                                    />
                                    <label className="text-gray-500 font-semibold mx-2">
                                        ft
                                    </label>
                                    <input
                                        className="bg-gray-200 text-center w-24 md:w-32 py-1 px-2 rounded-md"
                                        value={el.ft}
                                        readOnly
                                    />
                                </div>
                                <button className="ml-5">
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        onClick={() => deleteLightsInstalled(el)}
                                    />
                                </button>
                            </div>
                        ))}

                        {lightsInstalledList.length ? (
                            <div className="flex justify-center mt-10">
                                <button
                                    className="text-base rounded-md shadow-lg px-6 py-2 font-medium bg-blue-500 text-white hover:bg-blue-600"
                                    onClick={submitFootage}
                                >
                                    Submit
                                </button>
                            </div>
                        ) : null}
                    </div>
                </Modal>

                <TableTabBar
                    tabOptions={{
                        tabs: [
                            {
                                name: InstallTab.All,
                                quantity:
                                    countAll === undefined ? (
                                        <ReactLoading height={15} width={15} type="spin" />
                                    ) : (
                                        countAll
                                    ),
                            },
                            {
                                name: InstallTab.PAID,
                                quantity:
                                    countPaid === undefined ? (
                                        <ReactLoading height={15} width={15} type="spin" />
                                    ) : (
                                        countPaid
                                    ),
                                amount: paidAmount ? formatMoney(paidAmount) : undefined,
                            },
                            {
                                name: InstallTab.NOT_PAID,
                                quantity:
                                    countUnpaid === undefined ? (
                                        <ReactLoading height={15} width={15} type="spin" />
                                    ) : (
                                        countUnpaid
                                    ),
                                amount: unpaidAmount ? formatMoney(unpaidAmount) : undefined,
                            },
                            {
                                name: InstallTab.SCHEDULED,
                                quantity:
                                    countScheduled === undefined ? (
                                        <ReactLoading height={15} width={15} type="spin" />
                                    ) : (
                                        countScheduled
                                    ),
                                amount: scheduledAmount
                                    ? formatMoney(scheduledAmount)
                                    : undefined,
                            },
                            {
                                name: InstallTab.NOT_SCHEDULED,
                                quantity:
                                    countNotScheduled === undefined ? (
                                        <ReactLoading height={15} width={15} type="spin" />
                                    ) : (
                                        countNotScheduled
                                    ),
                                amount:
                                    notScheduledAmount && downpaymentAmount
                                        ? `${formatMoney(downpaymentAmount)} / ${formatMoney(
                                            notScheduledAmount
                                        )}`
                                        : undefined,
                            },
                            {
                                name: InstallTab.WARRANTY_SIGNED,
                                quantity:
                                    countSigned === undefined ? (
                                        <ReactLoading height={15} width={15} type="spin" />
                                    ) : (
                                        countSigned
                                    ),
                            },
                            {
                                name: InstallTab.WARRANTY_NOT_SIGNED,
                                quantity:
                                    countNotSigned === undefined ? (
                                        <ReactLoading height={15} width={15} type="spin" />
                                    ) : (
                                        countNotSigned
                                    ),
                            },
                            {
                                name: InstallTab.PENDING_APPROVAL,
                                quantity: countPendingApproval === undefined ? (<ReactLoading height={15} width={15} type="spin" />) : (countPendingApproval),
                            }
                        ],
                        active: selectedTab,
                        onClick: setSelectedTab,
                    }}
                />

                <div id="table-scroll-div" className={`h-full w-full max-w-full overflow-auto relative`}>
                    <InfiniteScroll
                        dataLength={displayInstalls?.length ?? 0}
                        next={() => loadInstalls(100)}
                        hasMore={!!cursor.current}
                        loader={
                            <div>
                                <p style={{ textAlign: "center" }}>
                                    {loadingInstalls ? (
                                        <b>Loading more installs...</b>
                                    ) : (
                                        <Button
                                            className="mx-auto my-8"
                                            type="button"
                                            color="blue"
                                            style="outline"
                                            rounded
                                            onClick={() => loadInstalls(100)}
                                        >
                                            Load More
                                        </Button>
                                    )}
                                </p>
                                <p style={{ textAlign: "center" }}>
                                    <Button
                                        className="mx-auto my-8"
                                        type="button"
                                        color="blue"
                                        style="outline"
                                        rounded
                                        onClick={() => loadAll()}
                                    >
                                        Load All
                                    </Button>
                                </p>
                            </div>
                        }
                        endMessage={
                            <p style={{ textAlign: "center" }}>
                                <b>No more installs</b>
                            </p>
                        }
                        scrollThreshold={"80px"}
                        scrollableTarget={"table-scroll-div"}
                    >
                        <CustomTable
                            columns={columns}
                            data={data}
                            renderRowSubComponent={renderRowSubComponent}
                            searchCriteria={searchCriteria}
                        />
                    </InfiniteScroll>
                </div>

                {displayInstalls ? (
                    displayInstalls.length ? null : (
                        <div className="text-gray-500 inset-center top-72">
                            No installs
                        </div>
                    )
                ) : (
                    <Loading center />
                )}
            </div>

            {exporting && <Loading fullscreen />}
        </div>
    );
}
