import React, { useEffect, useState } from 'react'
import { CompanyType, IBranchSalesGoal, ICompany, IDIYGoal, int } from '@common.abstractions';
import { Equals, Exists, Not } from '@common.api';
import { Page } from '@frontend/components/misc/layout/Page';
import TopBar from '@frontend/components/misc/top-bar/TopBar';
import { DaysSinceLastSale, RetrieveAllGoals, RetrieveTotalFtSold } from '@frontend/services/branch-goals';
import Select from 'react-select'
import { calcPercentage, formatWholeMoney, GetDate } from '@common.tools';
import { Loading } from '@frontend/components/misc/loading';
import { RetrieveCompanies, RetrieveCompaniesForDashboard } from '@frontend/services/companies';
import DaysSinceLastSaleComponent from './DaysSinceSale';
import FtSold from './FtSold';
import { RetrieveDaysSinceLastPaidOrder, RetrieveDealerFtSold, RetrieveDealerGoals } from '@frontend/services/dealer-goals';
import { RetrieveShopifyDaysSinceLastPurchase, RetrieveShopifyFtSold } from '@frontend/services/shopify';
import ShopifySales from './shopify-components/ShopifySales';
import PartnerSales from './partner/PartnerSales';
import { RetrieveDIYGoals, RetrieveParterGoals } from '@frontend/services/goals';

export default function InstallsDash() {
    const [months, setMonths] = useState<Date[]>();
    const [totals, setTotals] = useState<{ totalProgress: number, totalGoal: number, totalPercent: number }>()
    const [loading, setLoading] = useState<boolean>();
    const [monthGoals, setMonthGoals] = useState<IBranchSalesGoal[]>();
    const [daysSinceLastSaleData, setDaysSinceLastSaleData] = useState<{ companyName: string, days: number }[]>();

    const today = new Date();
    const firstDayMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayMonth = new Date(new Date(today.getFullYear(), today.getMonth() + 1, 0).setHours(23, 59, 59, 59));

    const [timeFrame, setTimeFrame] = useState<{ startDate: Date, endDate: Date }>({ startDate: firstDayMonth, endDate: lastDayMonth }) //default to current month
    const [ftSold, setFtSold] = useState<{ branchName: string; totalFtSold: int | undefined }[]>();
    const [ftSoldTotals, setFtSoldTotals] = useState<int>();

    const [daysSinceLastPaidOrder, setDaysSinceLastPaidOrder] = useState<number>();
    const [dealerFtSold, setDealerFtSold] = useState<number>();
    const [dealerTotals, setDealerTotals] = useState<{ totalGoal: number; totalProgress: number; totalPercent: number; }>();

    const [diyTotals, setDIYTotals] = useState<{ totalGoal: number; totalProgress: number; totalPercent: number; }>();
    const [shopifyDaysSincePurchase, setShopifyDaysSincePurchase] = useState<number>();
    const [shopifyFtSold, setShopifyFtSold] = useState<int>();

    const [partnerTotals, setPartnerTotals] = useState<{ totalGoal: number, totalProgress: number, totalPercent: number, totalFtSold: number }>();

    const getMonthStartEnd = (month: Date) => {
        const date = new Date(month);
        const firstDayMonth = new Date(date.getFullYear(), date.getMonth(), 1)
        const lastDayMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        return {
            start: new Date(firstDayMonth.setHours(0, 0, 0, 0)),
            end: new Date(lastDayMonth.setHours(23, 59, 59, 59))
        }
    }

    // const getDaysSinceLastSaleData = async (branches: ICompany[]) => {
    //     const branchData = await Promise.all(
    //         branches.map(async (company) => {
    //             const daysSinceSale = await DaysSinceLastSale(company.id);
    //             const data = {
    //                 companyName: company.name,
    //                 days: daysSinceSale!
    //             }
    //             return data;
    //         })
    //     )
    //     return branchData;
    // }

    // const retrieveDaysSinceSale = async () => {
    //     const companies = await RetrieveCompanies({
    //         filters: {
    //             deletedAt: Not(Exists()),
    //             companyType: Equals(CompanyType.BRANCH)
    //         }
    //     })
    //     if (!companies) return;

    //     const branchData = await getDaysSinceLastSaleData(companies);

    //     branchData.sort((a, b) => {
    //         if (a.days < b.days) return -1;
    //         if (a.days > b.days) return 1;

    //         return 0;
    //     })

    //     setDaysSinceLastSaleData(branchData);
    // }

    const getGoals = async () => {
        console.time('getGoals');
        const tempGoals = await RetrieveAllGoals({
            filters: {
                deletedAt: Not(Exists()),
                company: { companyType: Equals(CompanyType.BRANCH) },
            },
        });

        const monthsArr = tempGoals?.map((el) => el.startDate);
        const uniqueMonths = monthsArr?.filter((month, index) => {
            return monthsArr?.indexOf(month) === index;
        });

        setMonths(uniqueMonths?.sort((a, b) => {
            let x = new Date(a).getTime();
            let y = new Date(b).getTime();
            return y - x
        }));

        const testGoals = tempGoals?.filter((goal) => {
            return (
                new Date(timeFrame.startDate) <= new Date(goal.startDate) && new Date(goal.startDate) <= new Date(timeFrame.endDate)
            )
        })
        if (!testGoals) return;

        // group goals by company id and then sum totals
        const result: IBranchSalesGoal[] = [...testGoals.reduce((r, o) => {
            const key = o.company.id

            const item = r.get(key) || Object.assign({}, o, {
                progress: 0,
                salesGoal: 0
            });

            item.progress += o.progress;
            item.salesGoal += o.salesGoal;

            return r.set(key, item);
        }, new Map).values()];

        result?.sort((a, b) => {
            if (calcPercentage(a.progress!, a.salesGoal) < calcPercentage(b.progress!, b.salesGoal)) return 1;
            if (calcPercentage(a.progress!, a.salesGoal) > calcPercentage(b.progress!, b.salesGoal)) return -1;

            return 0;
        })

        setMonthGoals(result);

        let totalProgress = result?.reduce((acc, curr) => {
            if (!curr.progress) curr.progress = int(0);
            return acc + curr.progress
        }, 0);
        if (!totalProgress) totalProgress = 0;

        let totalGoal = result?.reduce((acc, curr) => acc + curr.salesGoal, 0);
        if (!totalGoal) totalGoal = 0;

        const totalPercent = calcPercentage(totalProgress, totalGoal);

        const totals = {
            totalProgress,
            totalGoal,
            totalPercent
        }

        setTotals(totals);
        console.timeEnd('getGoals');
    }

    const gatherMetrics = async () => {
        console.time('gatherMetrics');
        const companies = await RetrieveCompaniesForDashboard({
            filters: {
                deletedAt: Not(Exists()),
                companyType: Equals(CompanyType.BRANCH)
            }
        }, timeFrame);
        if (!companies) return;

        //#region process days since last sale data
        const daysSinceLastSaleArray = companies.map(company => (
            { companyName: company.name, days: company.daysSinceLastSale! as int }
        ));

        daysSinceLastSaleArray.sort((a, b) => {
            if (a.days < b.days) return -1;
            if (a.days > b.days) return 1;
            return 0;
        })

        setDaysSinceLastSaleData(daysSinceLastSaleArray);
        //#endregion days since last sale

        //#region process total ft sold data
        const branchFtSoldArr = companies.map(company => (
            { branchName: company.name, totalFtSold: company.ftSold! as int }
        ));

        branchFtSoldArr.sort((a, b) => {
            if (a.totalFtSold! < b.totalFtSold!) return 1;
            if (a.totalFtSold! > b.totalFtSold!) return -1;

            return 0;
        })
        setFtSold(branchFtSoldArr)

        const totals = int(branchFtSoldArr.reduce((acc, curr) => acc + curr.totalFtSold!, 0));
        setFtSoldTotals(totals);
        //#endregion ft sold data
        console.timeEnd('gatherMetrics');
    }

    // const getFtSold = async () => {
    //     console.time('getFtSold');
    //     const companies = await RetrieveCompaniesForDashboard({
    //         filters: {
    //             companyType: Equals(CompanyType.BRANCH)
    //         }
    //     }, timeFrame);
    //     if (!companies) return;

    //      const branchDataArr = companies.map(company => (
    //         { branchName: company.name, totalFtSold: company.ftSold! as int}
    //      ));

    //     branchDataArr.sort((a, b) => {
    //         if (a.totalFtSold! < b.totalFtSold!) return 1;
    //         if (a.totalFtSold! > b.totalFtSold!) return -1;

    //         return 0;
    //     })
    //     setFtSold(branchDataArr)

    //     const totals = int(branchDataArr.reduce((acc, curr) => acc + curr.totalFtSold!, 0));
    //     setFtSoldTotals(totals);
    //     console.timeEnd('getFtSold');
    // }

    const getDealerGoals = async () => {
        console.time('getDealerGoals');
        const tempGoals = await RetrieveDealerGoals();

        const goals = tempGoals?.filter((goal) => {
            return (
                new Date(timeFrame.startDate) <= new Date(goal.startDate) && new Date(goal.startDate) <= new Date(timeFrame.endDate)
            )
        })

        let totalProgress = goals?.reduce((acc, curr) => {
            if (!curr.progress) curr.progress = int(0);
            return acc + curr.progress;
        }, 0);
        if (!totalProgress) totalProgress = 0;

        let totalGoal = goals?.reduce((acc, curr) => acc + curr.goal, 0);
        if (!totalGoal) totalGoal = 0;

        const totalPercent = calcPercentage(totalProgress, totalGoal);

        const totals = {
            totalGoal,
            totalProgress,
            totalPercent
        }

        if (!goals) {
            console.log('Could not retrieve dealer goals for specified timeframe');
            console.time('getDealerGoals');
            return;
        }
        setDealerTotals(totals);
        console.time('getDealerGoals');
    }

    const getDaysSinceLastPaidOrder = async () => {
        const response = await RetrieveDaysSinceLastPaidOrder();
        // TODO: fix - if response returns 0 below code block triggers
        if (!response) {
            setDaysSinceLastPaidOrder(0); // Setting to 0 as a temp work around
            return;
        }
        setDaysSinceLastPaidOrder(response);
    }

    const getDealerTotalFtSold = async () => {
        console.time('getDealerTotalFtSold');
        const response = await RetrieveDealerFtSold(timeFrame);
        if (!response) {
            console.log('Could not retrieve dealer ft sold');
            setDealerFtSold(0);
            return;
        }
        setDealerFtSold(response);
        console.timeEnd('getDealerTotalFtSold');
    }

    const getTimeFrames = () => {
        if (!months) return;
        const monthTimeFrames = months.map(month => (
            {
                value: {
                    start: getMonthStartEnd(month).start,
                    end: getMonthStartEnd(month).end
                },
                label: new Date(month).toLocaleDateString('en-us', { year: "numeric", month: "short" })
            }
        ))

        
        const yearToDateStart = new Date(GetDate.january().setHours(0, 0, 0, 0));
        const yearToDateEnd = new Date(GetDate.tomorrow().setHours(23, 59, 59, 999));

        const yearToDate = {
            value: {
                start: yearToDateStart,
                end: yearToDateEnd,
                name: "yearToDate",
            },
            label: "Year to Date",
        }

        const years = GetDate.years().map(el => (
            {
                value: {
                    start: el.start,
                    end: el.end,
                    name: `${el.start.getFullYear()}`,
                },
                label:  `${el.start.getFullYear()}`
            }
        ));

        const timeframes = [...monthTimeFrames, yearToDate, ...years];
        return timeframes
    }

    const getDIYGoals = async () => {
        console.time('getDIYGoals');
        setLoading(true);
        const tempGoals = await RetrieveDIYGoals({
            filters: {
                deletedAt: Not(Exists()),
            }
        }, timeFrame);
        const goals = tempGoals?.filter((goal) => {
            return (
                new Date(timeFrame.startDate) <= new Date(goal.startDate) && new Date(goal.startDate) <= new Date(timeFrame.endDate)
            )
        })

        let totalProgress = goals?.reduce((acc, curr) => {
            if (!curr.progress) curr.progress = int(0);
            return acc + curr.progress;
        }, 0);
        if (!totalProgress) totalProgress = 0;

        let totalGoal = goals?.reduce((acc, curr) => acc + curr.goal, 0);
        if (!totalGoal) totalGoal = 0;

        const totalPercent = calcPercentage(totalProgress, totalGoal);

        const totals = {
            totalGoal,
            totalProgress,
            totalPercent
        }
        setDIYTotals(totals);
        setLoading(false);
        console.timeEnd('getDIYGoals');
    }

    const getShopifyDaysSinceLastPurchase = async () => {
        const daysSinceLastPurchase = await RetrieveShopifyDaysSinceLastPurchase();
        if (!daysSinceLastPurchase) {
            console.log('Could not get Shopify days Since Last Purchase');
            return;
        }
        setShopifyDaysSincePurchase(daysSinceLastPurchase);
    }

    const getShopifyFtSold = async () => {
        console.time('getShopifyFtSold');
        const ftSold = await RetrieveShopifyFtSold(timeFrame);
        if (!ftSold) {
            setShopifyFtSold(int(0));
            return;
        }
        setShopifyFtSold(ftSold);
        console.timeEnd('getShopifyFtSold');
    }

    const getPartnerGoals = async () => {
        console.time('getPartnerGoals');
        const tempGoals = await RetrieveParterGoals();

        const goals = tempGoals?.filter((goal) => new Date(timeFrame.startDate) <= new Date(goal.startDate) && new Date(goal.startDate) <= new Date(timeFrame.endDate))

        let totalProgress = goals?.reduce((acc, curr) => {
            if (!curr.progress) curr.progress = int(0);
            return acc + curr.progress;
        }, 0);
        if (!totalProgress) totalProgress = 0;

        let totalGoal = goals?.reduce((acc, curr) => acc + curr.goal, 0);
        if (!totalGoal) totalGoal = 0;

        let totalFtSold = goals?.reduce((acc, curr) => acc + curr.ftSold, 0);
        if (!totalFtSold) totalFtSold = 0;

        const totalPercent = calcPercentage(totalProgress, totalGoal);

        const totals = {
            totalGoal,
            totalProgress,
            totalPercent,
            totalFtSold
        }
        setPartnerTotals(totals);
        console.timeEnd('getPartnerGoals');
    }

    useEffect(() => {
        (async function () {
            console.time('retrieveDaysSinceSale');
            await gatherMetrics();
            console.timeEnd('retrieveDaysSinceSale');
            console.time('getDaysSinceLastPaidOrder');
            await getDaysSinceLastPaidOrder();
            console.timeEnd('getDaysSinceLastPaidOrder');
            console.time('getShopifyDaysSinceLastPurchase');
            await getShopifyDaysSinceLastPurchase();
            console.timeEnd('getShopifyDaysSinceLastPurchase');
        })();
    }, [])

    useEffect(() => {
        getGoals();
        gatherMetrics();
        getDealerGoals();
        getDealerTotalFtSold();
        getDIYGoals();
        getShopifyFtSold();
        getPartnerGoals();
    }, [timeFrame])

    return (
        <Page>
            <TopBar title="Branch Dashboard">
                <Select
                    className="quotes_date_dropdown"
                    defaultValue={
                        {
                            value: {
                                start: timeFrame.startDate,
                                end: timeFrame.endDate
                            },
                            label: new Date(GetDate.today().getFullYear(), GetDate.today().getMonth(), 1).toLocaleDateString('en-us', { year: "numeric", month: "short" })
                        }
                    }
                    options={getTimeFrames()}
                    onChange={(val) => {
                        if (val) setTimeFrame({ startDate: val.value.start, endDate: val.value.end })
                    }}
                />
            </TopBar>

            {loading ?
                <Loading center></Loading>

                : (monthGoals?.length ?
                    <div className='grid mb-5 gap-6 p-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-h-full overflow-y-auto'>
                        <div className="p-5 shadow-md hover:shadow-lg duration-theme h-auto opacity-70 hover:opacity-100">
                            <h2 className="text-gray-500 mb-3">Monthly Target </h2>

                            <div className="flex items-end mb-4">
                                <div className="text-3xl text-gray-700 font-bold">{totals?.totalPercent}%</div>
                                <div className="text-xl text-gray-700 font-bold flex ml-5">
                                    <div className="relative" style={{ minWidth: "30px" }}>
                                        {totals?.totalProgress ? formatWholeMoney(totals?.totalProgress) : '0'}
                                        <div className="absolute bottom-full text-xs whitespace-nowrap">progress</div>
                                    </div>
                                    <div className="mx-3">/</div>
                                    <div className="relative">
                                        {totals?.totalGoal ? formatWholeMoney(totals?.totalGoal) : '0'}
                                        <div className="absolute bottom-full text-xs whitespace-nowrap">target</div>
                                    </div>
                                </div>
                            </div>

                            {monthGoals?.map((goal, i) => {
                                return (
                                    <div key={i} className={`flex flex-col border-gray-200 border-b`}>
                                        <div
                                            className="flex justify-between py-2 cursor-pointer"
                                            onClick={() => window.location.href = `/app/companies/${goal.company.id}/goals`}
                                        >
                                            <div className="text-gray-600">{goal.company.name}</div>
                                            <div className="flex">
                                                <div className="inline-flex items-center mr-3 font-semibold text-gray-700">
                                                    {goal.progress ? `${formatWholeMoney(goal.progress)} /  ${formatWholeMoney(goal.salesGoal)}` : `0/${formatWholeMoney(goal.salesGoal)}`}
                                                </div>
                                                <div className="inline-flex items-center w-14">
                                                    <span className="ml-1 text-gray-500">{goal.progress ? `${calcPercentage(goal.progress, goal.salesGoal)}%` : '0%'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        <DaysSinceLastSaleComponent data={daysSinceLastSaleData} />

                        {ftSold ?
                            <FtSold data={ftSold} totals={ftSoldTotals} />
                            : null
                        }

                        {/* Dealer Monthly Goal Tiles */}
                        <div className="p-5 shadow-md hover:shadow-lg duration-theme h-auto opacity-70 hover:opacity-100 cursor-pointer"
                            onClick={() => window.location.href = `/app/companies/dealerGoals`}
                        >
                            <h2 className="text-gray-500 mb-3">Dealer Monthly Target </h2>
                            <div className="flex items-end mb-4">
                                <div className="text-3xl text-gray-700 font-bold">{dealerTotals && (dealerTotals?.totalPercent / 100).toFixed(2)}%</div>
                                <div className="text-xl text-gray-700 font-bold flex ml-5">
                                    <div className="relative" style={{ minWidth: "30px" }}>
                                        {dealerTotals?.totalProgress && formatWholeMoney(dealerTotals.totalProgress)}
                                        <div className="absolute bottom-full text-xs whitespace-nowrap">progress</div>
                                    </div>
                                    <div className="mx-3">/</div>
                                    <div className="relative">
                                        {dealerTotals?.totalGoal && formatWholeMoney(dealerTotals.totalGoal * 100)}
                                        <div className="absolute bottom-full text-xs whitespace-nowrap">target</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Dealer Days Since Last Paid Order */}
                        <div className="p-5 shadow-md hover:shadow-lg duration-theme h-auto opacity-70 hover:opacity-100" >
                            <h2 className="text-gray-500 mb-3">Days Since Last Paid Order </h2>
                            <div className={`flex flex-col border-gray-200 border-b`}>
                                <div className="flex justify-between py-2">
                                    <div className="flex">
                                        <div className="inline-flex items-center w-14">
                                            <span className="ml-1 text-gray-500">{daysSinceLastPaidOrder}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Dealer Total Ft Sold */}
                        <div className="p-5 shadow-md hover:shadow-lg duration-theme h-auto opacity-70 hover:opacity-100">
                            <div className="flex">
                                <h2 className="text-gray-500 mb-3">Total Dealer MOQ </h2>
                                <p className="text-3xl text-gray-700 font-bold ml-3">{dealerFtSold}</p>
                            </div>
                        </div>

                        {/* Shopify */}
                        <ShopifySales onClick={() => window.location.href = `/app/goals/diyGoals`} totals={diyTotals!} daysSinceLastPurchase={shopifyDaysSincePurchase ? shopifyDaysSincePurchase : 0} totalFtSold={shopifyFtSold ? shopifyFtSold : int(0)} />

                        {/* Partner */}
                        <PartnerSales onClick={() => window.location.href = `/app/goals/partnerGoals`} totalGoal={partnerTotals?.totalGoal!} ftSold={partnerTotals?.totalFtSold!} totalPercent={partnerTotals?.totalPercent!} totalProgress={partnerTotals?.totalProgress!} />
                    </div>

                    : <div className="inset-center text-gray-500">Targets Not Found</div>
                )
            }
        </Page>
    )
}